import PropTypes from 'prop-types';
import React from 'react';
import Button from '../shared/Button';
import Search from '../shared/Search';
import {get} from '../../lib/API';

export default class TeamsSelect extends React.Component {
  static propTypes = {
    ignore: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
  };

  state = {
    filter: '',
    teams: [],
    createTeamActive: false,
  };

  componentDidMount() {
    const { ignore } = this.props;
    get(
      `/api/teams?${ignore.map((id) => `&ignored_team_ids[]=${id}`)}`,
      (data) => {
        this.setState({ teams: data });
      },
    );
  }

  toggleTeamCreationMode = () => {
    this.setState({ createTeamActive: !this.state.createTeamActive });
  };

  render() {
    const { onSelect, ignore = [] } = this.props;
    const { filter, createTeamActive, teams } = this.state;

    return (
      <div className="teams-select u-pt-2">
        <div className="u-pb-1">
          <div className="teams-select__title">
            Please select teams from the list
          </div>

          {!createTeamActive &&
            <div className="teams-select__search">
              <Search id="teams-select_search"
                      onChange={(filter) => this.setState({ filter })}/>
            </div>}
        </div>

        {createTeamActive
        ? <Button mod="Button--small u-font-size-13"
                  title="Show teams list"
                  id="show_teams"
                  onClick={this.toggleTeamCreationMode}/>
        : (teams.length > 0 &&
            <ul className="teams-select__content u-mb-1">
              {teams?.map(team => !ignore.includes(team.id) && (filter.length === 0 || team.title.toLowerCase().includes(filter)) &&
                <li className="teams-select__team"
                    onClick={() => onSelect(team)}
                    key={team.id}>
                  {team.title} ({team.captain})
                </li>)}
            </ul>
        )}
      </div>
    );
  }
}
