import React, {useState} from "react";
import {isValidPhoneNumber} from 'react-phone-number-input';
import FindLeague from './FindLeague';
import ConfirmDetails from './ConfirmDetails';
import Success from './Success';
import {getVenues, getPublicListing, postTeamRegistration} from "./utils/_api_requests";
import {TEAM_KEYS} from "./utils/constants";
import {getRegistrationParams, getPreferredLeague} from "./utils/helpers";

export default function RegisterTeam({sports, company_name}) {
  const [venues, setVenues] = useState([]);
  const [listings, setListings] = useState([]);
  const [leagueOptions, setLeagueOptions] = useState([]);
  const [returningId, setReturningId] = useState();
  const [isFindLeagueStep, setIsFindLeagueStep] = useState(true);
  const [isConfirmDetailsStep, setIsConfirmDetailsStep] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [leagueDetails, setLeagueDetails] = useState({
    sport_id: null,
    venue_id: null,
    registration_listing_id: null
  });
  const [captainDetails, setCaptainDetails] = useState({
    email: null,
    first_name: null,
    last_name: null,
    team_title: null,
    phone: null
  });

  const handleFindLeagueChange = async(id, key) => {
    setLeagueDetails(prev => ({ ...prev, [key]: id }));

    if (key === TEAM_KEYS.sport_id) {
      setVenues([]);
      setListings([]);
      setLeagueOptions([]);
      setLeagueDetails(prev => ({ ...prev, venue_id: null, registration_listing_id: null }));
      getVenues(id, setVenues);
    }

    if (key === TEAM_KEYS.venue_id) {
      setListings([]);
      setLeagueOptions([]);
      setLeagueDetails(prev => ({ ...prev, registration_listing_id: null }));
      getPublicListing(leagueDetails.sport_id, id, setLeagueOptions, setListings);
    }
  }

  const handleDetailsChange = async(value, key) => {
    setCaptainDetails(prev => ({...prev, [key]: value }));
    if (key === TEAM_KEYS.captain_phone) {
      setIsPhoneValid(isValidPhoneNumber(value))
    }
  }

  const handleNextClick = () => {
    setIsFindLeagueStep(false);
    setIsConfirmDetailsStep(true);
  }

  const handleSubmit = () => {
    const params = getRegistrationParams(returningId, leagueDetails, captainDetails)
    postTeamRegistration(params, setIsConfirmDetailsStep, setIsSuccess)
  }

  return <div>
    {isFindLeagueStep &&
      <FindLeague leagueOptions={leagueOptions}
                  preferredLeague={getPreferredLeague(listings, leagueDetails.registration_listing_id)}
                  sports={sports}
                  venues={venues}
                  details={leagueDetails}
                  onChange={(id, key) => handleFindLeagueChange(id, key)}
                  onNext={handleNextClick}/>}

    {isConfirmDetailsStep &&
      <ConfirmDetails details={captainDetails}
                      isPhoneValid={isPhoneValid}
                      onChange={(id, key) => handleDetailsChange(id, key)}
                      onSubmit={() => handleSubmit()}
                      returningId={returningId}
                      setReturningId={setReturningId}/>}

    {isSuccess &&
      <Success company={company_name}/>}
  </div>
}
