import qs from 'qs'

const clearParams = (params) => {
    for (const key of Object.keys(params)) {
        if (typeof params[key] === 'object' && params[key] !== null) {
            clearParams(params[key])
        } else if (params[key] === "" || params[key] === null) {
            delete params[key];
        }
    }
}

const buildQueryParams = (params) => {
    clearParams(params);
    return qs.stringify(params, {encode: false});
}

const buildQueryParamsEncode = (params) => {
    clearParams(params);
    return qs.stringify(params, {encode: true});
}

const buildQueryParamsArrayFormat = (params) => {
    clearParams(params);
    return qs.stringify(params, {encode: false, arrayFormat: 'brackets'});
}

const buildQueryParamsArray = (key, params) => {
    let newarr=[]
    params.forEach(item => newarr.push(encodeURIComponent(key + '[]') + '=' + item))
    return newarr.join('&') 
}

export {
    buildQueryParams,
    buildQueryParamsArray,
    buildQueryParamsEncode,
    buildQueryParamsArrayFormat
}
