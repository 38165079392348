import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import Calendar from '../shared/Calendar';
import Step from './Step';
import TextInput from '../shared/TextInput';
import Button from '../shared/Button';
import TimeSlots from './TimeSlots';
import { create, destroy } from '../../lib/API';
import { fixtureDTO, fixtureMap } from '../../lib/DTO';
import SlotsCount from './SlotsCount';
import { dateRange } from '../../lib/dateTimeHelper';
import { redirect } from '../../lib/utils';

const DATE_FORMAT = 'D MMMM YYYY';

export default class FixturesForm extends React.Component {
  static propTypes = {
    league: PropTypes.object.isRequired,
    fixture: PropTypes.object,
    spaces: PropTypes.array.isRequired,
  };

  state = {
    league_id: this.props.league.id,
    start_date:
      (this.props.fixture.start_date
        && moment(this.props.fixture.start_date))
      || null,
    round_number: this.props.fixture.round_number,
    slots_number: ~~(this.props.teams.length / 2),
    non_playing_dates:
      (this.props.fixture.non_playing_dates
        && this.props.fixture.non_playing_dates
          .split(',')
          .map((d) => moment(d, 'DD/MM/YYYY')))
      || [],
    team_ids: this.props.fixture.team_ids,
    sport_duration: this.props.sport_duration,
    selected: [],
    currentStep:
      !!this.props.fixture.start_date
      && !!this.props.fixture.round_number
        ? 2
        : 1,
  };

  setLoadedTimeslots = (timeslots) => {
    this.setState({ selected: timeslots });
  };

  get dateSet() {
    const { non_playing_dates } = this.state;

    return dateRange(non_playing_dates).map((d, i) => (
      <div className="step__value_item" key={i}>
        {d}
      </div>
    ));
  }

  componentDidMount() {
    const { currentStep } = this.state;
    const { fixture } = this.props;

    if (currentStep === 2) {
      this.afterValidateStep1(fixture);
    }
  }

  goBack() {
    const { league } = this.props;
    const { id } = this.state;

    if (id) {
      destroy({}, `/leagues/${league.id}/fixture`, () => redirect(`/leagues/${league.id}`),
      );
    } else {
      redirect(`/leagues/${league.id}`);
    }
  }

  validateStep1() {
    const { league } = this.props;
    const { start_date, round_number } = this.state;

    if (!!start_date && !!round_number) {
      const data = {
        fixture_wizard: fixtureDTO(this.state),
        current_step: 'step1',
      };

      create(
        data,
        `/leagues/${league.id}/fixture_wizard/validate_step`,
        (responce) => this.afterValidateStep1(responce.wizard.fixture),
      );
    }
  }

  afterValidateStep1(fixture) {
    this.setState({ ...fixtureMap(fixture), currentStep: 2 });
  }

  validateStep2() {
    const { league } = this.props;

    const data = {
      fixture_wizard: fixtureDTO(this.state),
      current_step: 'step2',
    };
    create(
      data,
      `/leagues/${league.id}/fixture_wizard/validate_step`,
      (responce) => this.afterValidateStep2(responce),
    );
  }

  afterValidateStep2() {
    const { league } = this.props;
    redirect(`/leagues/${league.id}/fixture_wizard/step3`);
  }

  submit() {
    this.validateStep2();
  }

  render() {
    const { spaces, sport_duration } = this.props;
    const {
      currentStep,
      start_date,
      non_playing_dates,
      round_number,
      slots_number,
      selected,
      id,
    } = this.state;

    const selectedCount = selected.length;
    const isRoundAutoCollapse =      typeof round_number === 'string' && round_number.length > 1;

    return (
      <div className="fixture-form">
        <Step
          number="1"
          autoCollapse
          title="Start date"
          value={
            (start_date && [
              moment(start_date).format(DATE_FORMAT),
            ])
            || null
          }
        >
          <Calendar
            value={moment(start_date)}
            onSelect={(start_date) => this.setState({ start_date }, () => this.validateStep1(),
              )
            }
          />
        </Step>
        <Step
          number="2"
          disabled={!start_date}
          title="Non-playing dates"
          value={this.dateSet}
        >
          <Calendar
            multiSelect
            mod="calendar-red-select"
            value={non_playing_dates}
            blockedBefore={start_date}
            onSelect={(non_playing_dates) => this.setState({ non_playing_dates }, () => this.validateStep1(),
              )
            }
          />
        </Step>
        <Step
          number="3"
          autoCollapse={isRoundAutoCollapse}
          disabled={!start_date}
          title="How many rounds?"
          value={round_number}
        >
          <TextInput
            mod="text-input__input_small"
            title="Number of rounds"
            id="rounds_num"
            validate={['required']}
            type="number"
            onChange={(round_number) => {
              if (typeof round_number === 'string') {
                round_number.length > 1
                  ? this.setState({ round_number }, () => this.validateStep1(),
                    )
                  : this.setState({ round_number });
              }
            }}
            onBlur={({ target: { value: round_number } }) => {
              if (typeof round_number === 'string') {
                this.setState({ round_number }, () => this.validateStep1(),
                );
              }
            }}
            value={round_number}
          />
        </Step>
        <Step
          number="4"
          autoCollapse
          disabled={currentStep === 1}
          title="Timeslots"
          completed={() => selectedCount === slots_number}
          value={selected.map((s) => s.start_time).join(', ')}
        >
          {id && (
            <div>
              <SlotsCount
                current={selectedCount}
                required={slots_number}
              />
              <TimeSlots
                loadTimeslots={this.setLoadedTimeslots}
                round_number={round_number}
                start_date={start_date}
                isAllowAdd={selectedCount < slots_number}
                fixtureId={id}
                spaces={spaces}
                sport_duration={sport_duration}
                onChangeSelected={(selected) => this.setState({ selected })
                }
              />
            </div>
          )}
        </Step>
        <div className="u-mt-3 d-flex">
          <Button
            mod="Button--primary u-mr-1"
            title="View Fixture Preview"
            disabled={selectedCount !== slots_number}
            id="create_button"
            onClick={() => this.submit()}
          />
          <Button
            mod="Button--cancel"
            title="Cancel"
            id="cancel_button"
            onClick={() => this.goBack()}
          />
        </div>
      </div>
    );
  }
}
