import React, {useEffect, useState} from 'react'
import {
    parseMatchFeeFromDescription,
    parseCompetitionFromDescription
} from "../util/_payment_fields_operations";
import {moneyFormatterZeros} from '../../../lib/utils';
import axios from "axios";

export default function PaymentInformation({payment, option, cancelCallback}) {
    const [paymentHistory, setPaymentHistory] = useState(null);

    useEffect(() => {
        axios.get(`/api/invoices/${payment.id}/histories`)
            .then((response) => {
                    setPaymentHistory(response.data.data);
                },
                () => {
                    cancelCallback();
                    window.alert('Error occurred!')
                })

    }, [])

    if (!paymentHistory) return null;

    const {
        invoice_versions: invoiceVersions,
        invoice: {description, sport, venue, team, amount}
    } = paymentHistory;

    return <div className="PaymentModal PaymentModal-width--28 u-element-center u-fixed">
            <h1 className="PaymentTab-title">{option}</h1>

            <h2>
                <span className="u-font-size-16 u-weight-400">
                    {parseMatchFeeFromDescription(payment.description)}
                </span>
                <span className="separator">|</span>
                <span className="u-font-size-16 u-weight-600">
                    ${moneyFormatterZeros(amount ? amount : payment.amount)}
                </span>
            </h2>

            <div className="SchedulePaymentsTab-invoice-info flex-grow-1 u-mt-2">
                <p className="u-weight-400 u-font-size-17">{team}</p>

                <div className="d-flex align-items-center">
                    {sport && 
                        <div className="d-flex u-mt-1">
                            <div className="sport-icon u-mr-1"/>   
                            <p className="PaymentTab-description u-weight-400 u-font-size-13">
                                {sport}
                            </p>
                        </div>}

                    {venue && 
                        <div className="d-flex u-mt-1">
                            <p className="u-font-size-12 separator">|</p>
                            <div className="venue-icon u-mr-1"/>
                            <p className="PaymentTab-description u-weight-400 u-font-size-13">
                                {venue}
                            </p>
                        </div>}
                </div>

                {description && 
                    <div className="d-flex u-mt-1">
                        <div className="desc-icon u-mr-1"/>  
                        <p className="PaymentTab-description u-weight-400 u-font-size-13">
                            {description.includes("|")
                            ? parseCompetitionFromDescription(description)
                            : description}
                        </p>
                    </div>}
            </div>

            {paymentHistory && invoiceVersions.length > 0 &&
                <div className="u-mb-2 u-mt-1">
                    <p className="u-font-size-17 u-weight-400">History:</p>

                    {invoiceVersions.map(({description, who_done_this, date}, index) =>
                        <div className="PaymentTab-history-container u-mt-1 d-flex justify-content-between"
                             key={index}>

                            <div className="d-flex flex-column">
                                <div className="u-font-size-14 u-relative u-weight-600">{date}</div>
                                {description.map(desc =>
                                    <div key={desc} className="u-font-size-14 u-weight-400 u-mt-1">
                                        {desc}
                                    </div>)}
                            </div>

                            <span className="PaymentTab-author u-font-size-14 u-relative u-weight-600">
                                {who_done_this}
                            </span>
                        </div>)}
                </div>}

            <div className="d-flex u-mt-2">
                <button className="Button Button--primary-empty PaymentTab-button"
                        onClick={() => cancelCallback()}>
                    Back
                </button>
            </div>
        </div>
}
