import axios from 'axios';
import {buildQueryParams, geListingOptions} from './helpers';

export const getVenues = (sport_id, setData) => {
  axios
  .get(`/api/public_listings/venues?${buildQueryParams({sport_id})}`)
  .then(({data: { data: data }}) => {
    setData(data.venues);
  })
  .catch(error => {
    console.error(error);
  })
};

export const getPublicListing = (sport_id, venue_id, setListingOptions, setListings) => {
  axios
  .get(`/api/public_listings/listings?${buildQueryParams({sport_id, venue_id})}`)
  .then(({data: { data: data }}) => {
    setListingOptions(geListingOptions(data.listings));
    setListings(data.listings);
  })
  .catch(error => {
    console.error(error);
  })
};

export const postTeamRegistration = (params, hideConfirmStep, showSuccess) => {
  axios
    .post(`/api/public_listings/team_registrations?${buildQueryParams(params)}`)
    .then(response => {
      if (response.status === 201) {
        hideConfirmStep(false);
        showSuccess(true);
      }
    })
    .catch(error => {
      console.error(error);
    })
};
