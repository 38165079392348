import React from 'react'
import PropTypes from "prop-types"
import moment from "moment";
import Background from "../../shared/Background";
import {moneyFormatterZeros} from "../../../lib/utils";
export default function Breakdown({paymentsList, downloadingNewPage, perPage, totals}) {

    const theadClassNames = 'u-pt-2 u-pb-2';

    return (
        <React.Fragment>
            <Background backgroundStyle={'Underlayer-dark'}/>
            <div className="table__wrapper">
                <table className="bordered highlight u-w-100">
                    <thead>
                    <tr className="u-text-left u-noBorder no-bordered">
                        <th className={theadClassNames}>Paid At</th>
                        <th className={theadClassNames}>Invoice</th>
                        <th className={theadClassNames}>Description</th>
                        <th className={theadClassNames}>Amount <br/>received</th>
                        <th className={theadClassNames}>Invoice <br/>amount</th>
                        <th className={theadClassNames}>Transaction <br/>fee</th>
                        <th className={theadClassNames}>Stripe fee</th>
                        <th className={theadClassNames}>Margin</th>
                    </tr>
                    <tr className="u-text-left u-noBorder">
                        <th className={theadClassNames}></th>
                        <th className={theadClassNames}></th>
                        <th className={theadClassNames}></th>
                        <th className={theadClassNames + ' total-amount'}>${moneyFormatterZeros(totals.total_amount)}</th>
                        <th className={theadClassNames + ' total-amount'}>${moneyFormatterZeros(totals.amount)}</th>
                        <th className={theadClassNames + ' total-amount'}>${moneyFormatterZeros(totals.transaction_fee)}</th>
                        <th className={theadClassNames + ' total-amount'}>${moneyFormatterZeros(totals.stripe_fee)}</th>
                        <th className={theadClassNames + ' total-amount'}>${moneyFormatterZeros(totals.margin)}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {paymentsList.length ? paymentsList.map(payment => {
                            const {
                                id,
                                paid_at,
                                description,
                                amount,
                                total_amount,
                                transaction_fee,
                                margin,
                                stripe_fee
                            } = payment;
                            return (
                                <tr className="collection-item"
                                    key={id}
                                >
                                    <td>{moment(paid_at).format('LL')}</td>
                                    <td>{id}</td>
                                    <td>{description}</td>
                                    <td>${moneyFormatterZeros(total_amount)}</td>
                                    <td>${moneyFormatterZeros(amount)}</td>
                                    <td>${moneyFormatterZeros(transaction_fee)}</td>
                                    <td>${moneyFormatterZeros(stripe_fee)}</td>
                                    <td>${moneyFormatterZeros(margin)}</td>
                                </tr>
                            )
                        })
                        : <tr><th>There is no invoices...</th></tr> }
                    </tbody>
                </table>
                {downloadingNewPage && paymentsList.length >= perPage ?
                <p className="u-text-center">Fetching more payments...</p> : null} 
            </div>      
        </React.Fragment>
    )
}

Breakdown.propTypes = {
    paymentsList: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            created_at: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            total_amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            transaction_fee: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            stripe_fee: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        })
    ),
    downloadingNewPage: PropTypes.bool.isRequired,
    perPage: PropTypes.number.isRequired
}
