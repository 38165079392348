import React, {useEffect, useState, useRef, useCallback} from 'react'
import PropTypes from "prop-types"
import Background from "../shared/Background";
import {
  markAsPaid,
  voidFee,
  refund,
  adjustFee,
  addPaymentItem,
  editFee,
  editPayment
} from '../Payments/util/_options'
import {overdues, paid, upcoming} from '../Payments/util/_tabs'
import PaymentsTable from "../Payments/payments_table/PaymentsTable";
import Spinner from "../shared/Spinner";
import {
  voidFeeCallback,
  markAsPaidCallback,
  refundCallback,
  adjustFeeCallback,
  addPaymentItemCallback
} from '../Payments/util/_option_window_callbacks'
import VoidFeeMarkAsPaid from "../Payments/option_windows/VoidFeeMarkAsPaid";
import AdjustFeeRefund from "../Payments/option_windows/AdjustFeeRefund";
import PaymentInformation from "../Payments/option_windows/PaymentInformation";
import {getPaymentsWithFilters} from '../Payments/util/_payments_tabs_api'
import DateFilteringBlock from "../Payments/DateFilteringBlock";
import TableFilterCell from "../Payments/payments_table/TableFilterCell";
import Button from "../shared/Button";
import AddPayment from "../Payments/option_windows/AddPayment";
import {buildQueryParams} from "../Payments/util/_build_request_params";
import EditPaymentFee from "../Payments/option_windows/EditPaymentFee";
import {allActions} from "../Payments/util/_manual_adjust_invoice_constants";

export const tabs = [
  {tab: paid, api: '/paids', className: 'Button--switchFirst'},
  {tab: overdues, api: '/overdues', className: 'Button--switchMiddle'},
  {tab: upcoming, api: '/upcomings', className: 'Button--switchLast'}
]

export default function TeamPayments({
  sports,
  statuses,
  venues,
  teamId,
  headLine,
  children
}) {
  const [isLoading, setLoading] = useState(true);
  const [chosenTab, setChosenTab] = useState(0);
  const [paymentsList, setPaymentsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [popupPayment, setPopupPayment] = useState(null);
  const [popupOption, setPopupOption] = useState(null);
  const [firstDate, setFirstDate] = useState('');
  const [lastDate, setLastDate] = useState('');
  const [status, setStatus] = useState(null);
  const [sport, setSport] = useState(null);
  const [venue, setVenue] = useState(null);

  const nextPage = useRef(null);

  useEffect(() => {
    onChangeFilters();
  }, [searchValue, firstDate, lastDate, status, sport, venue]);

  useEffect(() => {
    if (currentPage !== 1)
      apiRequestFunc(true, currentPage)
  }, [currentPage])

  useEffect(() => setLoading(false), [paymentsList]);

  useEffect(() => {
    status || venue || sport ? clearAllFilters() : onChangeFilters();
  }, [chosenTab])

  const clearAllFilters = () => {
    setStatus(null);
    setSport(null);
    setVenue(null);
  }

  const onChangeFilters = () => {
    setLoading(true);
    setCurrentPage(1);
    apiRequestFunc(false, 1);
  }

  const apiRequestFunc = (pageChanged, page) => {
    getPaymentsWithFilters(`${tabs[chosenTab].api}?${buildParams(page)}`)
      .then(({data: {data, next_page: newNextPage}}) => {
        nextPage.current = newNextPage;
        setPaymentsList(oldList => pageChanged ? [...oldList, ...data] : data);
      })
  }

  const onNextPage = useCallback(() => {
    if (nextPage.current) {
      setCurrentPage(nextPage.current);
      return true;
    }

    return false;
  }, [nextPage.current])

  const buildParams = (page) => {
    const params = {
      page,
      filters: {
        term: searchValue,
        date_from: firstDate,
        date_to: lastDate,
        sport_id: sport,
        status: status,
        venue_id: venue,
        team_id: teamId
      }
    }

    return buildQueryParams(params);
  }

  const disablePopup = () => {
    setPopupPayment(null);
    setPopupOption(null);
  }

  const onNewPaymentClick = () => {
    setPopupPayment({});
    setPopupOption(addPaymentItem);
  }

  const onClickMarkAsPaid = (payment) =>
    setPaymentsList(prevList => prevList.filter(item => item.id !== payment.id))

  const getNecessaryTab = (option, payment) => {
    switch (option) {
      case markAsPaid:
        return <VoidFeeMarkAsPaid payment={payment}
                                  option={option}
                                  cancelCallback={disablePopup}
                                  confirmCallback={markAsPaidCallback(onClickMarkAsPaid, disablePopup)}/>
      case voidFee:
        return <VoidFeeMarkAsPaid payment={payment}
                                  option={option}
                                  cancelCallback={disablePopup}
                                  confirmCallback={voidFeeCallback(setPaymentsList, disablePopup)}/>
      case refund:
        return <AdjustFeeRefund payment={payment}
                                option={option}
                                cancelCallback={disablePopup}
                                confirmCallback={refundCallback(setPaymentsList, disablePopup)}/>
      case adjustFee:
        return <AdjustFeeRefund payment={payment}
                                option={option}
                                cancelCallback={disablePopup}
                                confirmCallback={adjustFeeCallback(setPaymentsList, disablePopup)}/>
      case addPaymentItem:
        return <AddPayment option={option}
                           cancelCallback={disablePopup}
                           confirmCallback={addPaymentItemCallback(teamId, onChangeFilters, disablePopup)}/>
      case editFee:
      case editPayment:
        return <EditPaymentFee payment={payment}
                                option={option}
                                currentTab={chosenTab}
                                setPayment={setPopupPayment}
                                cancelCallback={disablePopup}
                                setPaymentList={setPaymentsList}
                                availableActions={allActions}
                                tabClass={'u-w-35'}/>
      default:
        return <PaymentInformation payment={payment}
                                   option={option}
                                   cancelCallback={disablePopup}/>
    }
  }

  return (
    <div style={{position: 'relative'}}>
      <Background isShow={popupPayment && popupOption}
                  setDisable={disablePopup}
                  backgroundStyle={'Underlayer-dark'}/>

      {popupOption && popupPayment ? getNecessaryTab(popupOption, popupPayment) : null}

      <div className="d-flex justify-content-between">
          <h1 className="u-font-size-30 u-m-0 u-pb-3">{headLine}</h1>
          {children &&
            <div className="d-flex justify-content-between">
                <Button mod="Button Button--primary Button--medium u-mr-1"
                        title="Add payment item"
                        id="add-payment-item-btn"
                        onClick={() => onNewPaymentClick()}/>
                {children}
            </div>}
      </div>

      <div className="d-flex justify-content-flex-start align-items-center u-pb-3">
        <div className="search-wrapper">
          <i className="far fa-search filter-search-icon"> </i>
          <input className="search-field browser-default"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  type="text"
                  placeholder="Search"/>
        </div>
        <DateFilteringBlock firstDate={firstDate}
                            setFirstDate={setFirstDate}
                            lastDate={lastDate}
                            setLastDate={setLastDate}>
          {children}
        </DateFilteringBlock>
      </div>

      <div className="row u-m-0 u-pb-3">
        {tabs.map(({tab, className}, index) => {
          const primaryClass = chosenTab === index ? 'Button--primary' : '';
          return <div key={index}
                      className={`Button Button--medium ${className} ${primaryClass}`}
                      onClick={() => setChosenTab(index)}>
                    {tab}
                </div>})}
      </div>            

      <div className="card jsLeaguesList">
        <div className="table__wrapper">
          <Spinner isLoading={isLoading}
                    position={{right: '50%', bottom: '25%'}}
          />
          {!isLoading &&
            <PaymentsTable paymentsList={paymentsList}
                           chosenTab={chosenTab}
                           setPayment={(payment) => setPopupPayment(payment)}
                           setOption={(option) => setPopupOption(option)}
                           setPage={onNextPage}
                           teamId={teamId}>
              {tabs[chosenTab].tab === paid &&
                <TableFilterCell className={`PaymentsTable-status-column`}
                                 columnName={'Status'}
                                 optionsList={statuses}
                                 chosenOption={status}
                                 setChosenOption={setStatus}/>}

                <TableFilterCell columnName={'Sport'}
                                 optionsList={sports}
                                 chosenOption={sport}
                                 setChosenOption={setSport}/>

                <TableFilterCell columnName={'Venue'}
                                 optionsList={venues}
                                 chosenOption={venue}
                                 setChosenOption={setVenue}/>
            </PaymentsTable>}
        </div>
      </div>
    </div>
  )
}

TeamPayments.propTypes = {
  sports: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired
    })
  ),
  statuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  venues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired
    })
  ),
  teamId: PropTypes.number.isRequired,
  headLine: PropTypes.string.isRequired,
  children: PropTypes.element
}
