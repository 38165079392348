export const TEAM_KEYS = {
  captain_email: 'email',
  captain_first_name: 'first_name',
  captain_last_name: 'last_name',
  captain_phone: 'phone',
  registration_listing_id: 'registration_listing_id',
  sport_id: 'sport_id',
  team_title: 'team_title',
  venue_id: 'venue_id'
};

export const RETURNING_TEAM_OPTIONS = {
  yes: 'Yes',
  no: 'No'
};

export const RETURNING_TEAM_RADIO_BUTTONS = [
  {title: 'Yes', id: RETURNING_TEAM_OPTIONS.yes},
  {title: 'No', id: RETURNING_TEAM_OPTIONS.no}
];
