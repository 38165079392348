import { flatten } from 'lodash';
import moment from 'moment-timezone';

const ERROR_INTERNAL = 'yellow';
const ERROR_EXTERNAL = 'red';

const setError = (errors, { round, id }, color) => {
  if (!errors[round]) {
    errors[round] = {};
  }
  if (errors[round][id] !== ERROR_INTERNAL) {
    errors[round][id] = color;
  }
};

export const isValid = (value, types) =>
  Array.isArray(types)
    ? types.every((type) => check[type](value))
    : check[types](value);

const check = {
  phone: (v) => /^\d{8}$/.test(v) || /^04\d{8}$/.test(v) || /^\+61\d{9}$/.test(v),

  float: (v) => /^-?\d?\.?\d+$/.test(v),

  integer: (v) => /^-?\d+$/.test(v),

  positive: (v) => v >= 0,

  minutes: (v) => /^[0-5]?\d$/.test(v),

  email: (v) => /^.+@.+\..+$/.test(v),

  required: (v) => v !== null && v !== '',
};

export function validateMatches(rounds, affectedMatches) {
  let saveChangesOptionDisabled = false;
  const errors = {};
  const matches = flatten(
    Object.keys(rounds).map((key) => rounds[key]),
  ).map(withDuration);
  const allMatches = [...affectedMatches, ...matches];

  matches.forEach((match1) => {
    allMatches.forEach((match2) => {
      if (
        match1.id !== match2.id
        && !isMatchScore(match1)
        && match1.start_date === match2.start_date
        && match1.space_id === match2.space_id
        && Math.abs(
          moment(match1.start_time).diff(
            moment(match2.start_time),
            'minutes',
          ),
        ) < match1.duration
      ) {
        const color =          match1.isAffected || match2.isAffected
            ? ERROR_EXTERNAL
            : ERROR_INTERNAL;
        setError(errors, match1, color);
        setError(errors, match2, color);
        saveChangesOptionDisabled = true;
      }
    });
  });
  return { errors, saveChangesOptionDisabled };
}

export const isMatchBYE = ({ team_a_id, team_b_id }) => !team_a_id || !team_b_id;
export const isMatchScore = ({ score_a, score_b }) => score_a !== null || score_b !== null;
export const isSwapable = (m) => m && !isMatchScore(m) && !isMatchBYE(m);

const withDuration = (match) => ({
  ...match,
  duration:
    match.duration
    || Math.abs(
      moment(match.start_time).diff(
        moment(match.end_time),
        'minutes',
      ),
    ),
});

export const affectedMatches = (rounds) => {
  const matches = [];
  Object.keys(rounds)
    .map((key) => rounds[key])
    .forEach(
      ({
        id,
        start_date,
        space_id,
        start_time,
        end_time,
        team_a_id,
        team_b_id,
      }) => matches.push({
          id,
          start_date,
          space_id,
          start_time,
          team_a_id,
          team_b_id,
          end_time,
          isAffected: true,
        }),
    );
  return matches.map(withDuration);
};
