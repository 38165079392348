import React from 'react';
import Button from '../shared/Button';
import Modal from '../shared/Modal';

export const ConfirmTerminateModal = ({
  active,
  entity,
  onConfirm,
  onCancel,
}) => (
  <Modal show={active} id={`delete-${entity}-modal`}>
    <div className="center-align">
      <h5 className="u-weight-500 u-pb-2">Terminate {entity}</h5>
      <p className="u-pb-3">
        Refund from company will be terminated. It will also trigger refunds to users directly from LeagueUP balance. Are you sure you want to do this ?
      </p>
      <div className="d-flex justify-content-center">
        <Button
          id={`confirm-delete-${entity}-btn`}
          mod="Button--redBg u-mr-1"
          onClick={onConfirm}
          title="Delete"
        />
        <Button
          id={`cancel-delete-${entity}-btn`}
          mod="Button--cancel"
          onClick={onCancel}
          title="Cancel"
        />
      </div>
    </div>
  </Modal>
);
