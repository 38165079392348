import React from "react";
import {noop} from "lodash";
import classNames from 'classnames';
import PhoneInput from 'react-phone-number-input';
import {TEAM_KEYS, RETURNING_TEAM_RADIO_BUTTONS} from "./utils/constants";
import Button from "../shared/Button";
import TextInput from "../shared/TextInput";

export default function ConfirmDetails({
  details,
  isPhoneValid,
  onChange,
  onSubmit,
  returningId,
  setReturningId
}) {
  const {email, first_name, last_name, phone, team_title} = details;
  const canRegister = isPhoneValid && email && first_name && last_name && phone && team_title;
  
  return <div className="RegisterTeams-form">
    <h1 className="u-font-size-30 u-mt-6 u-mb-2">Register a team</h1>
    <div className="u-font-size-17 u-mb-2">Step 2: Confirm your details</div>

    <div className="RegisterTeams-form--content">
      <div className="u-font-size-17 u-mb-2">Team information</div>

      <label htmlFor="teamTitle" className="RegisterTeams-form--detailsLabel">Team name</label>
      <TextInput id="teamTitle"
                 onChange={e => onChange(e, TEAM_KEYS.team_title)}
                 title=""
                 validate={['required']}
                 value={details.team_title || ''}/>

      <div className="RegisterTeams-form--detailsLabel">Returning team</div>
      <div className="d-flex align-items-center u-pt-1">
        {RETURNING_TEAM_RADIO_BUTTONS.map((rb, i) => 
          <label className="d-flex align-items-center u-font-size-13 u-color-mine-shaft u-mr-2" key={i}>
            <input id={rb.id}
                   checked={rb.id === returningId}
                   className="RegisterTeams-form--radioButton browser-default"
                   onClick={() => setReturningId(rb.id)}
                   onChange={noop}
                   type="radio"
                   value={rb.id}/>
              {rb.title}
          </label>)}
      </div>

      <div className="u-font-size-17 u-mb-2 u-mt-3">Your information</div>
      
      <label htmlFor="firstName" className="RegisterTeams-form--detailsLabel">First name</label>
      <TextInput id="firstName"
                 onChange={e => onChange(e, TEAM_KEYS.captain_first_name)}
                 title=""
                 validate={['required']}
                 value={details.first_name || ''}/>

      <label htmlFor="lastName" className="RegisterTeams-form--detailsLabel">Last name</label>
      <TextInput id="lastName"
                 onChange={e => onChange(e, TEAM_KEYS.captain_last_name)}
                 title=""
                 validate={['required']}
                 value={details.last_name || ''}/>

      <div className="u-mb-2">
        <div htmlFor="phone" className="RegisterTeams-form--detailsLabel u-mb-1">Phone</div>
        <PhoneInput id="phone"
                    className={classNames({'invalid-number': !isPhoneValid})}
                    defaultCountry="AU"
                    displayInitialValueAsLocalNumber
                    onChange={e => onChange(e, TEAM_KEYS.captain_phone)}
                    required
                    value={details.phone || ''}/>
      </div>

      <label htmlFor="email" className="RegisterTeams-form--detailsLabel">Email</label>
      <TextInput id="email"
                 onChange={(e) => onChange(e, TEAM_KEYS.captain_email)}
                 title=""
                 validate={['required']}
                 value={details.email || ''}/>

      <Button mod="Button Button--primary Button--medium"
              title="Register"
              disabled={!canRegister}
              onClick={() => onSubmit()}
              id="register_step"/>
    </div>
  </div>
}
