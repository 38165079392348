import PropTypes from 'prop-types';
import React from 'react';
import { update, destroy } from '../../lib/API';
import { redirect } from '../../lib/utils';
import { sortMatchesByDateTime } from '../../lib/dateTimeHelper';
import { FixtureActions } from '../EditFixture/FixtureActions';
import { MatchRow } from './MatchRow';
import { editFixtureDTO } from '../../lib/DTO';
import {
  getFinalFixtureUrl,
  getLeagueUrl,
} from '../../helpers/endpoints';
import { ConfirmDeleteModal } from '../EditFixture/ConfirmDeleteModal';
import {
  affectedMatches,
  validateMatches,
} from '../../lib/validation';

const SKIP_VALIDATION = false;

export default class EditFinalFixture extends React.Component {
  static propTypes = {
    fixture: PropTypes.object.isRequired,
    teams: PropTypes.array.isRequired,
    spaces: PropTypes.array.isRequired,
    rounds: PropTypes.object.isRequired,
    incompleted_round_numbers: PropTypes.array.isRequired,
    matches_in_the_same_venue: PropTypes.array.isRequired,
    league_completed: PropTypes.bool.isRequired,
    has_results: PropTypes.bool.isRequired,
  };

  state = {
    rounds: this.props.rounds,
    deleteFixtureActive: false,
    errors: {},
    saveChangesOptionDisabled: true,
    affectedMatches: affectedMatches(
      this.props.matches_in_the_same_venue,
    ),
  };

  get teamsOptions() {
    return [{ id: null, title: 'TBD' }, ...this.props.teams];
  }

  validate = () => {
    const { rounds, affectedMatches } = this.state;
    this.setState(validateMatches(rounds, affectedMatches, true));
  };

  handleDeleteFixture = () => {
    this.setState({ deleteFixtureActive: true });
  };

  handleConfirmDeleteFixture = () => {
    const {
      fixture: { league_id },
    } = this.props;

    this.setState({ deleteFixtureActive: false }, () => destroy({}, getFinalFixtureUrl(league_id), () => redirect(getLeagueUrl(league_id)),
      ),
    );
  };

  handleCancelDeleteFixture = () => {
    this.setState({ deleteFixtureActive: false });
  };

  swapTeams = (match1, match2, round) => {
    const t1 = match1.team_a_id;
    const t2 = match1.team_b_id;

    this.updateMatchField(
      round,
      match1.id,
      'team_a_id',
      match2.team_a_id,
      SKIP_VALIDATION,
    );
    this.updateMatchField(
      round,
      match1.id,
      'team_b_id',
      match2.team_b_id,
      SKIP_VALIDATION,
    );
    this.updateMatchField(
      round,
      match2.id,
      'team_a_id',
      t1,
      SKIP_VALIDATION,
    );
    this.updateMatchField(
      round,
      match2.id,
      'team_b_id',
      t2,
      SKIP_VALIDATION,
    );
  };
  handleSave = () => {
    const {
      fixture: { league_id },
      rounds: originalRounds,
    } = this.props;
    const { rounds } = this.state;
    update(
      editFixtureDTO({ rounds, originalRounds, publish: 1 }),
      getFinalFixtureUrl(league_id),
      this.afterSave,
      this.onSaveError,
    );
  };

  afterSave = () => {
    const {
      fixture: { league_id },
    } = this.props;
    redirect(getLeagueUrl(league_id));
  };

  onSaveError = (invalidMatches) => {
    const { rounds } = this.state;
    const invalidIds = invalidMatches.map(
      ({ match_id }) => +match_id,
    );
    const errors = {};

    Object.keys(rounds).forEach((r) => {
      rounds[r].forEach(({ id }) => {
        if (invalidIds.includes(+id)) {
          if (!errors[r]) {
            errors[r] = {};
          }
          errors[r][id] = 'red';
        }
      });
    });
    this.setState({ errors });
  };

  updateMatchField = (
    round,
    match_id,
    field,
    value,
    skipValidation = false,
  ) => {
    const matches = this.state.rounds[round];
    const match = matches.find((m) => m.id === match_id);
    const m_index = matches.indexOf(match);
    match[field] = value;
    match.updated = true;
    matches[m_index] = match;

    this.setState(
      (s) => ({
        rounds: {
          ...s.rounds,
          [round]: matches,
        },
      }),
      () => {
        !skipValidation && this.validate();
      },
    );
  };

  render() {
    const {
      rounds,
      deleteFixtureActive,
      saveChangesOptionDisabled,
    } = this.state;
    const {
      has_results,
      fixture: { league_id },
    } = this.props;

    return (
      <div className="EditFixture">
        <div className="d-flex align-items-baseline justify-content-flex-start">
          <a href={`/leagues/${league_id}`} className="Button u-mr-1">
            Back
          </a>
          <FixtureActions
            position="top"
            disabled={saveChangesOptionDisabled}
            onSave={this.handleSave}
            onDelete={!has_results && this.handleDeleteFixture}
            hideSave
          />
        </div>
        <table className="edit-fixture edit-fixture_left-padded u-mb-8 u-w-100">
          <tbody>
            {Object.keys(rounds)
              .sort((a, b) => a - b)
              .map((r) => this.renderRound(rounds[r], r))}
          </tbody>
        </table>
        <div className="EditFixture-footer">
          <div className="Section">
            <div className="Section-content">
              <div className="u-bg-white u-pt-2 u-pl-2 u-pr-2">
                <FixtureActions
                  position="bottom"
                  disabled={saveChangesOptionDisabled}
                  onSave={this.handleSave}
                  hideSave
                />
              </div>
            </div>
          </div>
        </div>
        <ConfirmDeleteModal
          entity="fixture"
          active={deleteFixtureActive}
          onConfirm={this.handleConfirmDeleteFixture}
          onCancel={this.handleCancelDeleteFixture}
        />
      </div>
    );
  }

  renderRound = (matches, round) => {
    const sortedMatches = matches.sort(sortMatchesByDateTime);
    const roundTitle = [
      ...new Set(matches.map((m) => m.round_title)),
    ][0];

    return [
      <tr className="edit-fixture__round-row" key={round}>
        <td className="edit-fixture__cell edit-fixture__cell_title">
          {roundTitle}
        </td>
        <td className="edit-fixture__cell edit-fixture__cell_subtitle edit-fixture__cell_time">
          time
        </td>
        <td className="edit-fixture__cell edit-fixture__cell_subtitle">
          space
        </td>
        <td className="edit-fixture__cell edit-fixture__cell_move-actions" />
        <td className="edit-fixture__cell edit-fixture__cell_subtitle edit-fixture__cell_right-aligned">
          team a
        </td>
        <td className="edit-fixture__cell edit-fixture__cell_subtitle">
          team b
        </td>
      </tr>,
      sortedMatches.map((match, i) => (
        <MatchRow
          key={match.id}
          value={match}
          round={round}
          prevMatch={sortedMatches[i - 1]}
          nextMatch={sortedMatches[i + 1]}
          spaces={this.props.spaces}
          errors={this.state.errors}
          onChange={this.updateMatchField}
          onSwap={this.swapTeams}
          teams={this.teamsOptions}
        />
      )),
    ];
  };
}
