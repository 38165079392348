import PropTypes from 'prop-types';
import React from 'react';
import Button from '../shared/Button';
import TeamsSelect from './TeamsSelect';
import TeamsList from './TeamsList';

export default class Teams extends React.Component {
  static propTypes = {
    teams: PropTypes.array,
    onChange: PropTypes.func,
    onRemove: PropTypes.func,
    team_ids: PropTypes.array,
  };

  state = {
    selectTeamsActive: false,
  };

  onTeamAdd = team => {
    this.props.onChange(team);
    this.setState({ selectTeamsActive: false });
  };

  onTeamRemove = team => {
    this.props.onRemove(team);
  };

  render() {
    const { team_ids, teams } = this.props;
    const { selectTeamsActive } = this.state;

    return <div>
      <div className="d-flex justify-content-between">
        <div className="u-weight-500">
          {selectTeamsActive && 'Add '}
          Teams
        </div>

        {selectTeamsActive 
        ? <Button mod="Button--cancel Button--small u-font-size-13"
                  title="Cancel"
                  id="add_teams_cancel_button"
                  onClick={() => this.setState({ selectTeamsActive: false })}/>
        : <Button mod="Button--small u-font-size-13"
                  title="Add teams"
                  id="add_teams_button"
                  onClick={() => this.setState({ selectTeamsActive: true })}/> }
      </div>

      {selectTeamsActive
      ? <TeamsSelect ignore={team_ids}
                     onSelect={(team) => this.onTeamAdd(team)}
                     onCreate={(team) => this.onTeamAdd(team)} />
      : <TeamsList teams={teams}
                   teamIds={team_ids}
                   onRemove={(team) => this.props.onRemove(team)}/>}
    </div>
  }
}
