import PropTypes from 'prop-types';
import React from 'react';
import axios from 'axios';
import Details from './Details';
import TeamPayments from "./TeamPayments";
import Button from "../shared/Button";

const PAGE = { DETAILS: 'details', FINANCES: 'finances' };

export default class TeamForm extends React.Component {
  static propTypes = {
    team: PropTypes.object.isRequired,
    operations: PropTypes.array.isRequired,
    balance: PropTypes.string.isRequired,
    user_id: PropTypes.number.isRequired,
    players: PropTypes.array.isRequired,
    payments_filters: PropTypes.object.isRequired
  }

  state = {
    page: PAGE.DETAILS,
    currencySymbol: '',
  };

  componentDidMount() {
    return axios
      .get('/api/settings/config')
      .then((response) => {
        this.setState({
          currencySymbol: response.data.currency_symbol,
        });
      })
      .catch(() => {
        M.toast({
          html: 'Failed to receive configuration',
          classes: 'u-bg-red',
        });
      });
  }

  togglePage = () => {
    if (this.state.page === PAGE.DETAILS) {
      this.setState({ page: PAGE.FINANCES });
    } else {
      this.setState({ page: PAGE.DETAILS });
    }
  };

  render() {
    const {page} = this.state;
    const {title, id: teamId} = this.props.team;
    const {sports, statuses, venues} = this.props.payments_filters;

    return page === PAGE.FINANCES
    ? <TeamPayments teamId={teamId}
                    sports={sports}
                    venues={venues}
                    statuses={statuses}
                    headLine={`${title} payments`}>
        <Button mod="Button Button--medium"
                title="Back to profile"
                id="financial-back-to-profile-btn"
                onClick={() => location.replace(`/teams/${teamId}`)}/>
      </TeamPayments>
    : <React.Fragment>
        <div>Team Profile</div>
        <Details team={this.props.team}
                  players={this.props.players}
                  togglePage={this.togglePage}
                  balance={this.props.balance}
                  isBeDeleted={this.props.isBeDeleted}
                  captain={this.props.captain}/>
      </React.Fragment>
  }
}
