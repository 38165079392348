import React, { useEffect, useState } from "react";
import { noop } from 'lodash';
import moment from 'moment-timezone';
import Calendar from '../shared/Calendar';
import Background from "../shared/Background";
import FinancialTable from "./FinancialTable";
import ManualPayoutsTable from "./ManualPayoutsTable";
import { buildQueryParams } from "../Payments/util/_build_request_params";
import axios from "axios";

const tabs = [
    {tab: 'Online payments', tabQuery: 'online', className: 'Button--switchFirst'},
    {tab: 'Manual payments', tabQuery: 'manual', className: 'Button--switchLast'}

];

const query = new URLSearchParams(location.search);

const tabIndex = tabs.findIndex(tab => tab['tabQuery'] === query.get('tab_name'));
const tab = tabIndex !== -1 ? tabIndex : 0;

const authenticityToken = document.head.querySelector('meta[name="csrf-token"]').getAttribute('content');

export default function FinancialReports() {
    const [datefrom, setDateFrom] = useState('');
    const [dateto, setDateTo] = useState('');
    const [chosenTab, setChosenTab] = useState(tab);
    const [showFirstCalendar, setShowFirstCalendar] = useState(false);
    const [showSecondCalendar, setShowSecondCalendar] = useState(false);
    const [payoutsList, setPayoutsList] = useState([]);


    useEffect(() => {
        if (chosenTab === 0) {
            apiRequestFunc(false, 1);
        }
    }, [datefrom, dateto]);

    useEffect(() => {
        clearChangeFilters()
        setPayoutsList([]);
        if (chosenTab === 1) {
            saveFilterDatesFromQuery()
        }
    }, [chosenTab]);

    const clearChangeFilters = () => {
        setDateTo('');
        setDateFrom('');
    };

    const saveFilterDatesFromQuery = () => {
        setDateFrom(query.get('from_date'));
        setDateTo(query.get('to_date'));
    };

    const apiRequestFunc = () => {
        if (!datefrom || !dateto) {
            return
        }

        axios.get(`../api/settings/financial_payouts?${buildParams()}`)
            .then(({data: {data}}) => {
                setPayoutsList(data)
            })
    };

    const buildParams = () => {
        const params = {
            filters: {
                date_from: datefrom,
                date_to: dateto
            }
        }

        return buildQueryParams(params);
    };

    const disableAll = () => {
        setShowFirstCalendar(false);
        setShowSecondCalendar(false);
    };

    const onSelect = (value, setDate, setShowCalendar) => {
        setDate(value.format('YYYY-MM-DD'))
        setShowCalendar(false);
    };

    const changeTab = (index) => {
       setChosenTab(index)
    };

    return(
        <div className="card FinancialReports-container" style={{overflowX: 'visible'}}>
            <Background setDisable={() => disableAll()}
            isShow={showFirstCalendar || showSecondCalendar}/>

            <div className="card-panel">
                <h1 className="page__subtitle u-mb-2">Financial reports</h1>

                <div className="row u-m-0 u-pb-3" style={{position: 'relative'}}>
                    {tabs.map(({tab, className}, index) => {
                        const primaryClass = chosenTab === index ? 'Button--primary' : '';
                        return (
                            <div key={index}
                                 className={`Button Button--medium ${className} ${primaryClass}`}
                                 onClick={() => changeTab(index)}>
                                {tab}
                            </div>
                        )
                    })}
                </div>

                <div className="row mb-5">
                    <div className="input-field col s6">
                        <div
                            className="input-field u-mt-0"
                            onClick={() => setShowFirstCalendar(true)}
                        >
                          <span className="calendar-icon">
                            <input
                              id="date_from"
                              type="text"
                              value={datefrom ? moment(datefrom).format('DD MMM, YYYY') : ''}
                              onChange={noop} // in order to eliminate React warning, onChange should be provided here
                            />
                          </span>

                          <label 
                            htmlFor="date_from" 
                            className={datefrom ? 'active FinancialReports-label' : 'FinancialReports-label'}>
                            From
                          </label>

                          {showFirstCalendar && (
                            <div className="popup__content">
                              <Calendar
                                onSelect={(value) => onSelect(value, setDateFrom, setShowFirstCalendar)
                                }
                                value={datefrom}
                              />
                            </div>
                          )}
                        </div>
                    </div>
                    <div className="input-field col s6">
                        <div
                            className="input-field u-mt-0"
                            onClick={() => setShowSecondCalendar(true)}
                        >
                          <span className="calendar-icon">
                            <input
                              id="date_from"
                              type="text"
                              value={dateto ? moment(dateto).format('DD MMM, YYYY') : ''}
                              onChange={noop} // in order to eliminate React warning, onChange should be provided here
                            />
                          </span>

                          <label 
                            htmlFor="date_from" 
                            className={dateto ? 'active FinancialReports-label' : 'FinancialReports-label'}>
                            To
                          </label>

                          {showSecondCalendar && (
                            <div className="popup__content">
                              <Calendar
                                onSelect={(value) => onSelect(value, setDateTo, setShowSecondCalendar)
                                }
                                value={datefrom}
                              />
                            </div>
                          )}
                        </div>
                    </div>
                </div>


                {chosenTab === 0 ? 
                    <FinancialTable 
                        payoutsList={payoutsList}
                        token={authenticityToken}
                    /> 
                    : <ManualPayoutsTable 
                        fromDate={datefrom} 
                        toDate={dateto}
                        token={authenticityToken}
                    />}
            </div>
        </div>
    )
}