import PropTypes from 'prop-types';
import React from 'react';
import { create, get, destroy } from '../../lib/API';
import { findById, redirect } from '../../lib/utils';
import Step from '../FixturesForm/Step';
import Button from '../shared/Button';
import TimeSlots from '../FixturesForm/TimeSlots';
import SlotsCount from '../FixturesForm/SlotsCount';
import Select from '../shared/Select';
import Switch from '../shared/Switch';
import { getLeagueUrl } from '../../helpers/endpoints';
import { replaceTeamDTO } from '../../lib/DTO';

const MODE_TT = 'Team with Team';
const MODE_BT = 'BYE with Team';
const MODE_TB = 'Team with BYE';
const SWITCH_OPTIONS = [MODE_TT, MODE_BT, MODE_TB];
const renderCheckTeams = (teamsArr) => teamsArr.length ? null : (
    <div className="has-error"> Sorry, no teams for select </div>
  );

export default class ReplaceTeamForm extends React.Component {
  static propTypes = {
    wizard: PropTypes.string.isRequired,
    league: PropTypes.object.isRequired,
    fixture: PropTypes.object,
    spaces: PropTypes.array.isRequired,
    teams_in: PropTypes.array.isRequired,
    teams_out: PropTypes.array.isRequired,
    slots_number: PropTypes.number.isRequired,
    sport_duration: PropTypes.number.isRequired,
    available_rounds: PropTypes.array.isRequired,
    allow_team_bye_swap: PropTypes.bool.isRequired,
  };

  state = {
    mode: null,
    team_in_id: null,
    team_out_id: null,
    timeslots_data: {},
    loadedSpaces: 0,
    newSlot: null,
    slots_number: this.props.slots_number,
    wizard: this.props.wizard,
    selected: [],
  };

  setLoadedTimeslots = (timeslots) => {
    this.setState({ selected: timeslots });
  };

  get isValid() {
    const {
      mode,
      team_in_id,
      team_out_id,
      selected,
      slots_number,
    } = this.state;
    if (mode === MODE_TT) {
      return !!team_in_id && !!team_out_id;
    }
    if (mode === MODE_BT) {
      return !!team_in_id && selected.length === slots_number;
    }
    if (mode === MODE_TB) {
      return !!team_out_id;
    }
    return false;
  }

  goBack = () => {
    const { league, fixture } = this.props;
    destroy({}, `/fixtures/${fixture.id}/mass_timeslots`, () => redirect(getLeagueUrl(league.id)),
    );
  };

  submit = () => {
    const { league, wizard } = this.props;
    create(
      replaceTeamDTO(this.state),
      `/leagues/${league.id}/${wizard}`,
      this.goBack,
      this.goBack,
    );
  };

  handleModeSwitch = (mode) => {
    this.setState({ mode });
  };

  handleSelectFromRound = (from_round) => {
    const { league, wizard } = this.props;
    get(
      `/leagues/${league.id}/${wizard}/new?${wizard}[from_round]=${from_round}`,
      () => {
        this.setState({ from_round });
      },
    );
  };

  handleSelectTeamIn = (team_in_id) => {
    this.setState({ team_in_id });
  };

  handleSelectTeamOut = (team_out_id) => {
    this.setState({ team_out_id });
  };

  disabledSwitchButtons = () => {
    const { allow_team_bye_swap } = this.props;

    return allow_team_bye_swap ? [] : [MODE_TB];
  };

  render() {
    const {
      spaces,
      available_rounds,
      teams_in,
      teams_out,
      fixture,
      sport_duration,
    } = this.props;

    const {
      mode,
      selected,
      slots_number,
      from_round,
      team_in_id,
      team_out_id,
    } = this.state;
    const selectedCount = selected.length;
    const teamIn = findById(teams_in, team_in_id).title;
    const teamOut = findById(teams_out, team_out_id).title;
    let counter = 1;

    return (
      <div className="fixture-form">
        <Step
          number={counter++}
          autoCollapse
          title="Replace"
          value={mode}
          completed={(v) => v}
        >
          <Switch
            value={mode}
            values={SWITCH_OPTIONS}
            disabledButtons={this.disabledSwitchButtons()}
            onSelect={this.handleModeSwitch}
            id="mode-switch"
          />
        </Step>

        <Step
          number={counter++}
          autoCollapse
          title="From round"
          value={from_round}
          completed={(v) => v}
          disabled={!mode}
        >
          <Select
            id="from_round"
            name="from_round"
            title="From round"
            fieldMod="input-field_small"
            required
            value={from_round}
            validate={['required']}
            items={available_rounds}
            onSelect={this.handleSelectFromRound}
          />
        </Step>

        {mode && mode !== MODE_TB && (
          <Step
            number={counter++}
            autoCollapse
            title="Team in"
            value={teamIn}
            completed={(v) => v}
            disabled={!from_round}
          >
            <Select
              id="team_in"
              name="team_in"
              title="Team in"
              fieldMod="input-field_small"
              required
              value={team_in_id}
              validate={['required']}
              items={teams_in}
              onSelect={this.handleSelectTeamIn}
            />
            {renderCheckTeams(teams_in)}
          </Step>
        )}

        {mode && mode !== MODE_BT && (
          <Step
            number={counter++}
            autoCollapse
            title="Team out"
            value={teamOut}
            completed={(v) => v}
            disabled={!from_round}
          >
            <Select
              id="team_out"
              name="team_out"
              title="Team out"
              fieldMod="input-field_small"
              required
              value={team_out_id}
              validate={['required']}
              items={teams_out}
              onSelect={this.handleSelectTeamOut}
            />
            {renderCheckTeams(teams_out)}
          </Step>
        )}

        {mode === MODE_BT && from_round && (
          <Step
            number={counter++}
            disabled={!from_round}
            title="Timeslots"
            completed={() => selectedCount === slots_number}
            value={selected.map((s) => s.start_time).join(', ')}
          >
            <div>
              <SlotsCount
                current={selectedCount}
                required={slots_number}
              />
              <TimeSlots
                loadTimeslots={this.setLoadedTimeslots}
                isAllowAdd={selectedCount < slots_number}
                fixtureId={fixture.id}
                spaces={spaces}
                sport_duration={sport_duration}
                onChangeSelected={(selected) => this.setState({ selected })
                }
                onAdd={(newSlot) => this.setState({ newSlot })}
              />
            </div>
          </Step>
        )}

        <div className="u-mt-3 d-flex">
          <Button
            mod="Button--primary u-mr-1"
            title="Save Changes"
            disabled={!this.isValid}
            id="create_button"
            onClick={this.submit}
          />
          <Button
            mod="Button--cancel"
            title="Cancel"
            id="cancel_button"
            onClick={this.goBack}
          />
        </div>
      </div>
    );
  }
}
