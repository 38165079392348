import PropTypes from 'prop-types';
import React from 'react';
import {noop} from 'lodash';
import moment from 'moment-timezone';
import Select from '../shared/Select';
import TextInput from '../shared/TextInput';
import Calendar from '../shared/Calendar';
import axios from 'axios';

export default class Listing extends React.Component {
  static propTypes = {
    divisions: PropTypes.array.isRequired,
    listing: PropTypes.object.isRequired,
    venues: PropTypes.array.isRequired,
    sports: PropTypes.array.isRequired,
    days: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  state = {
    ...this.props.listing,
    description: '',
    enableEditDateMode: false,
    start_date: this.props.listing.start_date || moment().format('YYYY-MM-DD'),
    duration: this.props.listing.duration || '',
    capacity: this.props.listing.capacity || '',
    price_per_player: this.props.listing.price_per_player || '',
    currencySymbol: ''
  };

  componentDidMount() {
    return axios
      .get('/api/settings/config')
      .then((response) => {
        this.setState({
          currencySymbol: response.data.currency_symbol,
        });
      })
      .catch(() => {
        M.toast({
          html: 'Failed to receive configuration',
          classes: 'u-bg-red',
        });
      });
  };

  updateField(field, value) {
    const { onChange } = this.props;
    const { listing } = this.state;
    this.setState({ [field]: value }, () => onChange(this.state));
  };

  onChooseDueDate = (date) => {
    const { onChange } = this.props
    this.setState((prevState) => ({
      enableEditDateMode: !prevState.enableEditDateMode,
      start_date: date.format('YYYY-MM-DD')
    }), () => onChange(this.state));
  };

  teeTimeFormatter = (time) => {
    let correctTime = '';
    if (!time) {
        return correctTime
    } else {
        return time
    }
  };

  render() {
    const {
      days,
      divisions,
      onChange,
      sports,
      venues
    } = this.props;

    const {
      capacity,
      currencySymbol,
      day,
      division_id,
      duration,
      enableEditDateMode,
      price_per_player,
      start_date,
      sport_id,
      venue_id
    } = this.state;

    const daysObj = days.map(day => ({
      id: day,
      title: day,
    }));

    const local_date = moment(start_date).format('DD MMM, YYYY');

    return <div className="new-league">
      <div className="u-weight-500 u-pb-2">League details</div>

      <Select title="Sport"
              required
              items={sports}
              value={sport_id}
              onSelect={(id) => this.updateField('sport_id', +id)}
              name="listing[sport_id]"
              id="listing_sport_id"/>

      <Select title="Venue"
              required
              items={venues}
              value={venue_id}
              onSelect={(id) => this.updateField('venue_id', +id)}
              name="listing[venue_id]"
              id="listing_venue_id"/>

      <Select title="Day"
              required
              items={daysObj}
              value={day}
              onSelect={(day) => this.updateField('day', day)}
              name="listing[day]"
              id="listing_day"/>

      <Select title="Division"
              required
              items={divisions}
              value={division_id}
              onSelect={(id) => this.updateField('division_id', +id)}
              name="listing[division_id]"
              id="listing_division_id"/>

      <div className="input-field u-mt-4 Listing-duedate"
            onClick={() => this.setState({ enableEditDateMode: true })}>
        <span className="calendar-icon">
          <input id="due_date"
                  type="text"
                  value={local_date}
                  // in order to eliminate React warning, onChange should be provided here
                  onChange={noop}/> 
        </span>
        <label htmlFor="due_date" className="input-field__label select-label_filled active">
          Start date
        </label>

        {enableEditDateMode &&
          <div className="popup__content">
            <Calendar onSelect={(value) => this.onChooseDueDate(value)}
                      value={start_date}/>
          </div>}
        </div>

        <div className='Listing-textinput'>
          <TextInput title="Season Duration"
                      id="season_duration"
                      validate={['required']}
                      value={duration}
                      onChange={duration => this.setState({ duration }, () => onChange(this.state))}/>
        </div>

        <div className='Listing-textinput'>
            <TextInput title="League Capacity"
                        id="league_capacity"
                        validate={['required']}
                        value={capacity}
                        onChange={capacity => this.setState({ capacity }, () => onChange(this.state))}/>
        </div>

        <div className='Listing-textinput'>
            <TextInput title="Match fee"
                        id="price_per_player"
                        validate={['required']}
                        measure={currencySymbol}
                        value={price_per_player}
                        onChange={price => this.setState({ price_per_player: price }, () => onChange(this.state))}/>
        </div>
    </div>
  }
}
