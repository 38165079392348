import PropTypes from 'prop-types';
import React from 'react';
import {Col, Row} from '../shared/Grid';
import TextInput from '../shared/TextInput';
import Button from '../shared/Button';
import Popup from '../shared/Popup';
import PlayerForm from './PlayerForm';
import {create, get} from '../../lib/API';
import {teamDTO} from '../../lib/DTO';
import {updateSelects} from '../../lib/materialHelper';
import {fullName} from '../../lib/utils';

export default class TeamCreate extends React.Component {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
  };

  state = {
    title: '',
    user_id: null,
    player: null,
    players: [],
    user_attributes: null,
    createPlayerActive: false,
    errors: {},
  };

  get newPlayerItem() {
    return {
      element: (
        <li key={-1}
            onClick={() => this.setState({ user_id: null, createPlayerActive: true })}>
          <span className="team-create__link">Add new player</span>
        </li>
      ),
    };
  }

  componentDidMount() {
    get('/api/players', (data) => {
      const dataWithTitle = data.map((item) => {
        if (!item.title) {
          item.title = `${item.first_name} ${item.last_name}`;
        }

        return item;
      });

      this.setState(
        {
          players: [this.newPlayerItem, ...dataWithTitle],
        },
        () => updateSelects(),
      );
    });
  }

  get isValid() {
    const { title, user_id, player } = this.state;

    return !!title && (!!user_id || !!player);
  }

  addPlayer(player) {
    this.setState({
      players: [...this.state.players, player],
      user_id: player.id,
      createPlayerActive: false,
    });
  }

  submit() {
    const data = { team: teamDTO(this.state) };
    create(
      data,
      '/api/teams',
      (team) => this.props.onCreate(team),
      (errors) => this.setState({ errors }),
    );
  }

  render() {
    const { onCancel } = this.props;
    const {
      title,
      players,
      createPlayerActive,
      user_id,
      errors,
    } = this.state;

    return <div className="team-create">
      <Row classMod="u-mb-2">
        <Col l="12">
          <div className="card-title">Create team</div>
        </Col>
        <Col l="12">
          <TextInput validate={['required']}
                      title="Team Name"
                      id="team_name"
                      error={errors.title}
                      value={title}
                      onChange={(title) => this.setState({ title })}/>
        </Col>

        {createPlayerActive
        ? <PlayerForm onCancel={() => this.setState({ createPlayerActive: false })}
                      onCreate={(player) => this.addPlayer(player)}/>
        : <Col l="12">
            <Popup title="Captain"
                    selected={players.find((p) => p.id === user_id)}
                    required
                    items={players}
                    value={user_id}
                    onSelect={({ id }) => this.setState({ user_id: id })}
                    id="captain_id"
                    name="captain_id"/>
          </Col> }
        <div className="u-w-100 u-pb-2" />

        {!createPlayerActive &&
          <Col l="12">
            <div className="d-flex">
              <Button mod="Button--small u-mr-1"
                      disabled={!this.isValid}
                      title="Create"
                      id="add_team_create_button"
                      onClick={() => this.submit()}/>
              <Button mod="Button--cancel Button--small u-font-size-13"
                      title="Cancel"
                      id="add_team_cancel_button"
                      onClick={onCancel}/>
            </div>
          </Col>}
      </Row>
    </div>
  }
}
