import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames/bind';
import { toggleKey } from '../../lib/utils';
import { update, create, destroy, get } from '../../lib/API';
import { venueDTO, spaceDTO } from '../../lib/DTO';
import TextInput from '../shared/TextInput';
import { Col, Row } from '../shared/Grid';
import Button from '../shared/Button';
import { updateInputs } from '../../lib/materialHelper';
import SpaceForm from './SpaceForm';

export default class VenueForm extends React.Component {
  static propTypes = {
    id: PropTypes.number,
    title: PropTypes.string,
    facility: PropTypes.string,
    address: PropTypes.string,
    deleted_at: PropTypes.instanceOf(Date),

    spaces: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        position: PropTypes.number.isRequired,
        deletable: PropTypes.bool,
        status: PropTypes.bool,
      }),
    ).isRequired,
    archived_spaces: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        position: PropTypes.number.isRequired,
        deletable: PropTypes.bool,
        status: PropTypes.bool,
      }),
    ),
    sports: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        deleted_at: PropTypes.instanceOf(Date),
      }),
    ).isRequired,
  };

  state = {
    id: this.props.id,
    title: this.props.title,
    facility: this.props.facility,
    address: this.props.address,
    deleted_at: this.props.deleted_at,
    sports: this.props.sports,
    spaces: this.props.spaces,
    archived_spaces: this.props.archived_spaces,
    allSports: [],
    editableSpace: {
      id: null,
      title: '',
      position: null,
      sport_ids: [],
    },
    showForm: false,
    showArchivedSpaces: false,
    editMod: 'New',
  };

  componentDidMount() {
    get('/api/settings/sports', (data) => {
      this.setState({ allSports: data });
    });
    updateInputs();
  }

  toggleArchivedStatus = () => {
    this.setState({
      deleted_at: this.isArchived() ? null : new Date(),
    });
  };

  isArchived = () => !!this.state.deleted_at;

  onFormChange = (field, value) => this.setState({ [field]: value });

  disableSpaceEditMod = () => {
    this.setState({
      editableSpace: {},
      showForm: false,
    });
  };

  enableSpaceEditMod = () => {
    this.setState({
      editableSpace: {
        id: -new Date().getTime(),
        sport_ids: [],
      },
      showForm: true,
      editMod: 'New',
    });
  };

  onSpaceFormSubmit = (id, position, title, sport_ids) => {
    const { spaces, archived_spaces } = this.state;

    const not_changed_spaces = [...archived_spaces, ...spaces].filter(
      (item) => item.id !== id,
    );
    const validation = this.validate_space(
      not_changed_spaces,
      title,
      sport_ids,
    );
    const spacePosition = !isNaN(position)
      ? position
      : spaces.length + 1;

    if (validation) {
      this.setState({
        editableSpace: {
          id: id || -new Date().getTime(),
          title,
          position: spacePosition,
          sport_ids,
          errors: { ...validation },
        },
        showForm: true,
      });
    } else {
      this.setState({
        spaces: [
          ...spaces.filter((item) => item.id !== id),
          {
            id,
            position: spacePosition,
            title,
            sport_ids,
          },
        ],
        editableSpace: {},
        showForm: false,
      });
    }
  };

  validate_space = (not_changed_spaces, title, sport_ids) => {
    const title_exists = not_changed_spaces.filter((item) => item.title === title).length > 0;
    const sport_not_selected = sport_ids.length === 0;
    const errors = {};

    if (title_exists) {
      errors.title = 'already exists';
    }
    if (title.length === 0) {
      errors.title = 'is required';
    }
    if (sport_not_selected) {
      errors.sport_ids = 'select at least one';
    }

    if (Object.keys(errors).length !== 0) {
      return errors;
    }
  };

  onSpaceEdit = (space) => {
    this.setState({
      editableSpace: {
        id: space.id,
        title: space.title,
        position: space.position,
        sport_ids: space.sport_ids,
      },
      showForm: true,
      editMod: 'Edit',
    });
  };

  onDragStart = (e, index) => {
    this.draggedItem = this.state.spaces[index];
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', e.target.parentNode);
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
  };

  onDragOver = (index) => {
    const draggedOverItem = this.state.spaces[index];
    if (this.draggedItem === draggedOverItem) {
      return;
    }

    const spaces = this.state.spaces.filter(
      (item) => item !== this.draggedItem,
    );
    spaces.splice(index, 0, this.draggedItem);
    const updated = spaces.map((space, index) => {
      space.position = index;
      return space;
    });
    this.setState({ spaces: updated });
  };

  onDragEnd = () => {
    this.draggedIdx = null;
  };

  onHandleRestore = (space, index) => {
    const { spaces } = this.state;
    spaces[index].status = !spaces[index].status;
    this.setState({ spaces });
  };

  onHandleRemove = (space, index) => {
    const { spaces } = this.state;
    if (space.id > 0) {
      spaces[index].status = true;
      this.setState({
        archived_spaces: [...this.state.archived_spaces, space],
      });
    } else {
      spaces.splice(index, 1);
    }

    this.setState({
      spaces: spaces.filter((s) => s.id != space.id),
    });
  };

  onHandleArchiveSpace = (space, index) => {
    const { archived_spaces } = this.state;
    archived_spaces[index].status = !archived_spaces[index].status;

    this.setState({
      archived_spaces: archived_spaces.filter(
        (s) => s.id != archived_spaces[index].id,
      ),
      spaces: [...this.state.spaces, archived_spaces[index]],
    });
  };

  creationHandler = (venue) => {
    create(
      { venue },
      '/settings/venues',
      (responce) => location.replace('/settings/venues'),
      (errors) => {
        this.setState({ errors: errors.responseJSON });
      },
    );
  };

  updateHandler = (venue) => {
    update(
      { venue },
      `/settings/venues/${venue.id}`,
      (responce) => location.replace('/settings/venues'),
      (errors) => {
        this.setState({ errors: errors.responseJSON });
      },
    );
  };

  onFormSubmit = () => {
    const {
      id,
      title,
      facility,
      address,
      deleted_at,
      spaces,
      archived_spaces,
    } = this.state;
    let spaces_attributes = spaces.map((space, index) => spaceDTO(space, index));

    spaces_attributes = spaces_attributes.concat(
      archived_spaces.map((space, index) => spaceDTO(space, index)),
    );

    const venue = venueDTO(
      id,
      title,
      facility,
      address,
      deleted_at,
      spaces_attributes,
    );
    if (id) {
      this.updateHandler(venue);
    } else {
      this.creationHandler(venue);
    }
  };

  sortByPosition(array) {
    return array.sort((a, b) => {
      const x = a.position;
      const y = b.position;
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  toggleDisplayingArchiveSpaces = () => {
    const { showArchivedSpaces } = this.state;

    this.setState({ showArchivedSpaces: !showArchivedSpaces });
  };

  render() {
    const {
      id,
      title,
      facility,
      address,
      errors,
      allSports,
      editMod,
      showForm,
      editableSpace,
      spaces,
      archived_spaces,
      showArchivedSpaces,
    } = this.state;

    return  <div className="Card">
      <div className="Card-content u-pt-4 u-pb-5">
        <Row classMod="u-mb-2">
          <Col l="12">
            <div className="d-flex flex-row justify-content-between align-items-baseline">
              <div className="form-title">
                {id ? 'Edit' : 'Create'} Venue
              </div>
              <Button mod={classNames(
                        'Button Button--small u-font-size-13',
                        { 'Button--red': !this.isArchived() },
                      )}
                      title={this.isArchived() ? 'Restore' : 'Archive'}
                      id="archived-status"
                      onClick={this.toggleArchivedStatus}/>
            </div>

            <TextInput validate={['required']}
                        type="text"
                        title="Title"
                        id="venue_title"
                        value={title}
                        error={errors && errors.title}
                        onChange={(value) => this.onFormChange('title', value)}/>
            <TextInput validate={['required']}
                        type="text"
                        title="Facility"
                        id="venue_facility"
                        value={facility}
                        error={errors && errors.facility}
                        onChange={(value) => this.onFormChange('facility', value)}/>
            <div className="input-field input-field_high">
              <TextInput validate={['required']}
                          type="text"
                          title="Address"
                          id="venue_address"
                          value={address}
                          error={errors && errors.address}
                          onChange={(value) => this.onFormChange('address', value)}/>
            </div>
          </Col>
        </Row>

        <Row classMod="u-mb-2">
          <Col l="12">
            <div className="d-flex flex-row justify-content-between align-items-baseline u-mt-3">
              <span className="form-title">Spaces</span>
              <Button mod="Button--small u-font-size-13"
                      title="Add space"
                      id="add-space"
                      disabled={showForm}
                      onClick={this.enableSpaceEditMod}/>
            </div>
            <div>
              {spaces &&
                <ul>
                  {this.sortByPosition(spaces).map((space, index) => 
                    <li key={space.id}
                        onDragOver={() => this.onDragOver(index)}>
                      <div className="drag d-flex flex-row justify-content-between align-items-baseline u-mt-1"
                            draggable
                            onDragStart={(e) => this.onDragStart(e, index)}
                            onDragEnd={this.onDragEnd}>
                        <div className="u-w-30">
                          {space.status ?  <s>{space.title}</s> : space.title}
                        </div>
                        <div className="u-w-60">
                          {allSports
                            .filter((s) => space.sport_ids.includes(s.id))
                            .map((i) => i.title)
                            .join(', ')}
                        </div>
                        <div className="u-w-10 d-flex">
                          {!space.status &&
                            <a className="table__action"
                                onClick={() => this.onSpaceEdit(space)}>
                              <i className="far fa-pen" />
                            </a>}

                          {space.status &&
                            <a className="table__action u-ml-1"
                                onClick={() => this.onHandleRestore(space, index)}>
                              activate
                            </a>}

                          {!space.status &&
                            <a className="table__action"
                                onClick={() => this.onHandleRemove(space, index)} >
                              <i className="far fa-times u-font-size-17 u-ml-1" />
                            </a>}
                        </div>
                      </div>
                    </li>)}
                </ul>}
            </div>

            {archived_spaces.length > 0 &&
              <div>
                <div className="d-flex flex-row justify-content-between align-items-baseline u-mt-5">
                  <span className="form-title">
                    {archived_spaces.length} Spaces in Archive
                  </span>
                  <button className="Button Button--small u-font-size-13"
                          onClick={this.toggleDisplayingArchiveSpaces}>
                    {showArchivedSpaces ? 'Hide archive' : 'Show archive'}
                  </button>
                </div>

                {showArchivedSpaces &&
                  <ul>
                    {this.sortByPosition(archived_spaces).map((space, index) =>
                      <li key={index}>
                        <div className="drag d-flex flex-row justify-content-between align-items-baseline u-mt-1">
                          <div className="u-w-30">{space.title}</div>
                          <div className="u-w-60">
                            {allSports
                              .filter((s) => space.sport_ids.includes(s.id))
                              .map((i) => i.title)
                              .join(', ')}
                          </div>
                          <div className="u-w-10 u-text-right">
                            <a className="table__action"
                                onClick={() => this.onHandleArchiveSpace(space, index)}>
                              <i className="far fa-trash-restore" />
                            </a>
                          </div>
                        </div>
                      </li>)}
                  </ul>}
              </div>}

            {showForm &&
              <SpaceForm allSports={allSports}
                          space={editableSpace}
                          editMod={editMod}
                          onSubmit={(id, position, title, sport_ids) => this.onSpaceFormSubmit(id, position, title, sport_ids)}
                          onCancel={this.disableSpaceEditMod}/>}
          </Col>
        </Row>

        <div className="d-flex">
          <Button mod="Button--primary u-mr-1"
                  title="Save changes"
                  id="create-venue-button"
                  onClick={this.onFormSubmit} />
          <Button mod="Button--cancel"
                  title="Cancel"
                  id="cancel-venue-form-button"
                  onClick={() => location.replace('/settings/venues')}/>
        </div>
      </div>
    </div>
  }
}
