import PropTypes from 'prop-types';
import React from 'react';
import PhoneInput, {
  isValidPhoneNumber,
} from 'react-phone-number-input';
import {Col, Row} from '../shared/Grid';
import TextInput from '../shared/TextInput';
import Button from '../shared/Button';
import {isValid} from '../../lib/validation';
import {create} from '../../lib/API';
import {playerDTO} from '../../lib/DTO';

export default class PlayerForm extends React.Component {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
  };

  state = {
    phone: '',
    email: '',
    last_name: '',
    first_name: '',
    errors: {},
    isPhoneValid: true,
  };

  updateField = (field, value) => {
    this.setState({ [field]: value });
  };

  updatePhoneField = (value) => {
    this.setState({
      phone: value,
      isPhoneValid: isValidPhoneNumber(value),
    });
  };

  isFormValid = () => {
    const { isPhoneValid, email, last_name, first_name } = this.state;

    return (
      isPhoneValid
      && isValid(email, 'email')
      && !!first_name
      && !!last_name
    );
  };

  submit = () => {
    const data = { player: playerDTO(this.state) };
    create(
      data,
      '/api/players',
      (player) => this.props.onCreate(player),
      (errors) => {
        this.setState({ errors });
      },
    );
  };

  render() {
    const { onCancel } = this.props;
    const {
      first_name,
      last_name,
      phone,
      email,
      errors,
      isPhoneValid,
    } = this.state;

    return <div>
      <Row classMod="u-mb-2">
        <Col l="6">
          <div className="team-create__title">
            Add new player as Captain
          </div>
          <TextInput validate={['required']}
                     title="Captain First Name"
                     id="first_name"
                     value={first_name}
                     onChange={(value) => this.updateField('first_name', value)}
                     tabIndex={1} />
          <div className="default-form__input-group">
            <label htmlFor="mobile" className="default-form__label">
              Mobile
            </label>
            <PhoneInput id="mobile"
                        placeholder="Insert phone number here"
                        value={phone}
                        onChange={(value) => this.updatePhoneField(value)}
                        className={isPhoneValid ? '' : 'invalid-number'}
                        defaultCountry="AU"
                        displayInitialValueAsLocalNumber
                        countrySelectProps={{ tabIndex: '3' }}
                        numberInputProps={{ tabIndex: '4' }}
                        required />
          </div>
        </Col>
        <Col l="6">
          <div className="team-create__link r-team-create__link"
               onClick={() => onCancel()} >
            Select existing player
          </div>
          <TextInput validate={['required']}
                     title="Captain Last Name"
                     id="last_name"
                     value={last_name}
                     onChange={(value) => this.updateField('last_name', value)}
                     tabIndex={2} />
        </Col>
      </Row>
      <Row>
        <Col l="12">
          <TextInput validate={['required', 'email']}
                     title="Email"
                     id="email"
                     type="email"
                     value={email}
                     error={errors.email}
                     onChange={(value) => this.updateField('email', value)}
                     tabIndex={5} />
        </Col>
      </Row>
      <Row classMod="u-mb-2">
        <Col l="12">
          <div className="d-flex justify-content-between">
            <Button mod="Button--small u-font-size-13"
                    disabled={!this.isFormValid()}
                    title="Create"
                    id="add_team_create_button"
                    onClick={this.submit} />
            <Button mod="Button--cancel Button--small u-font-size-13"
                    title="Cancel"
                    id="add_team_cancel_button"
                    onClick={onCancel} />
          </div>
        </Col>
      </Row>
    </div>
  }
}
