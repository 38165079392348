import React, {useEffect, useState} from "react";
import axios from "axios";
import MatchPaymentTabItem from "./MatchPaymentTabItem";
import {markAsPaid, refund} from "../../Payments/util/_options";
import {statuses} from "../../Payments/util/_statuses";
import {markAsPaidCallback, refundCallback} from "../../Payments/util/_option_window_callbacks";
import Background from "../../shared/Background";
import {parseCompetitionFromDescription, parseMatchFeeFromDescription} from "../../Payments/util/_payment_fields_operations";
import EditPaymentFee from "../../Payments/option_windows/EditPaymentFee";
import {adjustFeeAmount, adjustPaymentAmount, adjustRefundAmount, allActions, issueRefund, markFeeAsPaid}
    from "../../Payments/util/_manual_adjust_invoice_constants";
import AdjustFeeRefund from "../../Payments/option_windows/AdjustFeeRefund";
import {moneyFormatterZeros} from '../../../lib/utils';

export default function MatchPaymentTab({teamId, matchId, team, cancelCallback, updateMatchParams, isAdmin,}) {

    const [invoices, setInvoices] = useState(null);
    const [option, setOption] = useState(null);
    const [payment, setPayment] = useState(null);

    useEffect(() => {
        axios.get(`/api/teams/${teamId}/fin_infos/${matchId}`)
            .then(response => setInvoices([response.data.data.invoice, ...response.data.data.invoices]))
    }, [])

    const onClosePaymentTab = () => {
        const invoice = invoices[0];

        const fee = parseFloat(invoice.amount);
        const isPaid = statuses[invoice.status] !== 'Active'
        let balance;
        let overdues;

        if (invoices.length === 1) {
            balance = 0;
            overdues = false;
        } else {
            const otherInvoices = invoices.slice(1);

            overdues = otherInvoices.filter(({overdue, status}) => overdue && statuses[status] === 'Active').length > 0;
            balance = otherInvoices.filter(({status}) => statuses[status] === 'Active')
                .map(({amount, paid}) => parseFloat(amount) - paid)
                .reduce((a, b) => a + b, 0)
        }

        updateMatchParams(fee, overdues, balance, isPaid);
        cancelCallback();
    }

    const payFeeCallback = (payment) => {
        const payedInvoiceIndex = invoices.findIndex(item => item.id === payment.id);
        const newInvoice = {...invoices[payedInvoiceIndex], status: 'paid'};

        setInvoices(prevInvoices => [
            ...prevInvoices.slice(0, payedInvoiceIndex),
            newInvoice,
            ...prevInvoices.slice(payedInvoiceIndex + 1)
        ])
    }

    const actionClick = (payment, option) => {
        if (option === markAsPaid) {
            markAsPaidCallback(payFeeCallback, () => {
            })(payment)
        } else {
            setPayment(payment);
            setOption(option);
        }
    }

    const closePopup = () => {
        setPayment(null);
        setOption(null);
    }

    const getAvailableActions = (isAdmin) => {
        if (isAdmin) {
            return allActions.filter(item => item !== markFeeAsPaid);
        }

        return [adjustFeeAmount, adjustPaymentAmount, issueRefund, adjustRefundAmount];
    }

    const getPaymentTab = (option, payment) => {
        const {status} = payment;
        let preDefinedAction;

        if (option === refund)
            return <AdjustFeeRefund payment={payment}
                                    option={option}
                                    cancelCallback={closePopup}
                                    confirmCallback={refundCallback(setInvoices, closePopup)}
                                    tabClasses={'u-text-left'}/>
        else if (statuses[status] === 'Active')
            preDefinedAction = adjustFeeAmount;
        else if (statuses[status] === 'Paid')
            preDefinedAction = adjustPaymentAmount;
        else if (['Partial refund', 'Fully refund'].includes(statuses[status]))
            preDefinedAction = adjustRefundAmount;

        return <div className="u-text-left">
                    <EditPaymentFee payment={payment}
                                    option={option}
                                    cancelCallback={closePopup}
                                    currentTab={0}
                                    setPaymentList={setInvoices}
                                    setPayment={setPayment}
                                    preDefinedAction={preDefinedAction}
                                    availableActions={getAvailableActions(isAdmin)}
                                    tabClass={'u-w-60'}/>
               </div>
    }

    if (option && payment) {
        return <React.Fragment>
                    {getPaymentTab(option, payment)}
                    <Background isShow={true}
                                setDisable={() => closePopup()}
                                backgroundStyle={'Underlayer-dark'}/>
                </React.Fragment>
    }

    if (!invoices)
        return null;

    const feesOwing = invoices
        .filter(item => statuses[item.status] === 'Active')
        .map(item => parseFloat(item.amount) - parseFloat(item.paid))
        .reduce((a, b) => a + b, 0)

    const matchInvoice = invoices[0];
    const otherInvoices = invoices.length > 1 ? invoices.slice(1) : [];

    return (
        <React.Fragment>
            <Background isShow={true}
                        setDisable={onClosePaymentTab}
                        backgroundStyle={'Underlayer-dark'}/>

            <div className="SchedulePaymentsTab PaymentModal PaymentModal-width--45 PaymentModal-width--tabletDown-80 u-element-center u-fixed u-text-left PaymentModal-schedule-payments ModalWindow-longModalScroll-modificator">

                <div className="d-flex justify-content-between align-items-center">
                    <div className="SchedulePaymentsTab-invoice-info flex-grow-1">
                        <h1 className="u-font-size-17 u-weight-400 u-mb-1">{team}</h1>
                        <div className="d-flex align-items-center">
                            <div className="fee-icon u-mr-1"/>   
                            <p className="PaymentTab-description u-weight-400 u-font-size-13">
                                {parseMatchFeeFromDescription(matchInvoice.description)}
                            </p>   

                            {matchInvoice.sport && 
                                <div className="d-flex align-items-center">
                                    <p className="PaymentTab-description u-weight-400 separator">|</p>
                                    <div className="sport-icon u-mr-1"/>   
                                    <p className="PaymentTab-description u-weight-400 u-font-size-13">{matchInvoice.sport}</p>
                                </div>}

                            {matchInvoice.venue && 
                                <div className="d-flex align-items-center">
                                    <p className="PaymentTab-description u-weight-400 separator">|</p>
                                    <div className="venue-icon u-mr-1"/>
                                    <p className="PaymentTab-description u-weight-400 u-font-size-13">{matchInvoice.venue}</p>
                                </div>}
                        </div>

                        {parseCompetitionFromDescription(matchInvoice.description) && 
                            <div className="d-flex u-mt-1">
                                <div className="desc-icon u-mr-1"></div>  
                                <p className="PaymentTab-description u-weight-400 u-font-size-13">
                                    {parseCompetitionFromDescription(matchInvoice.description)}
                                </p>
                            </div>}
                    </div>
                    <div className="u-pr-2 u-pl-4">
                        <p className="text-center wrap-none">Fees owing:</p>
                        <h1 className="u-font-size-30 u-weight-400 text-center">${moneyFormatterZeros(feesOwing)}</h1>
                    </div>
                </div>

                <p className="u-font-size-17 u-weight-400 u-mt-3 u-mb-1">Match fee</p>
                <MatchPaymentTabItem invoice={matchInvoice}
                                     actionClick={actionClick}
                                     totalPaidRefund={true}/>

                {otherInvoices.length > 0 &&
                    <React.Fragment>
                        <p className="u-font-size-17 u-weight-400 u-mt-3 u-mb-1">Other fees</p>
                    </React.Fragment>}
                
                {otherInvoices.map(item =>
                    <MatchPaymentTabItem key={item.id}
                                         invoice={item}
                                         actionClick={actionClick}
                                         totalPaidRefund={false}/>)}
                
                <div className="d-flex justify-content-flex-start u-mt-2">
                    <button className="Button Button-static-width--100 Button--primary-empty"
                            onClick={() => onClosePaymentTab()}>
                        Cancel
                    </button>
                </div>
            </div>
        </React.Fragment>
    )
}
