import React, {useState, useEffect, useRef} from "react";
import {noop} from "lodash";
import Button from "../../shared/Button";
import Background from "../Background";
import TextInput from "../../shared/TextInput";
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input';
import classNames from 'classnames';
import {
  returningTeamOptions,
  returningTeamRadioButtons,
  registrationFormStatus
} from "../util/_options";

const TEAM_KEYS = {
  team_title: 'title',
  captain_email: 'email',
  captain_first_name: 'first_name',
  captain_last_name: 'last_name',
  captain_phone: 'phone'
};

const requestHeaders = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export default function TeamFormRegistration({team, title, cancelCallback, confirmCallback}) {
  const [teamCurr, setTeamCurr] = useState({id: team.team_id, title: team.title})
  const [captain, setCaptain] = useState({
    id: team.user_id,
    email: team.email,
    first_name: team.first_name,
    last_name: team.last_name,
    phone: team.phone
  });
  const [returningID, setReturningID] = useState(team.returning_team ? returningTeamOptions.yes : returningTeamOptions.no);
  const [information, setInformation] = useState(team.description);
  const [searchDropdown, setSearchDropdown] = useState(null);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [currentTeamsPage, setCurrentTeamsPage] = useState(1);
  const [currentEmailsPage, setCurrentEmailsPage] = useState(1);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isExistingTeam, setExistingTeam] = useState(false);

  const nextPage = useRef(null);

  const handleScroll = setPage => {
    const element = document.querySelector('.TeamRegistration-dropdown');
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      setPage(nextPage.current)
    }
  };

  useEffect(() => {
    if (currentTeamsPage !== 1) {
      loadOptionsTeam(true, currentTeamsPage);
    } 
  }, [currentTeamsPage]);

  useEffect(() => {
    if (currentEmailsPage !== 1) {
      loadOptionsCaptain(true, currentEmailsPage);
    } 
  }, [currentEmailsPage]);

  const isTeamValid = () => {
    if (isExistingTeam){
      return true;
    }
    const validPhone = isValidPhoneNumber(captain.phone) || isValidPhoneNumber(`+${captain.phone}`);
    const valid = teamCurr.title && captain.email && captain.first_name && !!captain.last_name;
    return valid && validPhone;
  };

  const buildParams = () => {
    const phone = captain.phone[0] === '+' ? captain.phone.slice(1) : captain.phone
    const params = {
      team_registration: {
        first_name: captain.first_name,
        last_name: captain.last_name,
        email: captain.email,
        phone: phone,
        description: information,
        returning_team: returningID === returningTeamOptions.yes,
        title: teamCurr.title,
        team_id: teamCurr.id,
        user_id: captain.id,
      }
    }
    return params;
  };

  const loadOptionsTeam = async(pageChanged, page, term) => {
    const captain_id = captain.id || '';
    const response = await fetch(
      `/api/search_teams?term=${teamCurr.title || term}&captain_id=${captain_id}&page=${page}`,
      requestHeaders,
    );
    const responseJSON = await response.json();

    setDropdownOptions(oldList => pageChanged ? [...oldList, ...responseJSON.options] : responseJSON.options);
    nextPage.current = responseJSON.page + 1;
  };

  const loadOptionsCaptain = async(pageChanged, page) => {
      const response = await fetch(
          `/api/search_players?term=${captain.email}&page=${page}`,
          requestHeaders,
      );
      const responseJSON = await response.json();

      setDropdownOptions(oldList => pageChanged ? [...oldList, ...responseJSON.options] : responseJSON.options);
      nextPage.current = responseJSON.page + 1
  };

  const onTeamChange = async(e, key) => {
    setTeamCurr({title: e});
    setCurrentTeamsPage(1);
    await loadOptionsTeam(false, 1, e);
    const dropdown = e ? key : null
    setSearchDropdown(dropdown);
  };

  const onCaptainChange = async(e, key) => {
    if (!captain.id) {
      setCaptain(prev => ({...prev, [key]: e }));
    } else {
      setCaptain({ [key]: e })
    };
    
    if (key === TEAM_KEYS.captain_phone) {
      setIsPhoneValid(isValidPhoneNumber(e))
    }

    if (key !== TEAM_KEYS.captain_email) { return }

    await loadOptionsCaptain(false, 1);
    const dropdown = e ? key : null
    setSearchDropdown(dropdown);
  };

  const onInformationChange = e => {
    const value = e.target.value;
    setInformation(value);
  };

  const updateFormData = user => {
    setSearchDropdown(null);
    setCaptain({
      id: user.id, 
      email: user.email, 
      first_name: user.first_name, 
      last_name: user.last_name,
      phone: `+${user.phone}`
    })
    setExistingTeam(true);
  };

  const onChooseTeamOption = option => {
    const user = option.user || option;

    if (option.user) {
        setTeamCurr({id: option.id, title: option.title})
    }

    updateFormData(user);
  };

  const onChooseEmailOption = option => {
    const user = option.user || option;

    if (option.user && !teamCurr.title) {
        setTeamCurr({id: option.id, title: option.title})
    }

    updateFormData(user);
  };

  return <div className="PaymentModal u-element-center u-fixed u-p-3 u-pl-5 u-pr-5">
    <h1 className="u-font-size-30 u-weight-400 u-mb-2">{title}</h1>

    <div className="TeamRegistration-row">

      <div className="search-wrapper TeamRegistration-search-wrapper u-mb-1">
        <p className="TeamRegistration-title">Captain</p>

        <div className="u-relative TeamRegistration-search-input u-pt-2">
          <TextInput value={captain.first_name || ''}
                     onChange={e => onCaptainChange(e, TEAM_KEYS.captain_first_name)}
                     title="First name"
                     isDisabled={!!captain.id}
                     validate={['required']}
                     id="firstName"/>
        </div>

        <div className="u-relative TeamRegistration-search-input u-pt-2">
          <TextInput value={captain.last_name || ''}
                     onChange={e => onCaptainChange(e, TEAM_KEYS.captain_last_name)}
                     title="Last name"
                     isDisabled={!!captain.id}
                     validate={['required']}
                     id="lastName"/>
        </div>

        <div className="u-relative TeamRegistration-search-input u-pt-2">
          <Background setDisable={() => setSearchDropdown(null)}
                      isShow={searchDropdown === TEAM_KEYS.captain_email}/>

          <TextInput title="Email"
                     value={captain.email || ''}
                     onChange={(e) => onCaptainChange(e, TEAM_KEYS.captain_email)}
                     isDisabled={!!teamCurr.id}
                     validate={['required']}
                     id="email"/>

          {searchDropdown === TEAM_KEYS.captain_email && 
            <div className="TeamRegistration-dropdown"
                 onScroll={() => handleScroll(setCurrentEmailsPage)}>
              <ul>
                {dropdownOptions.length && dropdownOptions.map((option) => {
                  const {id, email} = option
                  return <li key={id}
                             className="TeamRegistration-dropdown-option"
                             onClick={() => onChooseEmailOption(option)}>
                          {email}
                        </li>})}

                {!dropdownOptions.length &&
                    setSearchDropdown(null)}
              </ul>
            </div>}
        </div>

        <div className="TeamRegistration-search-input u-relative u-mt-2">
          <p className="TeamRegistration-label">Phone</p>
          <PhoneInput id="phone"
                      defaultCountry="AU"
                      value={captain.phone || ''}
                      onChange={e => onCaptainChange(e, TEAM_KEYS.captain_phone)}
                      displayInitialValueAsLocalNumber
                      required
                      disabled={!!captain.id}
                      className={classNames({'invalid-number': !isPhoneValid})}/>
        </div>
      </div>

      <div className="search-wrapper TeamRegistration-search-wrapper u-mb-1">
        <p className="TeamRegistration-title">Team information</p>

        <div className="u-relative TeamRegistration-search-input u-pt-2">
          <Background setDisable={() => setSearchDropdown(null)}
                      isShow={searchDropdown === TEAM_KEYS.team_title}/>

          <TextInput value={teamCurr.title || ''}
                     onChange={e => onTeamChange(e, TEAM_KEYS.team_title)}
                     title="Team name"
                     validate={['required']}
                     id="teamTitle"/>

          {searchDropdown === TEAM_KEYS.team_title && 
            <div className="TeamRegistration-dropdown"
                onScroll={() => handleScroll(setCurrentTeamsPage)}>
              <ul>
                {dropdownOptions.length && dropdownOptions.map((option) => {
                  const {id, title, captain} = option
                  return <li key={id}
                             className="TeamRegistration-dropdown-option"
                             onClick={() => onChooseTeamOption(option)}>
                {`${title} (${captain})`}
              </li>})}

                {!dropdownOptions.length &&
                    setSearchDropdown(null)}
              </ul>
            </div>}
        </div>

        <p className="TeamRegistration-label u-mt-2">Returning team</p>
        <div className="d-flex align-items-center u-pt-1">
          {returningTeamRadioButtons.map((rb, i) => 
            <label className="d-flex align-items-center u-font-size-14 u-color-mine-shaft u-mr-2" key={i}>
              <input id={rb.id}
                     className="PaymentTab-radio-button browser-default"
                     type="radio"
                     value={rb.id}
                     checked={rb.id === returningID}
                     onClick={() => setReturningID(rb.id)}
                     onChange={noop}/>
                {rb.title}
              </label>)}
        </div>
      </div>
    </div>

    <div className="u-mt-2">
      <p className="TeamRegistration-label">Additional information</p>
      <textarea className='TeamRegistration-textarea'
                value={information || ''}
                onChange={(e) => onInformationChange(e)}/>
    </div>

    <div className="d-flex u-mt-3">
      {team === registrationFormStatus.new && 
        <Button mod="Button--primary"
                title="Add team to listing"
                id="add-team-to-listing-btn"
                disabled={!isTeamValid()}
                onClick={() => confirmCallback(buildParams())}/>}

      {team !== registrationFormStatus.new &&
        <Button mod="Button--primary"
                title="Update team"
                id="confirm-btn"
                disabled={!isTeamValid()}
                onClick={() => confirmCallback(buildParams(), team.id)}/>}

      <Button mod="Button Button--primary-empty u-ml-2"
              title="Cancel"
              id="cancel-btn"
              onClick={() => cancelCallback()}/>
    </div>
  </div>
}

