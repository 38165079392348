import React, {useEffect, useState} from 'react';
import {editTeamRegistration, teamTableStatus} from '../util/_options';

export default function TeamsTable({
  registrationsList,
  title,
  status,
  setCheckedRegistrations,
  onOptionClick,
  onDeleteTeam,
  onArrowClick
}) {
  const [checkboxList, setCheckboxList] = useState([]);

  useEffect(() => {
    setCheckedRegistrations(checkboxList)
  }, [checkboxList])

  const onCheck = (e, id, team_id) => {
    const input = e.target;
    if (input.checked) {
      setCheckboxList(prev => [...prev, {team_registration_id: id, extra_team_id: team_id}]);
    } else {
      setCheckboxList(prev => prev.filter(item => item.team_registration_id !== id));
    }
  };

  return <React.Fragment>
      {title &&
        <div className="text-bold u-pb-2 u-weight-500">{title}</div>}

      <table className={`ManageListing-table--${title} highlight u-w-100`}>
        <thead>
          <tr className="u-text-left u-noBorder u-noPadding">
            {status === teamTableStatus.all &&
              <th></th>}
            <th></th>
            <th className="u-color-dodger-blue">Team</th>
            <th className="u-color-dodger-blue">Captain</th>
            <th className="u-color-dodger-blue">Additional information</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!!registrationsList.length &&
            registrationsList.map(team => {
              const {
                description,
                email,
                first_name,
                id,
                last_name,
                phone,
                returning_team,
                title,
                team_id,
                team_intersection_exists,
                team_registration_url,
                waiting
              } = team
              return <tr key={id} className="collection-item bordered--2 ManageListing-table-row">
                {status === teamTableStatus.all && 
                  <td className="u-pt-1 u-pb-1">
                    <input className='ManageListing-input u-pointer' 
                          type="checkbox" 
                          id="ManageListing-checkbox"
                          value={id} 
                          onChange={e => onCheck(e, id, team_id)}/>
                  </td>}
                <td className="ManageListing-icon--column u-mr-1 u-pt-1 u-pb-1 u-pr-0">
                  {waiting && 
                    <i className="far fa-hourglass Icon Icon--noBorder ManageListing-icon--hourglass u-mr-1"/>}
                </td>
                <td className="text-left u-pt-1 u-pb-1 ManageListing-teamtitle-column">
                  {title}
                </td>
                <td className="text-left u-pt-1 u-pb-1">
                  {`${first_name} ${last_name}`} <br/>
                  {`+${phone}`} <br />
                  {email}
                </td>
                <td className="text-left u-pt-1 u-pb-1 ManageListing-big-column">
                  {description}
                </td>
                <td className="u-pt-1 u-pb-1">
                  {((returning_team || team_intersection_exists) && team_registration_url) && 
                    <a href={team_registration_url} className="d-flex justify-content-flex-end">
                      <i className="fal fa-code-merge Icon Icon--noBorder ManageListing-icon--merge-red"/>
                    </a>}
                  {(!returning_team && !team_intersection_exists && status === teamTableStatus.new) &&
                    <i className="fal fa-arrow-alt-down ManageListing-icon ManageListing-icon--actions"
                       onClick={() => onArrowClick(id)}/>}
                </td>
                <td className="u-pt-1 u-pb-1">
                  <i onClick={() => onOptionClick(editTeamRegistration, team)}
                     className="far fa-pen ManageListing-icon ManageListing-icon--pen"/>
                </td>
                <td className="u-pt-1 u-pb-1">
                  <i onClick={() => onDeleteTeam(id)}
                     className="fal fa-trash ManageListing-icon ManageListing-icon--trash"/>
                </td>
              </tr>})} 
        </tbody>
      </table>
    </React.Fragment>
}
