import React from "react";
import moment from "moment";
import {moneyFormatterZeros} from "../../lib/utils";

export default function FinancialTable({payoutsList, token}) {

    return(
        <table className="bordered u-w-100">
            <thead>
                <tr>
                    <th><span className="table__head_wrapper">Date of payout</span></th>
                    <th><span className="table__head_wrapper FinancialReports-center FinancialReports-shorter-cell">Payout amount</span></th>
                    <th><span className="table__head_wrapper FinancialReports-center">Download csv</span></th>
                </tr>
            </thead>
            <tbody>
                {payoutsList ? payoutsList.map(payout => { 
                    const {
                        amount,
                        arrival_date,
                        id
                    } = payout;
                    const convertedDate = new Date(arrival_date * 1000).toISOString();
                    return (
                        <tr key={convertedDate}>
                            <td>{moment(convertedDate).format('LL')}</td>
                            <td className="FinancialReports-center"><span className="FinancialReports-shorter-cell">${moneyFormatterZeros(amount/100)}</span></td>
                            <td className="FinancialReports-icon FinancialReports-center">
                                <form id="financial-export" action="/settings/online_payments_export" acceptCharset="UTF-8" method="post">
                                    <input type="hidden" name="date" value={convertedDate}/>
                                    <input type="hidden" name="payout_id" value={id}/>
                                    <input type="hidden" name="authenticity_token" value={token}/>
                                    <div className="FinancialReports-download u-relative">
                                        <i className="far fa-arrow-alt-circle-down u-pointer u-absolute"></i>
                                        <input
                                            className="FinancialReports-input u-pointer u-absolute"
                                            type="submit" 
                                            name="commit"
                                            value="s"/> 
                                    </div>
                                </form>
                            </td>
                        </tr>
                    )}
                ): null}
            </tbody>
        </table>
    )
}
