import React from 'react'
import Button from "../../shared/Button";
import {editFee, editPayment, markAsPaid, refund} from "../../Payments/util/_options";
import {statuses} from "../../Payments/util/_statuses";
import moment from "moment";
import {moneyFormatterZeros} from '../../../lib/utils';
import {
    parseCompetitionFromDescription,
    parseOnceOfFeeDescription
} from "../../Payments/util/_payment_fields_operations";

export default function MatchPaymentTabItem({invoice, actionClick, totalPaidRefund}) {

    const {amount, description, due_date, status, overdue, manual, payment_details, paid, before_split, partially_paid_fee} = invoice;

    const isActive = statuses[status] === 'Active';
    const isActiveOverdue = isActive && overdue;
    const isPartiallyPaid = paid != 0 && paid < amount;
    const isRefund = status.includes("refund")
    const refundFull = totalPaidRefund ? (paid >= amount) : (paid > 0)
    const canRefund = !manual && (status === 'paid' || status === 'open') && refundFull
    const zeroFee = amount == 0

    let buttonTitle;
    let option;

    if (isActive) {
        buttonTitle = 'Pay Fee';
        option = markAsPaid;
    } else {
        buttonTitle = 'Refund';
        option = refund
    }

    const itemAmount = (amount, conditionStatus) => {
        if (!isActiveOverdue || conditionStatus) {
            return amount
        }
        return "0.00"
    }

    const StatusIcon = ({item}) => {
        const {paid, condition_status, covered_by, with_payment_method} = item;
        const amount = itemAmount(paid, condition_status);
        const className = (covered_by || condition_status ) ? 'fa fa-solid fa-check-circle u-color-emerald ' :
                          with_payment_method ? 'fa fa-credit-card-front u-color-pumpkin ' :
                          'fa fa-credit-card-front u-color-payment-grey ';

        return covered_by ? (<span><i className={className + 'u-ml-1'}></i> Covered by {covered_by}</span>) :
                            (<span><i className={className + 'u-ml-1'}></i> ${amount}</span>);
    }

    return (
        <div className="schedule-page-payment-invoice-row u-mb-1">
            <div className="d-flex justify-content-between align-items-center">
                <div className="SchedulePaymentsTab-action u-flex-1 align-items-center">
                    {isActiveOverdue &&
                    <div className="SchedulePaymentsTab-overdue u-mb-1 d-flex">
                        <div className="danger-icon u-mr-1"></div>
                        <p className="u-color-red u-font-size-13">Due date: {moment(due_date).format('LL')}</p>
                    </div>}
                    <div className="d-flex align-items-center u-mb-1">
                        <p className="u-font-size-14 u-weight-400 u-mr-1">Amount:</p>
                        <h1 className={`SchedulePaymentsTab-amount ${(!zeroFee && isActiveOverdue) ? 'SchedulePaymentsTab-amount--overdue' :
                        isPartiallyPaid ? 'SchedulePaymentsTab-amount--partially-paid' :
                        !isActive && !isRefund ? 'SchedulePaymentsTab-amount--paid' : ''} u-font-size-17`}>
                           ${ isRefund ? (moneyFormatterZeros(paid - amount) + " refunded") : (paid >= amount) ? moneyFormatterZeros(amount) : moneyFormatterZeros(amount - paid)}
                        </h1>
                    
                    {(manual || isActiveOverdue || before_split || partially_paid_fee) &&
                        <i className="far fa-pen adjust-fee-schedule-tab-icon u-pointer u-ml-1"
                           onClick={() => actionClick(invoice, statuses[status] === 'Active' ? editFee : editPayment)}/>}
                    </div>
                    
                    {description.includes("|") && !!parseOnceOfFeeDescription(description) &&
                        <div className="u-mt-1 u-font-size-13">
                            {parseOnceOfFeeDescription(description)}
                        </div>}

                    <div className="u-mt-1 u-font-size-13">
                        {description.includes("|")
                        ? parseCompetitionFromDescription(description)
                        : description}
                    </div>
                </div>

                {manual && status === 'open' && !zeroFee &&
                    <Button title="Pay Fee"
                            mod={'Button-static-width--100 Button--primary'}
                            id="payment-action-schedule-page-button"
                            onClick={() => actionClick(invoice, markAsPaid)}/>}

                {canRefund && 
                    <Button title="Refund"
                            mod={'Button-static-width--100 Button--primary'}
                            id="payment-action-schedule-page-button"
                            onClick={() => actionClick(invoice, refund)}/>}
            </div>

            <div className='u-mt-4'>
                {payment_details.map((item, index) =>  <div key={index} className='d-flex u-mb-2'>
                    <div className="u-w-35">{item.name}</div>
                    <div className="u-w-65"><StatusIcon item={item}/></div>
                </div>)
                }
                
            </div>
        </div>

    )
}
