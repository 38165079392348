import React from 'react';
import moment from 'moment-timezone';
import { TZ } from '../shared/configs';
import DateInput from '../shared/DateInput';
import SelectDefault from '../shared/SelectDefault';
import TimePicker from '../shared/TimePicker';
import { SVG } from '../shared/SVG';
import {
  isSwapable,
  isMatchBYE,
  isMatchScore,
} from '../../lib/validation';

const DATE_FORMAT = 'DD/MM/YYYY';

const normalizeTeam = (v) => (isNaN(v) ? null : v);

export const MatchRow = ({
  value,
  round,
  spaces,
  errors,
  onChange,
  teams,
  onSwap,
  nextMatch,
  prevMatch,
}) => {
  const { id, space_id, team_a_id, team_b_id } = value;
  const start_date = moment.parseZone(value.start_date);
  const start_time = moment.parseZone(value.start_time);
  const isMeBYE = isMatchBYE(value);
  const isDisabled = isMatchScore(value);

  return (
    <tr
      className={`edit-fixture__match-row${
        isDisabled ? ' edit-fixture__match-row_disabled' : ''
      }`}
      key={id}
    >
      <td className="edit-fixture__cell">
        {errors[round] && errors[round][id] && (
          <span
            className={`edit-fixture__invalid edit-fixture__invalid_${errors[round][id]}`}
            title="Two games can not take place in the same place"
          />
        )}
        <DateInput
          value={start_date}
          mod="text-input__input_smaller"
          id={`date-input-${id}`}
          format={DATE_FORMAT}
          onChange={(v) => onChange(round, id, 'start_date', v.format('YYYY-MM-DD'))}
        />
      </td>
      <td className="edit-fixture__cell">
        <div>
          <div className="edit-fixture__time-picker">
            <TimePicker
              value={start_time}
              id={`match-time-${id}`}
              onChange={(v) => onChange(round, id, 'start_time', v)}
            />
          </div>
          <div className="edit-fixture__time">
            {start_time ? start_time.format('h:mmA') : ''}
          </div>
        </div>
      </td>
      <td className="edit-fixture__cell">
        <SelectDefault
          mod="select-dropdown_small"
          items={spaces}
          value={space_id}
          id={`match-space-${id}`}
          onSelect={(v) => onChange(round, id, 'space_id', +v)}
          nullable
          noInit
        />
      </td>
      <td className="edit-fixture__cell">
        {!isMeBYE && isSwapable(prevMatch) && (
          <SVG.arrowRound
            className="edit-fixture__icon edit-fixture__icon_up"
            onClick={() => onSwap(value, prevMatch, round)}
          />
        )}
        {!isMeBYE && isSwapable(nextMatch) && (
          <SVG.arrowRound
            className="edit-fixture__icon edit-fixture__icon_down"
            onClick={() => onSwap(value, nextMatch, round)}
          />
        )}
      </td>
      <td className="edit-fixture__cell edit-fixture__cell_right-aligned">
        <SelectDefault
          key={team_a_id}
          items={teams}
          value={team_a_id}
          ignore={[team_b_id]}
          id={`match-team-a-${id}`}
          onSelect={(v) => onChange(round, id, 'team_a_id', normalizeTeam(+v))}
          disabled={isMeBYE}
          nullable
          noInit
        />
      </td>
      <td className="edit-fixture__cell">
        <SelectDefault
          key={team_b_id}
          items={teams}
          value={team_b_id}
          ignore={[team_a_id]}
          id={`match-team-b-${id}`}
          onSelect={(v) => onChange(round, id, 'team_b_id', normalizeTeam(+v))}
          disabled={isMeBYE}
          nullable
          noInit
        />
      </td>
    </tr>
  );
};
