import PropTypes from 'prop-types';
import React from 'react';
import Button from '../shared/Button';
import TextInput from '../shared/TextInput';
import Checkbox from '../shared/Checkbox';
import { updateInputs } from '../../lib/materialHelper';

export default class SpaceForm extends React.Component {
  static propTypes = {
    space: PropTypes.object.isRequired,
    editMod: PropTypes.string.isRequired,
    allSports: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        deleted_at: PropTypes.instanceOf(Date),
      }),
    ).isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    ...this.props.space,
  };

  componentDidMount() {
    updateInputs();
  }

  onFormSubmit = () => {
    const { id, position, title, sport_ids } = this.state;
    this.props.onSubmit(
      id,
      position,
      title,
      sport_ids.map((id) => +id),
    );
  };

  onSportChoose = (e) => {
    const { sport_ids } = this.state;

    if (e.target.checked) {
      this.setState({
        sport_ids: [...sport_ids, e.target.value],
      });
    } else {
      this.setState({
        sport_ids: sport_ids.filter((i) => i != e.target.value),
      });
    }
  };

  render() {
    const { editMod, allSports } = this.props;
    const { errors } = this.props.space;
    const { title, sport_ids } = this.state;

    return (
      <div className="u-mt-2 u-p-2 bg-athens-gray">
        <div className="d-flex flex-row justify-content-between align-items-baseline">
          <span className="form-title">{editMod} 'Space'</span>
          <span
            className="table__action"
            onClick={this.props.onCancel}
          >
            Cancel
          </span>
        </div>

        <div className="space-form">
          <TextInput
            validate={['required']}
            type="text"
            title="Title"
            id="space_title"
            value={title}
            onChange={(value) => this.setState({ title: value })}
          />
          {errors && errors.title && (
            <span className="help-block has-error">
              {errors.title}
            </span>
          )}

          <ul className="u-pt-2">
            {allSports.map((sport, index) => (
              <li key={index}>
                <Checkbox
                  key={index}
                  value={sport.id}
                  labelText={sport.title}
                  checked={sport_ids.indexOf(sport.id) > -1}
                  onChange={this.onSportChoose}
                />
              </li>
            ))}
          </ul>
          {errors && errors.sport_ids && (
            <span className="help-block has-error">
              {errors.sport_ids}
            </span>
          )}

          <div className="d-flex justify-content-flex-end">
            <Button
              mod="Button Button--small u-font-size-13"
              title="Save"
              id="add-space-button"
              onClick={() => this.onFormSubmit()}
            />
          </div>
        </div>
      </div>
    );
  }
}
