import React, { useState } from "react";
import Button from "../shared/Button";


export default function ConfirmOnceOffFees({
  teams,
  onceOffFromApi,
  once_off_fees,
  confirmCallback,
  cancelCallback
}) {
  const [onceoffFees, setOnceoffFees] = useState(once_off_fees);

  const isChecked = (team, fee) => {
    const index = onceoffFees?.findIndex(item => item.id === fee);
    let checked = false;
    if (onceoffFees[index].oldteam_ids && onceoffFees[index].team_ids) {
      checked = onceoffFees[index].oldteam_ids.find(item => item === team) || onceoffFees[index].team_ids.find(item => item === team) ? true : false;
    } 
    else if (!onceoffFees[index].team_ids) {
      checked = onceoffFees[index].oldteam_ids.find(item => item === team) ? true : false;
    } else {
      checked = onceoffFees[index].team_ids.find(item => item === team) ? true : false;
    }
    return checked
  };

  const isDisabled = (fee, teamId) => {
    const index = onceOffFromApi?.findIndex(item => item.id === fee.id)
    if (fee.id > 0) {
      return !!onceOffFromApi[index].team_ids.find(item => item.id === teamId && item.paid)
    } else false
  };

  const CheckboxAction = ( onceOffID, teamID ) => {
    let newOnceofffees = [...onceoffFees]
    const index = newOnceofffees?.findIndex(item => item.id === onceOffID)

    if (isChecked(teamID, onceOffID)) {
      if (newOnceofffees[index].team_ids) {
        newOnceofffees[index].team_ids = newOnceofffees[index].team_ids.filter(item => item !== teamID)
      } 
      if (newOnceofffees[index].oldteam_ids) {
        newOnceofffees[index].oldteam_ids = newOnceofffees[index].oldteam_ids.filter(item => item !== teamID)
      }
    } else {
      if (newOnceofffees[index].team_ids) {
        newOnceofffees[index].team_ids = [...newOnceofffees[index].team_ids, teamID]
      } 
      if (newOnceofffees[index].oldteam_ids) {
        newOnceofffees[index].oldteam_ids = [...newOnceofffees[index].oldteam_ids, teamID]
      }
      if (!newOnceofffees[index].team_ids) {
        newOnceofffees[index].team_ids = [teamID]
      }
    }
    setOnceoffFees(newOnceofffees)
  };

  return <div className={`PaymentModal u-element-center u-fixed u-p-3 ConfirmOnceOffFees-modal`}>
    <h1 className="center-align u-font-size-20 u-weight-700 u-mb-1">Confirming</h1>

    <div className="ConfirmOnceOffFees-scroll-block">
      {!!teams.length &&
        teams.map(team =>
          <div key={team.id} className="d-flex u-pb-1 ConfirmOnceOffFees-team-row">
            <div className="u-mr-1 col-3">{team.title}</div>
            <div className="d-flex ConfirmOnceOffFees-fee-row">
              {!!onceoffFees.length &&
                onceoffFees.map(item => {
                  const { id, description, amount } = item;
                  return <div key={id}
                              className={ 
                                isDisabled(item, team.id) 
                                ? 'd-flex u-pl-1 u-ml-2 u-mt-1 ConfirmOnceOffFees-fee-disabled'
                                : 'd-flex u-pl-1 u-ml-2 u-mt-1 ConfirmOnceOffFees-fee'
                              }>
                    <input className={
                              isDisabled(item, team.id)
                              ? 'ConfirmOnceOffFees-input-disabled OnceOffFeesModal-input'
                              : 'OnceOffFeesModal-input u-pointer'
                            } 
                           type="checkbox" 
                           id="OnceOffFeesModal-checkbox"
                           value={id}
                           disabled={isDisabled(item, team.id)}
                           checked={isChecked(team.id, id)}
                           onChange={() => CheckboxAction(id, team.id)}/>

                    <div className={
                            isDisabled(item, team.id)
                            ? 'u-pl-1 ConfirmOnceOffFees-text-disabled'
                            : 'u-pl-1'
                          }>
                      {`${description} ($${amount})`}
                    </div>
                  </div> })}
            </div>
          </div>)}
    </div>

    <div className="d-flex u-mt-3 justify-content-center">
        <Button mod="Button--primary Button--medium ManageListing-button--large"
                title="Confirm"
                id="confirm-transfer"
                onClick={() => {confirmCallback(onceoffFees)}}/>
        <Button mod="Button Button--medium ManageListing-button--large u-ml-2"
                title="Cancel"
                id="cancel-transfer"
                onClick={() => {cancelCallback()}}/>
    </div>

  </div>
}
