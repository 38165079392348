import React from "react";
import Select from '../shared/Select';
import Button from "../shared/Button";
import {TEAM_KEYS} from "./utils/constants";

export default function FindLeague({
  details,
  preferredLeague,
  leagueOptions,
  onChange,
  onNext,
  sports,
  venues
}) {
  const {sport_id, venue_id, registration_listing_id} = details;

  return <form className="RegisterTeams-form">
    <h1 className="u-font-size-30 u-mt-6 u-mb-2">Register a team</h1>
    <div className="u-font-size-17 u-mb-2">Step 1. Find your league</div>

    <div className="RegisterTeams-form--content">
      <div className="RegisterTeams-form--field">
        <label htmlFor="registration_sport_id" className="u-font-size-11">
          Sport
        </label>
        <Select required
                items={sports}
                value={sport_id}
                onSelect={(id) => onChange(id, TEAM_KEYS.sport_id)}
                name="league[sport_id]"
                id="registration_sport_id"/>
      </div>

      <div className="RegisterTeams-form--field">
        <label htmlFor="registration_venue_id" className="u-font-size-11">
          Venue
        </label>
        {!!venues.length &&
          <Select required
                  items={venues}
                  value={venue_id}
                  onSelect={(id) => onChange(id, TEAM_KEYS.venue_id)}
                  name="league[venue_id]"
                  id="registration_venue_id"/>}
        {!venues.length &&
          <div className="RegisterTeams-form--disabledSelect">
            <i className="RegisterTeams-form--disabledCaret fa fa-caret-down"></i>
          </div>}
      </div>

      <div className="RegisterTeams-form--field">
        <label htmlFor="preferred_league_id" className="u-font-size-11">
          Preferred league
        </label>
        {!!leagueOptions.length &&
          <Select required
                  items={leagueOptions}
                  value={registration_listing_id}
                  onSelect={(id) => onChange(id, TEAM_KEYS.registration_listing_id)}
                  name="preferred_league [registration_listing_id]"
                  id="registration_listing_id"/>}
        {!leagueOptions.length &&
          <div className="RegisterTeams-form--disabledSelect">
            <i className="RegisterTeams-form--disabledCaret fa fa-caret-down"/>
          </div>}
      </div>

      <div className="RegisterTeams-league">
        {!preferredLeague &&
          <div className="d-flex flex-column align-items-center">
            <div className="RegisterTeams-league--image"/>
            <div>Select your league above</div>
          </div>}

        {preferredLeague &&
          <div>
            <div className="u-color-mine-shaft u-mb-2">League info</div>
            <div className="d-flex u-mb-2">
              <div className="u-w-50">
                <div className="RegisterTeams-league--label">Start date</div>
                <div className="u-font-size-13 u-color-mine-shaft">
                  {preferredLeague?.start_date}
                </div>
              </div>
              <div>
                <div className="RegisterTeams-league--label">Season duration</div>
                <div className="u-font-size-13 u-color-mine-shaft">
                  {preferredLeague?.duration} weeks
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div className="u-w-50">
                <div className="RegisterTeams-league--label">Pricing information</div>
                <div className="u-font-size-13 u-color-mine-shaft">
                  ${preferredLeague?.price_per_player}
                </div>
              </div>
              <div className="RegisterTeams-league--column">
                <div className="RegisterTeams-league--label">Additional details</div>
                <div className="u-font-size-13 u-color-mine-shaft">
                  {preferredLeague?.description}
                </div>
              </div>
            </div>
          </div>}
        </div>

      <Button mod="Button--primary"
              title="Next"
              disabled={!sport_id || !venue_id || !registration_listing_id}
              id="next_step"
              onClick={() => onNext()}/>
    </div>
  </form>
}
