import React from 'react'
import ToggleCheckbox from '../shared/ToggleCheckbox';
import SelectDefault from '../shared/SelectDefault';

export default function Integrations({
  onCheckboxChange, 
  labelPosition, 
  isChecked, 
  src, 
  handleSelectChange, 
  optionsVenues, 
  optionsSports, 
  selectVenueValue, 
  selectSportValue
}) {
  return (
    <React.Fragment>
      <p className="u-mb-2">Below you can enable <span className="u-color-dodger-blue">iframe</span> embedding to let you display competition fixtures on your own website.</p>
      <ToggleCheckbox
          value="disabled"
          className="u-font-size-14 u-mb-2 Checkbox--medium"
          label={["Enable", <span key="1" className="u-color-dodger-blue"> iframe </span> , "embedding"]}
          checked={isChecked}
          onChange={onCheckboxChange}
          labelPosition={labelPosition}
      />
      {isChecked && 
        <React.Fragment>
          <div className="row u-mb-2 u-ml-0 align-items-center">
            <span>The code for the view</span>
            <div className="col-3">
              <SelectDefault
                  id="selectVenue"
                  nullable
                  className="browser-default default-form__input"
                  items={optionsVenues}
                  onSelect={(v) => handleSelectChange(v, "selectVenue")}
                  value={selectVenueValue}
                />
            </div>
            <span>and</span>
            <div className="col-3">
              <SelectDefault
                  id="selectSport"
                  nullable
                  className="browser-default default-form__input"
                  items={optionsSports}
                  onSelect={(v) => handleSelectChange(v, "selectSport")}
                  value={selectSportValue}
                />
            </div>
            <span>is:</span>
          </div>
          <p className="row u-mb-4 u-mt-4 u-ml-0">
            <span>&#60;iframe src="</span>
            <span>{src}</span>
            <span>" style="width: 100%; height: 800px;"&#62;&#60;/iframe&#62;</span>
          </p>
          <p>Feel free to change the style to suit your needs (e.g the width and height parameters).</p>
        </React.Fragment>
      }
    </React.Fragment>
  )
}
