import PropTypes from 'prop-types';
import React from 'react';
import { getTimeSlotOffset } from '../../lib/timeSlotsHelper';

export default class Slot extends React.Component {
  static propTypes = {
    isBlocked: PropTypes.bool,
    value: PropTypes.object,
    space: PropTypes.string,
    onSelect: PropTypes.func,
  };

  get isUsed() {
    const { value } = this.props;

    return value && (!value.id || !value.disabled);
  }

  get isNew() {
    const { value } = this.props;

    return value && (!!value.id || value.disabled);
  }

  get style() {
    const { value } = this.props;

    return this.isUsed || this.isNew
      ? {
          marginTop: `${getTimeSlotOffset(value)}px`,
        }
      : {};
  }

  render() {
    const { value, onSelect, space, isBlocked } = this.props;

    return (
      !isBlocked && (
        <div
          // style={this.style}
          className={`timeslots__slot${
            this.isUsed ? ' timeslots__slot_used' : ''
          }${this.isNew ? ' timeslots__slot_selected' : ''}`}
          onClick={() => !this.isUsed && onSelect && onSelect(value)}
        >
          {value && (
            <div>
              <div>{this.isNew ? space : value.first_content}</div>
              <div>
                {this.isNew
                  ? value.first_content.split(' - ')[0]
                  : ''}
              </div>
            </div>
          )}
        </div>
      )
    );
  }
}
