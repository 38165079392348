import { toast } from "./utils";
import { deleteError } from "../components/shared/errorMessages"

const isSuccess = ({ success, errors }) => {
  const ok =    success || !errors || (errors && !Object.keys(errors).length);
  if (!ok) {
    console.warn('--- response errors', errors);
  }
  return ok;
};

export const create = (
  data,
  url,
  onSuccess = () => null,
  onFail = () => null,
) => {
  $.ajax({
    url,
    type: 'POST',
    data,
    success: (responce) => isSuccess(responce)
        ? onSuccess(responce)
        : onFail(responce.errors),
    error: (error) => onFail(error),
    dataType: 'json',
  });
};

export const update = (
  data,
  url,
  onSuccess = () => null,
  onFail = () => null,
) => {
  $.ajax({
    url,
    type: 'PATCH',
    data,
    success: (responce) => isSuccess(responce)
        ? onSuccess(responce)
        : onFail(responce.errors),
    error: (error) => onFail(error),
    dataType: 'json',
  });
};

export const destroy = (
  data,
  url,
  onSuccess = () => null,
  onFail = () => null,
) => {
  $.ajax({
    url,
    type: 'DELETE',
    data,
    success: (responce) => isSuccess(responce)
        ? onSuccess(responce)
        : onFail(responce.errors),
    error: (responce) => {
      toast(responce.responseJSON.message, 'fail', deleteError)
    },
    dataType: 'json',
  });
};

export const get = (
  url,
  onSuccess = () => null,
  onFail = () => null,
) => {
  $.ajax({
    url,
    type: 'GET',
    success: (responce) => isSuccess(responce)
        ? onSuccess(responce)
        : onFail(responce.errors),
    dataType: 'json',
  });
};

export const getWithParams = (
  data,
  url,
  onSuccess = () => null,
  onFail = () => null,
) => {
  $.ajax({
    url,
    data,
    type: 'GET',
    success: (responce) => isSuccess(responce)
        ? onSuccess(responce)
        : onFail(responce.errors),
    dataType: 'json',
  });
};
