import PropTypes from 'prop-types';
import React, { createRef } from 'react';
import axios from 'axios';
import PhoneInput, {
  isValidPhoneNumber,
} from 'react-phone-number-input';
import debounce from 'lodash.debounce';
import classNames from 'classnames';
import queryString from "query-string";
import { DebounceInput } from 'react-debounce-input';
import Spinner from '../shared/Spinner';
import SelectDefault from '../shared/SelectDefault';
import SingleFileUploader from '../shared/SingleFileUploader'
import IntercomTokenInput from './IntercomTokenInput';
import InputWithConfirmation from '../shared/InputWithConfirmation';
import { getErrorTextFromObject } from '../../helpers/utils';
import Integrations from "../Integrations";

const DEBOUNCE_TIMEOUT = 1000;

const FIELD_LABELS = {
  org_name: 'Name',
  email: 'Email',
  phone: 'Phone',
  website: 'Website',
  time_zone: 'Timezone',
  currency_iso_code: 'Currency',
  subdomain: 'Subdomain',
  brand_color: 'Brand Colour',
  captain_code: 'Team Confirmation Code',
  team_webhook_url: 'Team Confirmation Webhook URL',
  intercom_token: 'Intercom token',
  logo: 'Logo',
  app_name: 'App Name',
  ios_link: 'iOS Link',
  android_link: 'Android Link',
  favicon: 'Favicon'
};

const LOCATION_ORIGIN = window.location.origin;
const IFRAME_URL = `${LOCATION_ORIGIN}/fixture_ladder_iframe`

const SELECT_ALL_VANUES = [{id: '', title: 'all venues'}];
const SELECT_ALL_SPORTS = [{id: '', title: 'all sports'}];

export default class SettingsBasicForm extends React.Component {
  static propTypes = {
    company: PropTypes.shape({
      brand_color: PropTypes.string,
      currency_iso_code: PropTypes.string,
      email: PropTypes.string,
      org_name: PropTypes.string,
      phone: PropTypes.string,
      subdomain: PropTypes.string,
      time_zone: PropTypes.string,
      website: PropTypes.string,
      registration_key: PropTypes.string,
      f_registration_active: PropTypes.bool,
      team_webhook_url: PropTypes.string.isRequired,
      intercom_token: PropTypes.string,
      intercom_sync_in_process: PropTypes.bool,
      logo: PropTypes.any,
      app_name: PropTypes.string,
      ios_link: PropTypes.string,
      android_link: PropTypes.string,
      favicon: PropTypes.any
    }).isRequired,
    captain_code: PropTypes.string.isRequired,
  };

  state = {
    brand_color: this.props.company.brand_color || '',
    currency_iso_code: this.props.company.currency_iso_code || '',
    email: this.props.company.email || '',
    org_name: this.props.company.org_name || '',
    phone: this.props.company.phone
      ? `+${this.props.company.phone}`
      : '',
    subdomain: this.props.company.subdomain || '',
    time_zone: this.props.company.time_zone || '',
    website: this.props.company.website || '',
    registration_key: this.props.company.registration_key || '',
    f_registration_active: this.props.company.f_registration_active,
    captain_code: this.props.captain_code || '',
    team_webhook_url: this.props.company.team_webhook_url || '',
    intercom_token: this.props.company.intercom_token,
    intercom_sync_in_process: this.props.company.intercom_sync_in_process,
    logo: this.props.company.logo,
    android_link: this.props.company.android_link || '',
    app_name: this.props.company.app_name || '',
    ios_link: this.props.company.ios_link || '',
    favicon: this.props.company.favicon,

    currencies: [],
    timezones: [],
    isLoading: false,
    errorFields: [],
    isPhoneValid: true,
    isChecked: false,
    iframeSrc: IFRAME_URL,
    optionsVenues: [],
    optionsSports: [],
    selectVenue: '',
    selectSport: '',
    queryParamsVenues: null,
    queryParamsSports: null
  };

  componentDidMount() {
    Promise.all([
      axios.get('/api/system_utils/timezones'),
      axios.get('/api/system_utils/currencies'),
      axios.get('/api/settings/venues'),
      axios.get('/api/settings/sports'),
      axios.get('/api/settings/config')
    ])
      .then((data) => {
        const timezones = data[0].data.map((item) => ({
          id: item[1],
          title: item[0],
        }));
        const currencies = data[1].data.map((item) => ({
          id: item[1],
          title: item[0],
        }));
        const venues = data[2].data.map((item) => ({
          id: item.id,
          title: item.title,
        }));
        const sports = data[3].data.map((item) => ({
          id: item.id,
          title: item.title,
        }));
        const isIframeEnabled = data[4].data.f_iframe_active;

        this.setState({ 
          currencies, 
          timezones,
          optionsVenues: [...SELECT_ALL_VANUES, ...venues],
          optionsSports: [...SELECT_ALL_SPORTS, ...sports],
          isChecked: isIframeEnabled
         });
      })
      .catch(() => {
        M.toast({
          html: 'Failed to receive timezones/currencies',
          classes: 'u-bg-red',
        });
      });
  }

  handleIntercomSyncData = (e) => {
    this.setState({intercom_sync_in_process: true})
    axios
      .post(`/api/settings/sync_intercom_data`)
      .then(() => {
        M.toast({
          html: `Intercom synchronization started`,
          classes: 'u-bg-emerald',
        });
      })
  }

  handleFieldChange = (e) => {
    const { id: fieldName, value: fieldValue } = e.target;

    if (fieldValue === this.state[fieldName]) return;

    const updatedFieldData = {
      [fieldName]: fieldValue,
      errorFields: this.state.errorFields.filter(
        (item) => item !== fieldName,
      ),
    };

    this.setState(updatedFieldData, () => this.handleFieldSave(fieldName),
    );
  };

  handleInputChange = (fieldId, value) => {
    if (value === this.state[fieldId]) return;

    const updatedFieldData = {
      [fieldId]: value,
    };
    this.setState(updatedFieldData, () => this.handleFieldSave(fieldId),
    );
  };

  handleLogoUpdate = (logo) => {
    const formData = new FormData();
    formData.append('company[logo]', logo );
    this.setState({ isLoading: true });

    return axios
      .patch('/api/settings/account', formData)
      .then(() => {
        this.setState({ isLoading: false });
        M.toast({
          html: `Logo saved`,
          classes: 'u-bg-emerald',
        });
      })
      .catch((error) => {
        this.setState({
          errorFields: this.state.errorFields.includes(fieldName)
            ? [...this.state.errorFields]
            : [...this.state.errorFields, fieldName],
          isLoading: false,
        });
        M.toast({
          html: `Field Logo not saved: <br>${getErrorTextFromObject(error)}`,
          classes: 'u-bg-red',
        });
      });
  }

  handleFaviconUpdate = (favicon) => {
    const formData = new FormData();
    formData.append('company[favicon]', favicon );
    this.setState({ isLoading: true });

    return axios
      .patch('/api/settings/account', formData)
      .then(() => {
        this.setState({ isLoading: false });
        M.toast({
          html: `Favicon saved`,
          classes: 'u-bg-emerald',
        });
      })
      .catch((error) => {
        this.setState({
          errorFields: this.state.errorFields.includes(fieldName)
            ? [...this.state.errorFields]
            : [...this.state.errorFields, fieldName],
          isLoading: false,
        });
        M.toast({
          html: `Field Favicon not saved: <br>${getErrorTextFromObject(error)}`,
          classes: 'u-bg-red',
        });
      });
  }

  handlePhoneChange = (phoneNumber) => {
    if (phoneNumber === this.state.phone) return;

    const updatedFieldData = {
      phone: phoneNumber,
      isPhoneValid: isValidPhoneNumber(phoneNumber),
    };

    this.setState(updatedFieldData, () => this.handleFieldSaveDebounced('phone'),
    );
  };

  handleFieldSave = (fieldName) => {
    const fieldValue = this.state[fieldName];

    if (fieldName === 'phone' && !isValidPhoneNumber(fieldValue))
      return;

    const data = {
      company: { [fieldName]: fieldValue },
    };
    this.setState({ isLoading: true });

    return axios
      .patch('/api/settings/account', data)
      .then(() => {
        this.setState({ isLoading: false });
        M.toast({
          html: `${FIELD_LABELS[fieldName]} saved`,
          classes: 'u-bg-emerald',
        });
      })
      .catch((error) => {
        this.setState({
          errorFields: this.state.errorFields.includes(fieldName)
            ? [...this.state.errorFields]
            : [...this.state.errorFields, fieldName],
          isLoading: false,
        });
        M.toast({
          html: `Field ${
            FIELD_LABELS[fieldName]
          } not saved: <br>${getErrorTextFromObject(error)}`,
          classes: 'u-bg-red',
        });
      });
  };

  update_api_secret_key = () => {
    axios
      .post(`/api/settings/generate_company_keys`)
      .then(({data: data}) => {
        this.setState({ registration_key: data.data.new_registration_key });
        M.toast({
          html: `API secure key generated`,
          classes: 'u-bg-emerald',
        });
      })
  };


  handleFieldSaveDebounced = debounce(
    this.handleFieldSave,
    DEBOUNCE_TIMEOUT,
  );

  getInputClassErrorModifier = (fieldName) => this.state.errorFields.includes(fieldName) ? 'is-invalid' : '';

  handleToogleCheckboxChange = () => {
    this.setState({ 
      isChecked: !this.state.isChecked,
      selectVenue: '',
      selectSport: '',
      iframeSrc: IFRAME_URL
    });
    axios.put('/api/settings/account', { 
      company: { 
        f_iframe_active: !this.state.isChecked
      }
    })
    .catch(() => {
      M.toast({
        html: 'Failed to set iframe configuration',
        classes: 'u-bg-red',
      });
    });
  };

  handleSelectChange = (id, selectId) => {
    this.setState({ [selectId]: id});
    let queryStringParams = {
      dv: this.state.selectVenue,
      ds: this.state.selectSport
    };
    let params = {
      skipEmptyString: true,
      sort: false
    }
    let src = `${IFRAME_URL}?${queryString.stringify(queryStringParams, params)}`;
    this.setState({ iframeSrc: src });
  };

  render() {
    const { 
      f_registration_active,
      isLoading, 
      isPhoneValid, 
      isChecked, 
      iframeSrc,
      optionsVenues, 
      optionsSports, 
      selectVenue, 
      selectSport,
      intercom_sync_in_process,
    } = this.state;

    return (
      <div className="Card-content u-pt-4 u-pb-5">
        <div className="row u-mb-2">
          <div className="col-6">
            <h3>Organisation</h3>
            <br />
          </div>
        </div>
        <div className="row u-mb-2">
          <div className="col-6">
            <div className="default-form__input-group">
              <label
                htmlFor="org_name"
                className="default-form__label"
              >
                {FIELD_LABELS.org_name}
              </label>
              <br />
              <DebounceInput
                id="org_name"
                placeholder="Insert organisation name"
                className={`browser-default default-form__input ${this.getInputClassErrorModifier(
                  'org_name',
                )}`}
                value={this.state.org_name}
                onChange={this.handleFieldChange}
                forceNotifyOnBlur
                debounceTimeout={DEBOUNCE_TIMEOUT}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="default-form__input-group">
              <label htmlFor="email" className="default-form__label">
                {FIELD_LABELS.email}
              </label>
              <br />
              <DebounceInput
                id="email"
                type="email"
                placeholder="Insert email"
                className={`browser-default default-form__input ${this.getInputClassErrorModifier(
                  'email',
                )}`}
                value={this.state.email}
                onChange={this.handleFieldChange}
                forceNotifyOnBlur
                debounceTimeout={DEBOUNCE_TIMEOUT}
              />
            </div>
          </div>
        </div>
        <div className="row u-mb-2">
          <div className="col-6">
            <div className="default-form__input-group">
              <label htmlFor="phone" className="default-form__label">
                {FIELD_LABELS.phone}
              </label>
              <br />
              <PhoneInput
                id="phone"
                defaultCountry="AU"
                value={this.state.phone}
                onChange={this.handlePhoneChange}
                placeholder="Insert phone number here"
                displayInitialValueAsLocalNumber
                required
                className={classNames({
                  'invalid-number': !isPhoneValid,
                })}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="default-form__input-group">
              <label
                htmlFor="website"
                className="default-form__label"
              >
                {FIELD_LABELS.website}
              </label>
              <br />
              <DebounceInput
                id="website"
                type="url"
                placeholder="Insert website here"
                className={`browser-default default-form__input ${this.getInputClassErrorModifier(
                  'website',
                )}`}
                value={this.state.website}
                onChange={this.handleFieldChange}
                forceNotifyOnBlur
                debounceTimeout={DEBOUNCE_TIMEOUT}
              />
            </div>
          </div>
        </div>
        <div className="row u-mb-2">
          <div className="col-6">
            <h3>Localisation</h3>
            <br />
          </div>
          <div className="col-6">
            <div className="u-relative">
              <Spinner
                isLoading={isLoading}
                position={{ left: -27 }}
              />
            </div>
          </div>
        </div>
        <div className="row u-mb-2">
          <div className="col-6">
            <div className="default-form__input-group">
              <label
                htmlFor="time_zone"
                className="default-form__label"
              >
                {FIELD_LABELS.time_zone}
              </label>
              <SelectDefault
                id="time_zone"
                nullable
                noInit
                className="browser-default default-form__input"
                items={this.state.timezones}
                onSelect={(value) => this.handleInputChange('time_zone', value)
                }
                value={this.state.time_zone}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="default-form__input-group">
              <label
                htmlFor="currency_iso_code"
                className="default-form__label"
              >
                {FIELD_LABELS.currency_iso_code}
              </label>
              <SelectDefault
                id="currency_iso_code"
                nullable
                noInit
                className="browser-default default-form__input"
                items={this.state.currencies}
                onSelect={(value) => this.handleInputChange('currency_iso_code', value)
                }
                value={this.state.currency_iso_code}
              />
            </div>
          </div>
        </div>

        <div className="row u-mb-2">
          <div className="col-6">
            <h3>Branding</h3>
            <br />
          </div>
        </div>
        <div className="row u-mb-2">
          <div className="col-6">
            <InputWithConfirmation
              id="subdomain"
              title={`Change ${FIELD_LABELS.subdomain}`}
              placeholder="e.g. supertestjf"
              value={this.state.subdomain}
              onSubmit={(value) => this.handleInputChange('subdomain', value)}
            />
          </div>
          <div className="col-6">
            <InputWithConfirmation
              id="brand_color"
              type="color"
              title={FIELD_LABELS.brand_color}
              placeholder="e.g. #aa55cc"
              value={this.state.brand_color}
              onSubmit={(value) => this.handleInputChange('brand_color', value)
              }
            />
          </div>
        </div>

        <div className="row u-mb-2">
          <div className="col-6">
            <h3>Mobile App Details</h3>
            <br />
          </div>
        </div>
        <div className="row u-mb-2">
          <div className="col-6">
            <InputWithConfirmation
              id="app_name"
              title={`${FIELD_LABELS.app_name}`}
              placeholder="Insert App Name"
              value={this.state.app_name}
              onSubmit={(value) => this.handleInputChange('app_name', value)}
            />
          </div>
        </div>
        <div className="row u-mb-2">
          <div className="col-6">
            <InputWithConfirmation
              id="ios_link"
              title={`${FIELD_LABELS.ios_link}`}
              placeholder="Insert iOS Link"
              value={this.state.ios_link}
              onSubmit={(value) => this.handleInputChange('ios_link', value)}
            />
          </div>
          <div className="col-6">
            <InputWithConfirmation
              id="android_link"
              title={`${FIELD_LABELS.android_link}`}
              placeholder="Insert Android Link"
              value={this.state.android_link}
              onSubmit={(value) => this.handleInputChange('android_link', value)}
            />
          </div>
        </div>

        <div className="row u-mb-2">
          <div className="col-6">
            <label htmlFor="logo" className="default-form__label">
              {FIELD_LABELS.logo}
            </label>
            <br />
            <SingleFileUploader
              file={this.state.logo.url}
              url={'/api/settings/account'}
              updateFunc={this.handleLogoUpdate}
              id='logo-uploader'
              label='Choose SVG'
            />
          </div>
          <div className="col-6">
            <label htmlFor="favicon" className="default-form__label">
              {FIELD_LABELS.favicon}
            </label>
            <br />
            <SingleFileUploader
              file={this.state.favicon.url}
              url={'/api/settings/account'}
              updateFunc={this.handleFaviconUpdate}
              id="favicon-uploader"
              label='Choose SVG'
            />
          </div>
        </div>

        <div className="row u-mb-2">
          <div className="col-6">
            <h3 className="u-mb-4">Integrations</h3>
            <div className="default-form__input-group">
              <label className="default-form__label">API secure key</label>
              <br />
              <div className="input-with-confirm-container">
                {f_registration_active && (<input
                  type="text"
                  placeholder="Generate API secure key"
                  className="browser-default default-form__input"
                  value={this.state.registration_key}
                  disabled={true}
                />)}
                {f_registration_active && (<div className="confirm-controls-container">
                  <div className="badge badge-primary u-m-1 Button--nowrap">
                    <a onClick={() => this.update_api_secret_key()}>Generate new key</a>
                  </div>
                </div>)}
                {!f_registration_active && (<div className="confirm-controls-container">
                  Switch on Registration Add-on
                </div>)}
              </div>
            </div>
          </div>
        </div>
        <div className="row u-mb-2">
          <div className="col-6">
            <div className="default-form__input-group">
              <IntercomTokenInput
                id="intercom_token"
                title={ FIELD_LABELS.intercom_token}
                placeholder="Add Intercom Token"
                value={this.state.intercom_token}
                onSubmit={(value) => this.handleInputChange('intercom_token', value)}
                syncIntercomData={() => this.handleIntercomSyncData()}
                syncInProcess={intercom_sync_in_process}
              />
            </div>
          </div>
        </div>
        <div className="row u-mb-2">
          <div className="col-6">
            <div className="default-form__input-group">
              <label
                htmlFor="captain_code"
                className="default-form__label"
              >
                {FIELD_LABELS.captain_code}
              </label>
              <br />
              <DebounceInput
                id="captain_code"
                placeholder="Insert captain code"
                className={`browser-default default-form__input ${this.getInputClassErrorModifier(
                  'captain_code',
                )}`}
                value={this.state.captain_code}
                onChange={this.handleFieldChange}
                forceNotifyOnBlur
                debounceTimeout={DEBOUNCE_TIMEOUT}
              />
            </div>
          </div>
        </div>
        <div className="row u-mb-2">
          <div className="col-6">
            <div className="default-form__input-group">
              <label
                htmlFor="team_webhook_url"
                className="default-form__label"
              >
                {FIELD_LABELS.team_webhook_url}
              </label>
              <br />
              <DebounceInput
                id="team_webhook_url"
                placeholder="Insert URL"
                className={`browser-default default-form__input ${this.getInputClassErrorModifier(
                  'team_webhook_url',
                )}`}
                value={this.state.team_webhook_url}
                onChange={this.handleFieldChange}
                forceNotifyOnBlur
                debounceTimeout={DEBOUNCE_TIMEOUT}
              />
            </div>
          </div>
        </div>
        <div className="row u-mb-2">
          <div className="col-12">
            <Integrations 
                onCheckboxChange={this.handleToogleCheckboxChange} 
                labelPosition='right'
                isChecked={isChecked}
                src={iframeSrc}
                handleSelectChange={this.handleSelectChange}
                optionsVenues={optionsVenues}
                optionsSports={optionsSports}
                selectVenueValue={selectVenue}
                selectSportValue={selectSport}
            /> 
          </div>
        </div>
      </div>
    );
  }
}
