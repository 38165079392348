import React from 'react';
import {editTeamRegistration} from '../util/_options';

export default function WaitingListTable({
  registrationsList,
  onOptionClick,
  onDeleteTeam,
  onArrowClick
}) {

  return <React.Fragment>
      <table className={`ManageListing-table--waitinglist highlight u-w-100`}>
        <thead>
          <tr className="u-text-left u-noBorder u-noPadding">
            <th></th>
            <th className="text__color_blue">Team</th>
            <th className="text__color_blue">Captain</th>
            <th className="text__color_blue">Additional information</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!!registrationsList.length &&
              registrationsList.map(team => {
                const {
                  description,
                  email,
                  id,
                  first_name,
                  last_name,
                  phone,
                  returning_team,
                  team_registration_url,
                  title
                } = team
                return <tr key={id} className="collection-item bordered--2 ManageListing-table-row">
                    <td className="ManageListing-icon--column u-mr-0 u-pt-1 u-pb-1 u-pr-0">
                      {returning_team && team_registration_url &&
                      <a href={team_registration_url}>
                        <i className="fal fa-code-merge Icon Icon--noBorder ManageListing-icon--actions ManageListing-icon--merge u-mr-1"/>
                      </a>}
                    </td>
                    <td className="text-left u-pt-1 u-pb-1 ManageListing-teamtitle-column">
                      {title}
                    </td>
                    <td className="text-left u-pt-1 u-pb-1">
                      {`${first_name} ${last_name}`} <br />
                      {`+${phone}`} <br />
                      {email}
                    </td>
                    <td className="text-left u-pt-1 u-pb-1 ManageListing-big-column">
                      {description}
                    </td>
                    <td className="u-pt-1 u-pb-1">
                      <i className="fal fa-arrow-alt-down ManageListing-icon ManageListing-icon--actions"
                         onClick={() => onArrowClick(id)}/>
                    </td>
                    <td className="u-pt-1 u-pb-1">
                      <i onClick={() => onOptionClick(editTeamRegistration, team)}
                         className="far fa-pen ManageListing-icon ManageListing-icon--pen"/>
                    </td>
                    <td className="u-pt-1 u-pb-1">
                      <i onClick={() => onDeleteTeam(id)}
                         className="fal fa-trash ManageListing-icon ManageListing-icon--trash"/>
                    </td>
                  </tr>})}
        </tbody>
      </table>
    </React.Fragment>
}
