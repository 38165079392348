import React, {useState} from "react";
import {statuses} from "../util/_statuses";
import Background from "../../shared/Background";
import DropdownFilteringSelector from "../DropdownFilteringSelector";

export default function TableFilterCell({
  columnName,
  className = '',
  optionsList,
  chosenOption,
  setChosenOption
}) {

  const [showDropList, setShowDropList] = useState(false);
  const [filter, setFilter] = useState('');

  const onDisableClick = () => {
      setShowDropList(false);
      setFilter('');
  }

  const onLiClick = (id) => {
      setChosenOption(id);
      onDisableClick();
  }

  const filteredList = optionsList
      .map(item => typeof item === "string" ? {id: item, title: statuses[item]} : item)
      .filter(({title}) => filter.length === 0 || isInclude(filter, title));

  const findChosenElement = (list, option, defaultName) => {
      let columnName;

      if (list && option && (columnName = list.find((elem) => option === elem.id)) !== undefined) {
          return columnName.title;
      }
      return defaultName;
  }

  return <th className={`u-pt-2 u-pb-2 ${className}`}>
    <Background setDisable={onDisableClick}
                isShow={showDropList}/>

    <div className="u-relative">
      <span className="select2 select2-container select2-container--leagues select2-container--above select2-container--focus col-12 browser-default">
        <span className="selection">
          <span className="select2-selection select2-selection--single"
                role="combobox">
            <span className="select2-selection__rendered"
                  title={columnName}
                  onClick={() => setShowDropList(true)}>
                {findChosenElement(filteredList, chosenOption, columnName)}
            </span>
          </span>
        </span>
      </span>

      {showDropList &&
        <DropdownFilteringSelector filter={filter}
                                    filteredList={filteredList}
                                    defaultName={columnName}
                                    chosenOption={chosenOption}
                                    setFilter={setFilter}
                                    onLiClick={onLiClick}/>}
    </div>
  </th>
}

export const isInclude = (filter, input) => input.toLowerCase().includes(filter.toLowerCase());
