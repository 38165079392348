import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment-timezone';
import { noop } from 'lodash';
import AsyncPaginate from 'react-select-async-paginate';
import Select, { createFilter } from 'react-select';
import axios from 'axios';
import { TZformat } from '../shared/configs';
import { HOURS_RANGE } from '../../lib/timeSlotsHelper';
import Calendar from '../shared/Calendar';
import { redirect } from '../../lib/utils';
import { getErrorTextFromObject } from '../../helpers/utils';

const returnLoadedOptions = (responseJSON, page) => ({
  options: responseJSON.options,
  hasMore: responseJSON.has_more,
  additional: {
    page: page + 1,
  },
});

const filterConfig = {
  ignoreCase: true,
  ignoreAccents: true,
  trim: true,
};

const requestHeaders = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

const timeFormat = 'HH:mm a';
const hoursFormat = 'h A';

export default class FriendlyMatchForm extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      id: PropTypes.number,
      start_date: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
      ]).isRequired,
      start_time: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
      ]),
      team_a: PropTypes.object,
      team_b: PropTypes.object,
      space: PropTypes.object,
      sport: PropTypes.object,
      fee_a: PropTypes.number,
      fee_b: PropTypes.number,
      venue: PropTypes.object,
      errors: PropTypes.object,
    }),
    venue: PropTypes.object.isRequired,
    onMatchUpdate: PropTypes.func,
    date: PropTypes.string,
  };

  static defaultProps = {
    date: moment().format('YYYY-MM-DD'),
  };

  state = {
    id: this.props.match.id,
    start_date: this.props.match.start_date,
    start_time: this.props.match.start_time,
    team_a: this.props.match.team_a,
    team_b: this.props.match.team_b,
    space: this.props.match.space,
    sport: this.props.match.sport,
    fee_a: this.props.match.fee_a || 0.0,
    fee_b: this.props.match.fee_b || 0.0,
    hour: this.props.match.start_time
      ? {
          label: moment(
            this.props.match.start_time,
            timeFormat,
          ).format(hoursFormat),
          value: moment(
            this.props.match.start_time,
            timeFormat,
          ).format(hoursFormat),
        }
      : null,
    minute:
      moment(this.props.match.start_time, timeFormat).minute() || 0,
    venue: this.props.match.venue || this.props.venue,
    errors: {},
    // Can take any value. When this prop changed, AsyncPaginate cleans all cached options.
    invalidateOptions: false,
    enableEditDateMode: false,
  };

  onSelectVenue = (venue) => {
    this.setState((prevState) => ({
      ...prevState,
      venue,
      sport: null,
      space: null,
      invalidateOptions: !prevState.invalidateOptions,
    }));
  };

  onSelectSport = (sport) => {
    this.setState((prevState) => ({
      ...prevState,
      sport,
      space: null,
      invalidateOptions: !prevState.invalidateOptions,
    }));
  };

  onSelectSpace = (space) => {
    this.setState((prevState) => ({
      ...prevState,
      space,
      invalidateOptions: !prevState.invalidateOptions,
    }));
  };

  onSelectItem = (key, value) => {
    this.setState((prevState) => ({ [key]: value }));
  };

  invalidateSelectCache = () => {
    this.setState((prevState) => ({
      invalidateOptions: !prevState.invalidateOptions,
    }));
  };

  async loadVenueOptions(search, loadedOptions, { page }) {
    const response = await fetch(
      `/settings/venues?term=${search}&page=${page}`,
      requestHeaders,
    );
    const responseJSON = await response.json();
    return returnLoadedOptions(responseJSON, page);
  }

  loadSportOptions = async (search, loadedOptions, { page }) => {
    const { venue } = this.state;
    const response = await fetch(
      `/settings/sports/?venue_id=${venue.id}&term=${search}&page=${page}`,
      requestHeaders,
    );
    const responseJSON = await response.json();
    return returnLoadedOptions(responseJSON, page);
  };

  loadSpaceOptions = async (search, loadedOptions, { page }) => {
    const { venue, sport } = this.state;
    const response = await fetch(
      `/settings/venues/${venue.id}/spaces/?sport_id=${sport.id}&term=${search}&page=${page}`,
      requestHeaders,
    );
    const responseJSON = await response.json();
    return returnLoadedOptions(responseJSON, page);
  };

  async loadTeamOptions(search, loadedOptions, { page }) {
    const response = await fetch(
      `/teams/?term=${search}&page=${page}`,
      requestHeaders,
    );

    const responseJSON = await response.json();
    return returnLoadedOptions(responseJSON, page);
  }

  onChooseStartDate = (date) => {
    this.setState((prevState) => ({
      enableEditDateMode: !prevState.enableEditDateMode,
      start_date: date.format('YYYY-MM-DD'),
    }));
  };

  onFormSubmit = (e) => {
    e.preventDefault();

    const {
      id,
      start_date,
      start_time,
      team_a,
      team_b,
      space,
      sport,
      fee_a,
      fee_b,
      hour,
      minute,
      venue,
    } = this.state;

    const startTime = hour
      ? moment(start_date, 'YYYY-MM-DD')
          .set({ hour: moment(hour.value, 'h:mm a').hour(), minute })
          .format(TZformat)
      : null;
    const data = {
      match: {
        start_date,
        start_time: startTime,
        team_a_id: Object(team_a).id,
        team_b_id: Object(team_b).id,
        space_id: Object(space).id,
        sport_id: Object(sport).id,
        venue_id: Object(venue).id,
        fee_a,
        fee_b,
      },
    };

    if (id) {
      axios
        .put(
          `/venues/${venue.id}/matches/${id}.json?date=${this.props.date}`,
          data,
        )
        .then((res) => {
          // TODO: need to refactor so that when this component invoked from calendar it receives onMatchUpdate() from props
          if (typeof this.props.onMatchUpdate === 'function') {
            this.props.onMatchUpdate(res.data);
          } else {
            redirect(
              `/venues/${venue.id}/calendar?date=${start_date}`,
            );
          }
        })
        .catch((error) => {
          const errorText = getErrorTextFromObject(error);
          this.setState({ errors: errorText });
          M.toast({ html: errorText, classes: 'u-bg-red' });
        });
    } else {
      axios
        .post(`/venues/${venue.id}/matches.json`, data)
        .then((res) => {
          redirect(`/venues/${venue.id}/schedule?date=${start_date}`);
        })
        .catch((error) => {
          const errorText = getErrorTextFromObject(error);
          this.setState({ errors: errorText });
          M.toast({ html: errorText, classes: 'u-bg-red' });
        });
    }
  };

  componentDidMount() {
    M.updateTextFields();
  }

  render() {
    const {
      id,
      venue,
      sport,
      space,
      team_a,
      team_b,
      fee_a,
      fee_b,
      invalidateOptions,
      start_date,
      start_time,
      enableEditDateMode,
      hour,
      minute,
      errors,
    } = this.state;

    const local_date = moment(start_date).format('DD MMM, YYYY');
    const submitButtonText = id ? 'Update match' : 'Create match';

    return (
      <form onSubmit={this.onFormSubmit}>
        <div className="row">
          <div className="col-6">
            <div
              className="input-field"
              onClick={() => this.setState({ enableEditDateMode: true })
              }
            >
              <span className="calendar-icon">
                <input
                  id="match_start_date"
                  type="text"
                  className="validate"
                  value={local_date}
                  onChange={noop} // in order to eliminate React warning, onChange should be provided here
                />
              </span>
              <label htmlFor="match_start_date" className="active">
                Start date
              </label>

              {enableEditDateMode && (
                <div className="popup__content">
                  <Calendar
                    onSelect={(value) => this.onChooseStartDate(value)
                    }
                    value={start_date}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col-3">
            <div className="input-field" style={{ marginTop: '8px' }}>
              <label
                htmlFor="match[start_time(4i)]"
                className="active"
              >
                Hour
              </label>
              <Select
                value={hour}
                className="react-select-updated"
                name="match[start_time(4i)]"
                defaultValue={HOURS_RANGE[0]}
                options={HOURS_RANGE.map((hour) => ({
                  label: hour,
                  value: moment(hour, ['h:m a', 'H:m']).hour(),
                }))}
                onChange={(item) => this.onSelectItem('hour', item)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="input-field">
              <input
                id="match[start_time(5i)]"
                type="number"
                className="validate"
                defaultValue={minute}
                onChange={(e) => this.setState({ minute: e.target.value })
                }
                min={0}
                max={59}
              />
              <label
                htmlFor="match[start_time(5i)]"
                className="active"
              >
                Minutes
              </label>
            </div>
          </div>
        </div>

        {errors && errors.start_time && (
          <div className="helper-text" data-error="wrong">
            {errors.start_time.join(', ')}
          </div>
        )}

        <label className="label-wrapper">Venue</label>
        <AsyncPaginate
          value={venue}
          className="react-select-updated react-select-updated--spaced"
          filterOption={createFilter(filterConfig)}
          hideSelectedOptions
          cacheUniq={invalidateOptions}
          getOptionLabel={(venue) => `${venue.title}`}
          getOptionValue={(venue) => `${venue.id}`}
          loadOptions={this.loadVenueOptions}
          onChange={this.onSelectVenue}
          additional={{
            page: 1,
          }}
        />

        <label className="label-wrapper">Sport</label>
        <AsyncPaginate
          value={sport}
          className="react-select-updated react-select-updated--spaced"
          isClearable={!venue}
          isDisabled={!venue}
          hideSelectedOptions
          cacheUniq={invalidateOptions}
          getOptionLabel={(sport) => `${sport.title}`}
          getOptionValue={(sport) => `${sport.id}`}
          loadOptions={this.loadSportOptions}
          onChange={this.onSelectSport}
          additional={{
            page: 1,
          }}
        />

        {errors && errors.sport && (
          <div className="helper-text" data-error="wrong">
            {errors.sport.join(', ')}
          </div>
        )}

        <label className="label-wrapper">Space</label>
        <AsyncPaginate
          value={space}
          className="react-select-updated react-select-updated--spaced"
          isClearable={!sport}
          isDisabled={!sport}
          hideSelectedOptions
          cacheUniq={invalidateOptions}
          getOptionLabel={(space) => `${space.title}`}
          getOptionValue={(space) => `${space.id}`}
          loadOptions={this.loadSpaceOptions}
          onChange={this.onSelectSpace}
          additional={{
            page: 1,
          }}
        />

        {errors && errors.space && (
          <div className="helper-text" data-error="wrong">
            {errors.space.join(', ')}
          </div>
        )}

        <div className="row u-mb-2">
          <div className="col-7">
            <label className="label-wrapper">Team A</label>
            <AsyncPaginate
              value={team_a}
              className="react-select-updated react-select-updated--spaced"
              hideSelectedOptions
              getOptionLabel={(team_a) => `${team_a.title} (${team_a.captain})`
              }
              getOptionValue={(team_a) => `${team_a.id}`}
              loadOptions={this.loadTeamOptions}
              onChange={(item) => this.onSelectItem('team_a', item)}
              additional={{
                page: 1,
              }}
            />
            {errors && errors.team_a && (
              <div className="helper-text" data-error="wrong">
                {errors.team_a.join(', ')}
              </div>
            )}
          </div>
          <div className="col-3">
            <div
              className="input-field"
              style={{ marginTop: '22px' }}
            >
              <label htmlFor="fee_a">Fee A</label>
              <input
                type="number"
                id="fee_a"
                value={fee_a}
                onChange={(e) => this.onSelectItem('fee_a', e.target.value)
                }
              />
            </div>
          </div>
        </div>

        <div className="row u-mb-2" style={{ marginTop: '-30px' }}>
          <div className="col-7">
            <label className="label-wrapper">Team B</label>
            <AsyncPaginate
              value={team_b}
              className="react-select-updated react-select-updated--spaced"
              hideSelectedOptions
              getOptionLabel={(team_b) => `${team_b.title} (${team_b.captain})`
              }
              getOptionValue={(team_b) => `${team_b.id}`}
              loadOptions={this.loadTeamOptions}
              onChange={(item) => this.onSelectItem('team_b', item)}
              additional={{
                page: 1,
              }}
            />
            {errors && errors.team_b && (
              <div className="helper-text" data-error="wrong">
                {errors.team_b.join(', ')}
              </div>
            )}
          </div>
          <div className="col-3">
            <div
              className="input-field"
              style={{ marginTop: '22px' }}
            >
              <label htmlFor="fee_b">Fee B</label>
              <input
                type="number"
                id="fee_b"
                value={fee_b}
                onChange={(e) => this.onSelectItem('fee_b', e.target.value)
                }
              />
            </div>
          </div>
        </div>
        <div className="d-flex">
          <input
            type="submit"
            className="Button Button--primary Button--medium"
            value={submitButtonText}
          />
          <a
            href={`/venues/${venue.id}/schedule`}
            className="Button Button--cancel Button--medium u-ml-1"
          >
            Cancel
          </a>
        </div>
      </form>
    );
  }
}
