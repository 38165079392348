import React from "react";
import {
    parseMatchFeeFromDescription,
    parseCompetitionFromDescription
} from "../util/_payment_fields_operations";
import {moneyFormatterZeros} from "../../../lib/utils";

export default function OverallWindowPart({payment, option, invoiceAmount = null}) {
    const {description, team, amount, sport, venue} = payment;

    return <React.Fragment>
            <h1 className="PaymentTab-title">{option}</h1>

            <h2 className="PaymentTab-fee u-mb-1">
                <span className="u-font-size-16 u-weight-400">
                    {parseMatchFeeFromDescription(description)}
                </span>
                <span className="separator">|</span>
                <span className="u-font-size-16 u-weight-600">
                    ${moneyFormatterZeros(invoiceAmount ? invoiceAmount : amount)}
                </span>
            </h2>

            <div className="SchedulePaymentsTab-invoice-info u-mt-2 u-mb-2">
                <p className="u-weight-400 u-font-size-17">{team}</p>

                <div className="d-flex align-items-center">
                    {sport && 
                        <div className="d-flex u-mt-1">
                            <div className="sport-icon u-mr-1"/>   
                            <p className="PaymentTab-description u-weight-400 u-font-size-13">{sport}</p>
                        </div>}

                    {venue && 
                        <div className="d-flex u-mt-1">
                            <p className="u-font-size-12 separator">|</p>
                            <div className="venue-icon u-mr-1"/>
                            <p className="PaymentTab-description u-weight-400 u-font-size-13">{venue}</p>
                        </div>}
                </div>

                {description && 
                    <div className="d-flex u-mt-1">
                        <div className="desc-icon u-mr-1"/>  
                        <p className="PaymentTab-description u-weight-400 u-font-size-13">
                            {description.includes("|")
                            ? parseCompetitionFromDescription(description)
                            : description}
                        </p>
                    </div>}
            </div>
        </React.Fragment>
}
