import React, {useState} from 'react'
import OverallWindowPart from "./OverallWindowPart";
import {buildQueryParams} from "../util/_build_request_params";

export default function AdjustFeeRefund({payment, option, confirmCallback, cancelCallback, tabClasses = ''}) {

    const initialAdjust = payment ? parseFloat(payment.amount).toFixed(2) : '00.00'

    const [reason, setReason] = useState('');
    const [adjustTo, setAdjustTo] = useState(initialAdjust);
    const [inputStatus, setInputStatus] = useState('');
    const [reasonStatus, setReasonStatus] = useState('');

    if (payment === null)
        return null;

    const paymentPhrase = option !== 'Refund' ? 'Adjust to:' : null;

    const submitFunction = () => {
        if(reason.length === 0) {
            setReasonStatus('reason is not filled');
            setInputStatus('')
        } else if (!paymentPhrase) {
            setInputStatus('');
            confirmCallback(payment,
                buildQueryParams({invoice: {update_reason: reason}}),
                setInputStatus);
        } else if (paymentPhrase && /^\d*[,|.]?\d+$/.test(adjustTo)) {
            setInputStatus('');
            confirmCallback(payment,
                buildQueryParams({invoice: {update_reason: reason, amount: parseFloat(adjustTo)}}),
                setInputStatus);
        } else {
            setInputStatus('Incorrect number');
            setReasonStatus('');
        }
    }

    return (
        <div className={`PaymentModal PaymentModal-width--28 u-element-center u-fixed ${tabClasses}`}>
            <OverallWindowPart payment={payment}
                               option={option}/>
                               
            <p className="u-color-red u-mr-1 u-font-size-13">{reasonStatus}</p>

            <div>
                <div className="d-flex align-items-center u-mr-1 u-mb-1">
                    <span className="PaymentTab-label-transparent u-font-size-11">Reason</span>
                    <span className="u-color-red u-font-size-11">*</span>
                </div>

                <input className="PaymentTab-reason u-relative"
                       onChange={(e) => setReason(e.target.value)}
                       value={reason}/>
            </div>
            
            <p className="u-color-red u-font-size-13">{inputStatus}</p>

            {paymentPhrase && 
                <div className="u-mt-1 d-flex">
                    <span className="PaymentTab-label u-mr-1">{paymentPhrase}</span>
                    <input className="PaymentTab-amount browser-default"
                           type="text"
                           onChange={(e) => setAdjustTo(e.target.value.substring(1))}
                           value={`\$${adjustTo}`}
                    />
                </div>
            }

            <div className="d-flex">
                <button  className="Button Button--primary PaymentTab-button"
                         onClick={() => submitFunction()}>
                    Confirm
                </button>

                <button className="Button Button--primary-empty PaymentTab-button"
                        onClick={() => cancelCallback()}>
                    Cancel
                </button>
            </div>
        </div>
    )
}
