import axios from 'axios';
import {buildQueryParams} from './_build_request_params';
import {redirect} from '../../../lib/utils';

const getSuggestedMatch = (registration_listing_id, team_registration_id, set) => {
  const params = buildQueryParams({registration_listing_id, team_registration_id});

  axios
  .get(`/api/suggested_teams?${params}`)
  .then(({data: {data: data}}) => {
    set(data.suggested_teams);
  })
};

const updateTeam = (registration_listing_id, id, team_id,) => {
  let path = `/api/registration_listings/${registration_listing_id}/process_registrations/${id}`;
  let redirectUrl = `/registration_listings/${registration_listing_id}/team_registrations/`;

  if(team_id) {
    const query = buildQueryParams({team_id});
    path = `${path}?${query}`;
  }

  axios
  .patch(path)
  .then(response => {
    redirect(redirectUrl);
  })
  .catch(error => console.error(error));
};

export {
  getSuggestedMatch,
  updateTeam
}
