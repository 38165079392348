import React, { useEffect, useState } from "react";
import Select from "../../shared/Select";
import Button from "../../shared/Button";
import Spinner from "../../shared/Spinner";
import moment from "moment";
import axios from "axios";

export default function TransferModal({
    checkedRegistrations,
    title, 
    cancelCallback,
    confirmCallback,
    params
}) {

    const [items, setItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isLoading, setLoading] = useState(true);
    
    const transferParams = [
        {api: '/api/registration_listings'},
        {api: '/api/leagues'}
    ];

    useEffect(() => {
        getTransferSelectItems()
    }, []);

    const getTransferSelectItems = () => {
        const index = title === 'listing' ? 0 : 1

        axios
          .get(`${transferParams[index].api}?${params}`)
          .then(({data: {data}}) => {
            if (!index) {
                getItemsListing(data)
                setLoading(false)
            } else {
                getItemsLeague(data)
                setLoading(false)
            }
        })
    };

    const getItemsListing = async(list) => {
        let items = [];
        await list.forEach(listing => {
          items.push({id: listing.id, title: `${listing.venue} | ${listing.day} | ${listing.division} | ${moment(listing.start_date).format('LL')}`})
        });
        setItems(items)
    };

    const getItemsLeague = async(list) => {
        let items = [];
        await list.forEach(league => {
            items.push({id: league.id, title: `${league.venue.title} | ${league.day} | ${league.division.title} | ${league.season.title}`})
        })
        setItems(items)
    };

    return <div className={`PaymentModal u-element-center u-fixed u-p-3`}>
            <Spinner isLoading={isLoading}
                     position={{right: '50%', bottom: '45%'}}/>

            <div className="TeamRegistration-row">
                <h1 className="u-font-size-20 u-weight-700">Transfer to {title}</h1>
            </div>

            {isLoading &&
                <div className="TransferModal-loading-area"/>}

            {!isLoading &&
                <div className="TransferModal-select">
                    {!!items.length &&
                        <Select title={`Select ${title}`}
                                required
                                items={items}
                                value={selectedItem}
                                onSelect={(item) => setSelectedItem(item)}
                                name={`select_${title}`}
                                id={`select_${title}`}/>}

                    {!items.length &&
                        <div className="u-mt-3">
                            No available leagues, please create new league.
                        </div>}
                </div>}

            <div className="d-flex u-mt-3">
                    <Button mod="Button--primary Button--medium ManageListing-button--large"
                            title="Confirm"
                            id="confirm-transfer"
                            disabled={!items.length}
                            onClick={() => confirmCallback(checkedRegistrations, selectedItem)}/>
                    <Button mod="Button Button--medium ManageListing-button--large u-ml-2"
                            title="Cancel"
                            id="cancel-transfer"
                            onClick={() => cancelCallback()}/>
            </div>
        </div>
}
