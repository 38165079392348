import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import ReactDOM from 'react-dom';
import { updateSelects } from '../../lib/materialHelper';

export default class Select extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    name: PropTypes.string,
    mod: PropTypes.string,
    fieldMod: PropTypes.string,
    id: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    ignore: PropTypes.array,
    value: PropTypes.any,
    onSelect: PropTypes.func.isRequired,
    nullable: PropTypes.bool,
    noInit: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  componentDidMount() {
    $(ReactDOM.findDOMNode(this)).find('select').formSelect();
    // Check that nothing will break after removal
    $(ReactDOM.findDOMNode(this.selectBox)).on(
      'change',
      this.onChange.bind(this),
    );
  }

  onChange({ target: { value } }) {
    const { onSelect } = this.props;
    onSelect(value);
  }

  UNSAFE_componentWillReceiveProps({ value }) {
    if (value !== this.props.value) {
      $(ReactDOM.findDOMNode(this)).find('select').formSelect();
    }
  }

  render() {
    const {
      title,
      items,
      fieldMod = '',
      mod = '',
      name,
      id,
      value,
      ignore = [],
      nullable,
      disabled,
    } = this.props;

    const className = classnames(fieldMod, {
      'input-field': true,
      'input-field_high': title,
    });

    return <div className={`select-dropdown-dark ${className}`}
                ref={(el) => (this.selectBox = el)} >
      <select className={mod}
              name={name || id}
              id={id}
              onChange={this.onChange.bind(this)}
              value={nullable ? value : value || -1}
              disabled={disabled} >
        {items.length > 0 &&
          <option disabled value={-1} />}

        {items.map((item, i) =>
          <option key={i} value={item.id || item}>
            {item.title || item}
          </option>)}
      </select>
      <label className={`input-field__label select-label${value ? '_filled active' : '_empty'}`}
             htmlFor={id}>
        {title}
      </label>
    </div>
  }
}
