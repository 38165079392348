import React from 'react';
import Button from '../shared/Button';
import {redirect} from '../../lib/utils';

export default function LandingPages({
  fixtures_and_results_url,
  company,
  register_a_team_url,
  active_registration_listings
}) {
  const {email, org_name, phone, website} = company;

  return <div className="d-flex align-items-center justify-content-center"> 
    <div className="LandingPages-rotes u-bg-white u-pt-4 u-pl-5 u-pb-4">
      <h1 className="u-font-size-30 u-color-dodger-blue u-mb-1">{org_name}</h1>

      {(email || phone || website) &&
        <React.Fragment>
          <div className="u-font-size-17 u-mb-2">Contact Us</div>

          <div className="d-flex align-items-center  u-mb-1">
            <i className="far fa-external-link u-color-dodger-blue u-font-size-17 u-mr-2" />
            <div className="u-color-dodger-blue">{website}</div>
          </div>

          <div className="d-flex align-items-center u-mb-1">
            <i className="LandingPages-fa-phone far fa-phone u-color-dodger-blue u-font-size-17 u-mr-2" />
            <div className="u-color-dodger-blue">{phone}</div>
          </div>

          <div className="d-flex align-items-center">
            <i className="far fa-envelope u-color-dodger-blue u-font-size-17 u-mr-2" />
            <div className="u-color-dodger-blue">{email}</div>
          </div>
        </React.Fragment>}

      <div className="d-flex u-mt-5">
        {active_registration_listings && (
          <Button mod="Button--primary u-mr-1"
                title="Registration"
                id="registration"
                onClick={() => redirect(register_a_team_url)}/>
        )}
        <Button mod="Button--primary"
                title="Fixtures/Results"
                id="fixture_results"
                onClick={() => redirect(fixtures_and_results_url)}/>
      </div>
    </div>
  </div>
}
