import React, {useEffect, useState, useRef, useCallback} from 'react'
import Background from "../shared/Background";
import {markAsPaid, voidFee, refund, adjustFee, addPaymentItem, editFee, editPayment} from './util/_options'
import {overdues, paid, upcoming} from './util/_tabs'
import PaymentsTable from "./payments_table/PaymentsTable";
import Spinner from "../shared/Spinner";
import {
    voidFeeCallback,
    markAsPaidCallback,
    refundCallback,
    adjustFeeCallback, addPaymentItemCallback
} from './util/_option_window_callbacks'
import VoidFeeMarkAsPaid from "./option_windows/VoidFeeMarkAsPaid";
import AdjustFeeRefund from "./option_windows/AdjustFeeRefund";
import PaymentInformation from "./option_windows/PaymentInformation";
import {getPaymentsWithFilters} from './util/_payments_tabs_api'
import DateFilteringBlock from "./DateFilteringBlock";
import TableFilterCell from "./payments_table/TableFilterCell";
import Button from "../shared/Button";
import AddPayment from "./option_windows/AddPayment";
import {buildQueryParams} from "./util/_build_request_params";
import EditPaymentFee from "./option_windows/EditPaymentFee";
import {allActions} from "./util/_manual_adjust_invoice_constants";
import AvailableBalance from "./AvailableBalance"

export const tabs = [
    {tab: paid, api: '/paids', className: 'Button--switchFirst'},
    {tab: overdues, api: '/overdues', className: 'Button--switchMiddle'},
    {tab: upcoming, api: '/upcomings', className: 'Button--switchLast'}
]

export default function Payments({
    sports,
    statuses,
    venues,
    teams = null,
    teamId = null,
    headLine = 'Payments',
    children
}) {

    const [isLoading, setLoading] = useState(true);
    const [chosenTab, setChosenTab] = useState(0);
    const [paymentsList, setPaymentsList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [popupPayment, setPopupPayment] = useState(null);
    const [popupOption, setPopupOption] = useState(null);
    const [firstDate, setFirstDate] = useState('');
    const [lastDate, setLastDate] = useState('');
    const [status, setStatus] = useState(null);
    const [team, setTeam] = useState(null);
    const [sport, setSport] = useState(null);
    const [venue, setVenue] = useState(null);

    const [onlinePaymentStatus, setOnlinePaymentStatus] = useState(false)
    const [activated, setActivated] = useState(false);
    const [activationLink, setActivationLink] = useState('');
    const [balance, setBalance] = useState(0);
    const [detailsLink, setDetailsLink] = useState('');

    const additionalClass = onlinePaymentStatus ? 'search-field-short' : null

    const nextPage = useRef(null);

    useEffect(() => {
        onChangeFilters();
    }, [searchValue, firstDate, lastDate, status, team, sport, venue]);

    useEffect(() => {
        if (currentPage !== 1)
            apiRequestFunc(true, currentPage)
            apiRequestStripeBalance()
    }, [currentPage])

    useEffect(() => setLoading(false), [paymentsList]);

    useEffect(() => {
        status || team || venue || sport ? clearAllFilters() : onChangeFilters();
    }, [chosenTab])

    const clearAllFilters = () => {
        setStatus(null);
        setTeam(null);
        setSport(null);
        setVenue(null);
    }

    const onChangeFilters = () => {
        setLoading(true);
        setCurrentPage(1);
        apiRequestFunc(false, 1);
    }

    const apiRequestFunc = (pageChanged, page) => {
        getPaymentsWithFilters(`${tabs[chosenTab].api}?${buildParams(page)}`)
            .then(({data: {data, next_page: newNextPage}}) => {
                nextPage.current = newNextPage;
                setPaymentsList(oldList => pageChanged ? [...oldList, ...data] : data);
            })
    }

    const apiRequestStripeBalance = () => {
        getPaymentsWithFilters(`/stripe/balance`)
            .then(response => {
                setOnlinePaymentStatus(response.data.data.online_payments)
                setActivated(response.data.data.account_activated)
                setActivationLink(response.data.data.activation_link)
                setBalance(response.data.data.available_balance)
                setDetailsLink(response.data.data.details_link)
            })

    }

    const onNextPage = useCallback(() => {
        if (nextPage.current) {
            setCurrentPage(nextPage.current);
            return true;
        }

        return false;
    }, [nextPage.current])

    const buildParams = (page) => {
        const params = {
            page,
            filters: {
                term: searchValue,
                date_from: firstDate,
                date_to: lastDate,
                sport_id: sport,
                status: status,
                venue_id: venue,
                team_id: teamId ? teamId : team
            }
        }

        return buildQueryParams(params);
    }

    const disablePopup = () => {
        setPopupPayment(null);
        setPopupOption(null);
    }

    const onNewPaymentClick = () => {
        setPopupPayment({});
        setPopupOption(addPaymentItem);
    }

    const onClickMarkAsPaid = (payment) =>
        setPaymentsList(prevList => prevList.filter(item => item.id !== payment.id))

    const getNecessaryTab = (option, payment) => {
        switch (option) {
            case markAsPaid:
                return <VoidFeeMarkAsPaid payment={payment}
                                          option={option}
                                          cancelCallback={disablePopup}
                                          confirmCallback={markAsPaidCallback(onClickMarkAsPaid, disablePopup)}/>
            case voidFee:
                return <VoidFeeMarkAsPaid payment={payment}
                                          option={option}
                                          cancelCallback={disablePopup}
                                          confirmCallback={voidFeeCallback(setPaymentsList, disablePopup)}/>
            case refund:
                return <AdjustFeeRefund payment={payment}
                                        option={option}
                                        cancelCallback={disablePopup}
                                        confirmCallback={refundCallback(setPaymentsList, disablePopup)}/>
            case adjustFee:
                return <AdjustFeeRefund payment={payment}
                                        option={option}
                                        cancelCallback={disablePopup}
                                        confirmCallback={adjustFeeCallback(setPaymentsList, disablePopup)}/>
            case addPaymentItem:
                return <AddPayment option={option}
                                   cancelCallback={disablePopup}
                                   confirmCallback={addPaymentItemCallback(teamId, onChangeFilters, disablePopup)}/>
            case editFee:
            case editPayment:
                return <EditPaymentFee payment={payment}
                                       option={option}
                                       currentTab={chosenTab}
                                       setPayment={setPopupPayment}
                                       cancelCallback={disablePopup}
                                       setPaymentList={setPaymentsList}
                                       availableActions={allActions}
                                       tabClass={'u-w-35'}
                />
            default:
                return <PaymentInformation payment={payment}
                                           option={option}
                                           cancelCallback={disablePopup}/>
        }
    }

    return (
        <div style={{position: 'relative'}}>
            <Background isShow={popupPayment && popupOption}
                        setDisable={disablePopup}
                        backgroundStyle={'Underlayer-dark'}/>

            {(popupOption && popupPayment) &&
                getNecessaryTab(popupOption, popupPayment)}

            <div className="d-flex justify-content-between">
                <h1 className="u-font-size-30 u-m-0 u-pb-3">{headLine}</h1>
                {children &&
                    <div className="d-flex justify-content-between">
                        <Button mod="Button Button--primary Button--medium u-mr-1"
                                title="Add payment item"
                                id="add-payment-item-btn"
                                onClick={() => onNewPaymentClick()}/>
                        {children}
                    </div>}
            </div>

            <div className="d-flex justify-content-flex-start align-items-center u-pb-3">
                <div className="search-wrapper">
                    <i className="far fa-search filter-search-icon"/>
                    <input className="search-field browser-default"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            type="text"
                            placeholder="Search"/>
                </div>

                <DateFilteringBlock firstDate={firstDate}
                                    setFirstDate={setFirstDate}
                                    lastDate={lastDate}
                                    setLastDate={setLastDate}
                                    additionalClass={additionalClass}>
                    {children}
                </DateFilteringBlock>

                {onlinePaymentStatus && 
                    <AvailableBalance activated={activated} 
                                      activationLink={activationLink}
                                      balance={balance}
                                      detailsLink={detailsLink}/>}                
            </div>

            <div className="row u-m-0 u-pb-3">
                {tabs.map(({tab, className}, index) => {
                    const primaryClass = chosenTab === index ? 'Button--primary' : '';
                    return <div key={index}
                                className={`Button Button--medium ${className} ${primaryClass}`}
                                onClick={() => setChosenTab(index)}>
                                {tab}
                            </div>})}
            </div>            

            <div className="card jsLeaguesList">
                <div className="table__wrapper">
                    <Spinner isLoading={isLoading}
                             position={{right: '50%', bottom: '25%'}}/>

                    {!isLoading &&
                        <PaymentsTable paymentsList={paymentsList}
                                       chosenTab={chosenTab}
                                       setPayment={(payment) => setPopupPayment(payment)}
                                       setOption={(option) => setPopupOption(option)}
                                       setPage={onNextPage}
                                       teamId={teamId}>
                            {tabs[chosenTab].tab === paid &&
                                <TableFilterCell className={`PaymentsTable-status-column`}
                                                 columnName={'Status'}
                                                 optionsList={statuses}
                                                 chosenOption={status}
                                                 setChosenOption={setStatus}/>}
                            {teamId ? null :
                                <TableFilterCell columnName={'Team'}
                                                 optionsList={teams}
                                                 chosenOption={team}
                                                 setChosenOption={setTeam}/>}

                            <TableFilterCell columnName={'Sport'}
                                             optionsList={sports}
                                             chosenOption={sport}
                                             setChosenOption={setSport}/>
                            <TableFilterCell columnName={'Venue'}
                                             optionsList={venues}
                                             chosenOption={venue}
                                             setChosenOption={setVenue}/>
                        </PaymentsTable>}
                </div>
            </div>
        </div>
    )
}
