import React, {useEffect, useState} from "react";
import Calendar from "../../shared/Calendar";
import moment from "moment-timezone";
import axios from "axios";
import {buildQueryParams} from "../util/_build_request_params";
import DropdownFilteringSelector from "../DropdownFilteringSelector";
import {isInclude} from "../payments_table/TableFilterCell";
import {noop} from "lodash";

export default function AddPayment({option, cancelCallback, confirmCallback}) {

    const [itemStatus, setCreateItemStatus] = useState(null);
    const [descriptionStatus, setDescriptionStatus] = useState('');
    const [showCalendar, setShowCalendar] = useState(false);
    const [itemDescription, setItemDescription] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [itemFee, setItemFee] = useState('');
    const [leagues, setLeagues] = useState([])
    const [league, setLeague] = useState(null);
    const [showDropdownList, setShowDropdownList] = useState(false);
    const [filter, setFilter] = useState('');

    useEffect(() => {
        const params = {
            filters: {active: true}
        }
        axios.get(`/api/leagues?${buildQueryParams(params)}`)
            .then(response => setLeagues(response.data.data.map(({id, summary}) => ({id, title: summary}))))
    }, [])

    const onConfirm = () => {
        if (!checkTypedValues())
            return;

        const bodyFormData = new FormData();
        bodyFormData.append('amount', itemFee);
        bodyFormData.append('due_date', dueDate);
        bodyFormData.append('description', itemDescription);
        bodyFormData.append('league_id', league);

        confirmCallback(bodyFormData)
    }

    const checkTypedValues = () => {
        const emptyDescription = itemDescription === '';
        const emptyItemFee = itemFee === '';
        const emptyDueDate = dueDate === '';
        const emptyLeague = league === null;

        if (emptyDescription) {
            setDescriptionStatus('description is not filled');
            setCreateItemStatus('');
        } else if (emptyItemFee || emptyDueDate || emptyLeague) {
            setCreateItemStatus(
                `\'${emptyItemFee ? 'item fee' : emptyDueDate ? 'date due' : 'league'}\' is not filled`
            );
            setDescriptionStatus('');
        } else if (parseFloat(itemFee) < 0.5) {
            setCreateItemStatus('minimum amount is 0.50 $');
            setDescriptionStatus('');
        } else return true;

        return false;
    }

    const onSelectDate = (value) => {
        setDueDate(value.format('YYYY-MM-DD'))
        setShowCalendar(false);
    }

    const onLiClick = (id) => {
        setLeague(id);
        setShowDropdownList(false);
        setFilter('');
    }

    const findTitleById = (id, list) => {
        if (id && list)
            return list.find(({id: itemId}) => itemId === id).title;

        return ''
    }

    const filteredList = leagues.filter(({title}) => filter.length === 0 || isInclude(filter, title))

    return (
        <div className="PaymentModal PaymentModal-width--28 u-element-top u-absolute u-w-45">
            <h1 className="PaymentTab-title">{option}</h1>
            <p className="u-mt-1 u-font-size-13 u-color-red">{descriptionStatus}</p>

            <div className="u-mt-1">
                <p className="PaymentTab-label-default u-mb-1">
                    Item description
                    <span className="u-color-red u-font-size-13"> *</span>
                </p>

                <input className="PaymentTab-reason u-relative"
                       id="add-new-payment-tab-reason-input"
                       onChange={(e) => setItemDescription(e.target.value)}
                       value={itemDescription}/>
            </div>

            <p className="u-font-size-13 u-color-red">{itemStatus}</p>

            <div className="d-flex justify-content-between u-mt-1">
                <div className="PaymentTab-expanded-block">
                    <p className="PaymentTab-label-default u-mb-1">
                        Item fee
                        <span className="u-color-red u-font-size-13"> *</span>
                    </p>
                    <input id="add-new-payment-tab-fee-input"
                           type="number"
                           min="0.5"
                           onChange={(e) => setItemFee(e.target.value)}
                           value={itemFee}/>
                </div>
                <div className="PaymentTab-expanded-block">
                    <p className="PaymentTab-label-default u-mb-1">
                        Date due
                        <span className="u-color-red u-font-size-13"> *</span>
                    </p>
                    <div className="u-relative">
                        <input id="add-new-payment-tab-date-input"
                               type="text"
                               onClick={() => setShowCalendar(prev => !prev)}
                               onChange={(e) => setDueDate(e.target.value)}
                               readOnly={true}
                               value={dueDate.length === 0 ? '' : moment(dueDate).format('DD MMM, YYYY')}/>
                        {showCalendar &&
                            <div className="popup__content">
                                <Calendar onSelect={(value) => onSelectDate(value)}
                                          value={dueDate}/>
                            </div>}
                    </div>
                </div>
            </div>

            <div className="select-wrapper small-dropdown u-mt-1">
                <p className="PaymentTab-label-default u-mb-1">
                    League
                    <span className="u-color-red u-font-size-13"> *</span>
                </p>
                <div className="u-relative">
                    <input id="create-item-league-input"
                           onClick={() => setShowDropdownList(prev => !prev)}
                           value={findTitleById(league, leagues)}
                           onChange={noop}/>
                    {showDropdownList &&
                        <DropdownFilteringSelector filter={filter}
                                                   setFilter={setFilter}
                                                   defaultName={'League'}
                                                   chosenOption={league}
                                                   onLiClick={onLiClick}
                                                   filteredList={filteredList}/>}
                </div>
            </div>

            <div className="d-flex u-mt-2">
                <button
                    className="Button Button--primary PaymentTab-button"
                    onClick={() => onConfirm()}>
                    Confirm
                </button>

                <button className="Button Button--cancel PaymentTab-button"
                        onClick={() => cancelCallback()}>
                    Cancel
                </button>
            </div>
        </div>
    )
}
