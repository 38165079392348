import qs from 'qs'

const clearParams = (params) => {
  for (const key of Object.keys(params)) {
    if (typeof params[key] === 'object' && params[key] !== null) {
      clearParams(params[key])
    } else if (params[key] === "" || params[key] === null) {
      delete params[key];
    }
  }
}

export const buildQueryParams = (params) => {
  clearParams(params);
  return qs.stringify(params, {encode: false});
}
