import qs from 'qs'
import {RETURNING_TEAM_OPTIONS} from "./constants";

const clearParams = params => {
  for (const key of Object.keys(params)) {
    if (typeof params[key] === 'object' && params[key] !== null) {
      clearParams(params[key])
    } else if (params[key] === "" || params[key] === null) {
      delete params[key];
    }
  }
}

export const buildQueryParams = params => {
  clearParams(params);
  return qs.stringify(params, {encode: true});
}

export const geListingOptions = listings => {
  const listingOptions = []
  listings.forEach(l => {
    listingOptions.push({id: l.id, title: `${l.sport} | ${l.day} ${l.division}`})
  })
  return listingOptions
}

export const getPreferredLeague = (listings, listing_id) =>
  listings.find(l => +l.id === +listing_id)

export const getRegistrationParams = (returningId, leagueDetails, captainDetails) => {
  const { registration_listing_id } = leagueDetails
  const { email, first_name, last_name, phone, team_title} = captainDetails

  return {
    registration_listing_id,
    'team_registration[first_name]': first_name,
    'team_registration[last_name]': last_name,
    'team_registration[email]': email,
    'team_registration[phone]': phone.replace(/\+/g, ''),
    'team_registration[returning_team]': returningId === RETURNING_TEAM_OPTIONS.yes,
    'team_registration[title]': team_title,
  }
}
