import PropTypes from 'prop-types';
import React from 'react';
import Button from '../shared/Button';
import Modal from '../shared/Modal';
import TeamsSort from './TeamsSort';
import { create } from '../../lib/API';
import { cleanup, redirect } from '../../lib/utils';

export default class FixturePreview extends React.Component {
  static propTypes = {
    league: PropTypes.object.isRequired,
    wizard: PropTypes.string.isRequired,
    exclude_dates: PropTypes.string,
    from_round: PropTypes.number,
    new_rounds: PropTypes.string,
    fixture: PropTypes.object.isRequired,
    spaces: PropTypes.array,
    teams: PropTypes.array.isRequired,
    teams_original: PropTypes.array.isRequired,
  };

  state = {
    modalShow: false,
    teams: this.props.teams,
  };

  get isChanged() {
    const { teams } = this.state;
    return (this.props.teams_original || []).some(
      (t, i) => t.id !== teams[i].id,
    );
  }

  get teamPositions() {
    const { teams } = this.state;
    const order = {};

    teams.forEach((team, i) => {
      order[team.id] = { position: i + 1 };
    });

    return order;
  }

  handleSort = () => this.setState({ teams: this.props.teams, modalShow: true });

  handleRevert = () => this.setState({ teams: this.props.teams_original });

  handleCancel = () => this.setState({ teams: this.props.teams, modalShow: false });

  handleChangeOrder = (teams) => this.setState({ teams });

  submit = () => {
    const { league, wizard, from_round, new_rounds } = this.props;
    const data = {
      [wizard]: cleanup({
        team_positions: this.teamPositions,
        from_round,
        new_rounds,
      }),
      current_step: 'step1',
    };

    create(
      data,
      `/leagues/${league.id}/${wizard}/validate_step`,
      this.afterSubmit,
      this.afterSubmit,
    );
  };

  afterSubmit = () => {
    const {
      league,
      wizard,
      from_round,
      new_rounds,
      exclude_dates,
    } = this.props;
    const step = wizard === 'extend_week_wizard' ? 2 : 3;
    const positions = this.teamPositions;
    const teamsStr = Object.keys(positions)
      .map(
        (k) => `${wizard}[team_positions][${k}][position]=${positions[k].position}`,
      )
      .join('&');

    this.setState({ modalShow: false });
    redirect(
      `/leagues/${league.id}/${wizard}/step${step}?${teamsStr}${
        new_rounds ? `&${wizard}[new_rounds]=${new_rounds}` : ''
      }${
        from_round ? `&${wizard}[from_round]=${from_round}` : ''
      }&${wizard}[exclude_dates]=${exclude_dates}`,
    );
  };

  render() {
    const { modalShow, teams } = this.state;

    return (
      <div className="Card u-mb-2">
        <div className="Card-content u-pt-2 u-pb-2 u-relative d-flex justify-content-flex-end">
          <div className="u-weight-500 u-element-center u-absolute">
            Fixture preview
          </div>
          <Button
            title="Change teams order"
            id="change_teams_order_button"
            onClick={this.handleSort}
          />
          <Modal show={modalShow} id="change_teams_order_modal">
            <span className="modal-window__header">
              Change Teams Order
            </span>
            <div className="modal-window__hint">
              Drag and drop the team title to change the order
            </div>
            {this.isChanged && (
              <div
                className="u-pointer u-font-size-14 u-color-dodger-blue"
                onClick={this.handleRevert}
              >
                Revert to original order
              </div>
            )}
            <br />
            <TeamsSort
              teams={teams.map((team) => ({
                id: team.id,
                text: team.title,
              }))}
              onChange={this.handleChangeOrder}
            />
            <br />
            <div className="d-flex">
              <Button
                mod="Button--primary u-mr-1"
                title="Refresh"
                id="change_teams_button_refresh"
                onClick={this.submit}
              />
              <Button
                mod="Button--cancel"
                title="Cancel"
                id="change_teams_button_cancel"
                onClick={this.handleCancel}
              />
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}
