import PropTypes from 'prop-types';
import React from 'react';
import axios from 'axios';
import { Col, Row } from '../shared/Grid';
import Team from './Team';

export default class TeamsList extends React.Component {
  static propTypes = {
    enrolments: PropTypes.array.isRequired,
    edit_url: PropTypes.string.isRequired,
    league_id: PropTypes.number.isRequired,
  };

  state = {
    currencySymbol: '',
  };

  componentDidMount() {
    return axios
      .get('/api/settings/config')
      .then((response) => {
        this.setState({
          currencySymbol: response.data.currency_symbol,
        });
      })
      .catch(() => {
        M.toast({
          html: 'Failed to receive configuration',
          classes: 'u-bg-red',
        });
      });
  }

  render() {
    const { enrolments, edit_url, league_id } = this.props;
    const { currencySymbol } = this.state;

    return (
      <Row classMod="u-mb-2">
        <Col l="12">
          <div className="d-flex u-pb-1">
            <span className="u-weight-500 u-mr-1">Teams</span>
            <a
              href={edit_url}
              className="Button Button--small u-font-size-13"
            >
              Add
            </a>
          </div>
          <ul className="league__teams-list">
            {enrolments.map((enrolment, index) => (
              <Team
                key={index}
                {...enrolment}
                currencySymbol={currencySymbol}
                leagueId={league_id}
              />
            ))}
          </ul>
        </Col>
      </Row>
    );
  }
}
