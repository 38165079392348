import React from 'react'
import OverallWindowPart from "./OverallWindowPart";

export default function VoidFeeMarkAsPaid({payment, option, confirmCallback, cancelCallback}) {

    return (
        <div className="PaymentModal PaymentModal-width--28 u-element-center u-fixed">
            <OverallWindowPart payment={payment} option={option}/>
            <div className="d-flex">
                <button className="Button Button--primary PaymentTab-button"
                        onClick={() => confirmCallback(payment)}>
                    Confirm
                </button>
                <button className="Button Button--cancel PaymentTab-button"
                        onClick={() => cancelCallback()}>
                    Cancel
                </button>
            </div>
        </div>
    )
}
