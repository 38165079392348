import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import ReactTooltip from 'react-tooltip';
import { Col, Row } from '../shared/Grid';
import Button from '../shared/Button';
import { teamDTO } from '../../lib/DTO';
import LeaguesList from './LeaguesList';
import DropdownPlayersList from './DropdownPlayersList';
import TeamTitle from './TeamTitle';
import {handleResponseErrorFormat} from '../../lib/utils';
import PlayersList from './PlayersList';
import { moneyFormatterZeros } from '../../lib/utils';

export default function Details({ captain, team, players, togglePage, balance, isBeDeleted }) {
  const [title, setTitle] = useState(team.title);
  const [errors, setErrors] = useState({});
  const [isCaptainEditMode, setIsCaptainEditMode] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(captain);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const buttonName = `Save${team ? '' : ' changes'}`;

  const handleSubmit = () => {
    setIsSubmitted(true);
    const data = {
      team: teamDTO({ title, user_id: selectedPlayer.id }),
    };

    return team.id ? handleUpdate(data) : handleCreate(data);
  };

  const returnBack = () => {
    location.replace('/teams');
  };

  const handleUpdate = (data) =>
    axios
      .put(`/api/teams/${team.id}`, data)
      .catch((error) => {
        setIsSubmitted(false);
        setErrors(handleResponseErrorFormat(error));
      });

  const handleCreate = (data) =>
    axios
      .post('/api/teams', data)
      .then(() => {
        returnBack();
      })
      .catch((error) => {
        setIsSubmitted(false);
        setErrors(handleResponseErrorFormat(error));
      });

  const handleDelete = () =>
    axios
      .delete(`/api/teams/${team.id}`)
      .then(() => {
        returnBack();
      })
      .catch((error) => {
        setErrors(handleResponseErrorFormat(error));
      });

  const onCaptainSelect = (player) => {
    setSelectedPlayer(player);
    setIsCaptainEditMode(false);
  };

  const onPopupClose = () => {
    setIsCaptainEditMode(false);
  }

  const onTapToCopy = (e) => {
    const tmp = `<input class="jsTemp d-none1" value="${$.trim(
      e.target.dataset.full,
    )}">`;
    const defaultText = $('.jsTapText').text();

    $('body').append(tmp);
    $('.jsTapWrapper').addClass('u-noEvents');
    $('.jsTemp').select();
    document.execCommand('copy');
    $('.jsTapText').text('Copied');
    $('.jsTemp').remove();

    setTimeout(() => {
      $('.jsTapText').text(defaultText);
      $('.jsTapWrapper').removeClass('u-noEvents');
    }, 3000);
  };

  useEffect(() => {
    handleSubmit()
  }, [title, selectedPlayer])

  return (
    <React.Fragment>
      <div className="row u-pb-3">
        <div className="col-md-9">
          <TeamTitle value={team.title} onChange={setTitle} submit={handleSubmit}/>
        </div>

        <div className="col-md-3 d-flex justify-content-end">
          <span className="Button Button--link Button--medium Button--tapSmall u-font-size-13 jsTapWrapper"
                data-full={team.code}
                onClick={onTapToCopy}>
            <span className="u-color-mine-shaft u-noEvents jsTapText">
              {team.code}
            </span>
            <span className="Button-iconRight u-noEvents">
              <i className="far fa-clone u-noEvents" />
            </span>
          </span>
        </div>
      </div>
      <div className="card">
        <div className="table__wrapper">
          <Row classMod="u-mb-2">
            <Col l="7">
              <div className="table__head_wrapper u-weight-500 u-font-size-13">
                Captain
              </div>

              <div className="u-relative">
                <span className="u-font-size-13 u-pr-2">
                  {selectedPlayer.title}
                </span>
                {isCaptainEditMode
                ? <span className="table__action">
                    <i className="u-font-size-13 far fa-check"
                      aria-label="save team name"/>
                  </span>
                : <span className="table__action">
                    <i id="user_id"
                       className="u-font-size-13 far fa-pen"
                       onClick={() => setIsCaptainEditMode(true)}/>
                  </span>}
              </div>

              {isCaptainEditMode &&
                <DropdownPlayersList captain={captain}
                                     items={players}
                                     onSelect={onCaptainSelect}
                                     onClose={onPopupClose}/>}

              <LeaguesList teamId={team.id} />
            </Col>

            <Col l="5">
              <div className="table__head_wrapper">
                <div className="d-flex flex-column align-items-flex-end">
                  <div className="u-font-size-13 u-weight-500">
                    Overdue:
                  </div>

                  <div className="u-font-size-24 u-weight-800 u-pb-1">
                     ${moneyFormatterZeros(balance)}
                  </div>

                  <Button mod="Button Button--link Button--medium u-font-size-13"
                          title="Financial Details"
                          id="financial-details-btn"
                          onClick={togglePage}/>
                </div>
              </div>
            </Col>
          </Row>

          <PlayersList teamId={team.id}/>

          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <Button mod="Button--cancel"
                      title="Cancel"
                      id="cancel-btn"
                      onClick={returnBack}/>
            </div>

            {!isBeDeleted &&
              <ReactTooltip id="tooltipCannotDeleteTeam" effect="solid">
                <span> Team cannot be deleted as it is connected to a league</span>
              </ReactTooltip>}
            <div data-tip data-for="tooltipCannotDeleteTeam">
              <Button mod="Button Button--redBg Button--medium u-font-size-13"
                      title="Delete team"
                      id="delete-team-btn"
                      disabled={!isBeDeleted && !!team.id}
                      onClick={handleDelete}/>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

Details.propTypes = {
  captain: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
  players: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  togglePage: PropTypes.func.isRequired,
  balance: PropTypes.string.isRequired,
  isBeDeleted: PropTypes.bool,
};
