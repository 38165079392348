import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import axios from 'axios';
import AttendantsModal from 'components/ScheduleForm/AttendantsModal';
import AttendanceIcon from 'components/ScheduleForm/AttendanceIcon';
import {toast} from 'lib/utils';
import MatchPaymentTab from "./match_payment/MatchPaymentTab";
import {moneyFormatterZeros} from '../../lib/utils';

export default class MatchItemTeam extends React.Component {
  static propTypes = {
    matchItem: PropTypes.shape({
      id: PropTypes.number.isRequired,
      start_date: PropTypes.string.isRequired,
      suffix: PropTypes.string.isRequired,
      score: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      fee: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      paid: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      team_balance: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]).isRequired,
      team_title: PropTypes.string.isRequired,
      team: PropTypes.object,
      team_fee_overdue: PropTypes.bool.isRequired,
      league: PropTypes.object,
      venue: PropTypes.object,
      attendants: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          first_name: PropTypes.string.isRequired,
          last_name: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }).isRequired,
    isExtendedControlsVisible: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isScoreDisabled: PropTypes.bool.isRequired,
    isPaidDisabled: PropTypes.bool.isRequired,
    isScoreDashed: PropTypes.bool,
    updateMatchItem: PropTypes.func.isRequired,
    addOperation: PropTypes.func.isRequired,
    currencySymbol: PropTypes.string.isRequired,
    isAttendantsDisabled: PropTypes.bool,
    team_overdues: PropTypes.bool,
    updateMatchPayment: PropTypes.func.isRequired

  };

  static defaultProps = {
    isScoreDashed: false,
    isAttendantsDisabled: false,
  };

  state = {
    score: this.props.matchItem.score,
    isOperationDialogOpened: false,
    showAttendsModal: false,
    players: [],
    loadingPlayers: false,
    savingPlayers: false,
    attendants: this.props.matchItem.attendants.length,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.matchItem.score !== this.props.matchItem.score) {
      this.setState({ score: this.props.matchItem.score });
    }

    if (prevProps.matchItem.attendants !== this.props.matchItem.attendants) {
      this.setState({ attendants: this.props.matchItem.attendants.length})
    }
  }

  onInputChanged = (e) => {
    const { name, value } = e.target;
    const parsedValue = isNaN(parseFloat(value))
      ? ''
      : parseFloat(value);

    if (parsedValue < 0) return;

    this.setState({ [name]: parsedValue });
  };

  toggleOperationModal = () => {
      this.setState(({isOperationDialogOpened}) => ({
         isOperationDialogOpened: !isOperationDialogOpened
      }));
  };

  handleOpenAttendantsModal = () => {
    const { matchItem } = this.props;
    this.setState({ loadingPlayers: true });

    axios
      .get(
        `/api/teams/${matchItem.team.id}/match_lineups/${matchItem.id}/edit`,
      )
      .then(({ data: players } = { data: [] }) => {
        this.setState({
          players,
          showAttendsModal: true,
          loadingPlayers: false,
        });
      })
      .catch(() => {
        this.setState({ loadingPlayers: false });
      });
  };

  toggleAttendantsModal = () => {
    this.setState(({ showAttendsModal }) => ({
      showAttendsModal: !showAttendsModal,
    }));
  };

  handleSaveAttendantsModal = (players) => {
    const { matchItem } = this.props;

    const reducer = (acc, item) => {
      if (item.played) {
        acc.push(item.user_id);
      }
      return acc;
    };

    const items = players.reduce(reducer, []);

    this.setState({ savingPlayers: true });

    axios
      .put(
        `/api/teams/${matchItem.team.id}/match_lineups/${matchItem.id}`,
        { lineup_items: items },
      )
      .then(() => {
        const attendants = players.reduce((acc, p) => {
          return p.played ? ++acc : acc;
        }, 0);
        this.setState(
          {
            players,
            showAttendsModal: false,
            savingPlayers: false,
            attendants,
          },
          () => {
            toast('Lineup successfully added', 'success');
          },
        );
      })
      .catch(() => {
        this.setState({ savingPlayers: false });
        toast('Something went wrong. Please try again.', 'fail');
      });
  };

  _getScoreOutputValue = (value) => {
    let score = value;
    if (value === null || value === undefined) score = '';
    if (this.props.isScoreDashed) score = '-';

    return score;
  };

  setFeeIsGreen(fee, paid) {
    const feeNum = parseFloat(fee);
    const paidNum = parseFloat(paid);
  
    return ((!isNaN(paidNum) && paidNum >= feeNum) || (isNaN(paidNum) && feeNum === 0));
  }

  setFeeIsOrange(fee, paid) {
    const feeNum = parseFloat(fee);
    const paidNum = parseFloat(paid);
    const debtNum = feeNum - paidNum;
  
    return (fee > 0 && (debtNum > 0 && paidNum > 0));
  }

  calcDebt(fee, paid) {
    const feeNum = parseFloat(fee);
    const paidNum = parseFloat(paid);
    const debtNum = feeNum - paidNum;
  
    return debtNum > 0 ? moneyFormatterZeros(debtNum) : moneyFormatterZeros(feeNum);
  }

  render() {
    const {
      isExtendedControlsVisible,
      isScoreDisabled,
      isPaidDisabled,
      currencySymbol,
      isAttendantsDisabled,
      isAdmin
    } = this.props;

    const {
      id,
      suffix,
      fee,
      team_balance,
      team_title,
      team,
      team_overdues,
      paid,
      team_fee_overdue
    } = this.props.matchItem;

    const {
      isOperationDialogOpened,
      showAttendsModal,
      players,
      loadingPlayers,
      savingPlayers,
      attendants,
    } = this.state;

    const score = this._getScoreOutputValue(this.state.score);
    const isTeam = !!(team && team.id);

    const feeClass = {
      team__fee: true,
      'u-color-emerald u-weight-700': this.setFeeIsGreen(fee, paid),
      'u-color-red': !this.setFeeIsGreen(fee, paid) && team_fee_overdue && fee > 0,
      'u-color-pumpkin u-weight-700': !this.setFeeIsGreen(fee, paid) && this.setFeeIsOrange(fee, paid) 
    };

    const teamBalanceClass = team_overdues && fee > 0 ? 'u-color-red' : ''

    const isHighlighted = players.some(({ played }) => played);

    return (
      <React.Fragment>
        <td className={`schedule__team-${suffix}-col team-${suffix}-title`}>
          <div className="schedule__team-title">
            {isExtendedControlsVisible && isTeam
              ? <a href={`/teams/${team.id}`} className="nav-link">
                  {team_title}
                </a>
              : team_title }
          </div>
        </td>

        {!isAttendantsDisabled &&
          <td>
            <AttendanceIcon loading={loadingPlayers}
                            onClick={this.handleOpenAttendantsModal}
                            isHighlighted={isHighlighted}
                            attendance={attendants} />
            {showAttendsModal &&
              <AttendantsModal onCancel={this.toggleAttendantsModal}
                               onSave={this.handleSaveAttendantsModal}
                               teamTitle={team.title}
                               players={players}
                               saving={savingPlayers} /> }
          </td> }

        <td className="text-center u-p-0">
          <input className="browser-default schedule__input-field schedule__input-field--score"
                 type="number"
                 name="score"
                 value={score}
                 onChange={this.onInputChanged}
                 onBlur={this.props.updateMatchItem}
                 key={`schedule_matches_${id}_score_${suffix}`}
                 id={`schedule_matches_${id}_score_${suffix}`}
                 disabled={isScoreDisabled}
                 data-results-sync-required />
        </td>

        <td className={`schedule__team-${suffix}-col text-center team-${suffix}-balance`}>
          <span className={teamBalanceClass}>
            {currencySymbol}
            {isTeam ? moneyFormatterZeros(team_balance) : 0}
          </span>
        </td>

        <td className={`schedule__team-${suffix}-col text-center team-${suffix}-fee`}>
          <span className={classNames(feeClass)}>
            {currencySymbol}
            {isTeam ? this.calcDebt(fee, paid) : 0}
          </span>
        </td>

        <td className="text-center u-p-0 ">
            <i className="far fa-2x fa-usd-circle team-payment-usd-circle u-opacity-5"
               onClick={() => this.toggleOperationModal()}/>
            {isOperationDialogOpened &&
            <MatchPaymentTab teamId={team.id}
                             matchId={id}
                             team={team_title}
                             cancelCallback={this.toggleOperationModal}
                             updateMatchParams={this.props.updateMatchPayment(suffix)}
                             isAdmin={isAdmin} />}

        </td>
      </React.Fragment>
    );
  }
}
