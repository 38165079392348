const addTeamRegistration = 'Add team registration';
const editTeamRegistration = 'Edit team registration';
const transferToListing = 'Transfer to listing';
const transferToLeague = 'Transfer to league';
const closeListing = 'Close listing';

const processedTableOption = {
  teamList: 'Team List',
  waitingList: 'Waiting list'
}

const returningTeamOptions = {
  yes: 'Yes',
  no: 'No'
};

const returningTeamRadioButtons = [
  {title: 'Yes', id: returningTeamOptions.yes},
  {title: 'No', id: returningTeamOptions.no}
];

const teamTableStatus = {
  new: 'new',
  all: 'all',
  waiting: 'waiting'
};

const registrationFormStatus = {
  new: 'new',
  edit: 'edit'
};

export {
  addTeamRegistration,
  editTeamRegistration,
  transferToListing,
  transferToLeague,
  closeListing,
  processedTableOption,
  returningTeamOptions,
  returningTeamRadioButtons,
  teamTableStatus,
  registrationFormStatus
}
