import React from "react";

export default function ManualPayoutsTable({ fromDate, toDate, token}) {

    return(
        <div className="input-field col s12">
            <form className="col s12 u-p-0" id="financial-export" action="/settings/financials_export" acceptCharset="UTF-8" method="post">
                <input type="hidden" name="authenticity_token" value={token} />
                <input type="hidden" name="from_date" value={fromDate ? fromDate : ''}/>
                <input type="hidden" name="to_date" value={toDate ? toDate : ''}/>
                {(fromDate && toDate) ? 
                <input 
                    type="submit" 
                    name="commit" 
                    value="Download csv" 
                    className="Button Button--primary" />
                : null}
            </form>
        </div>
    )
}