import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import Button from '../shared/Button';
import { create, destroy } from '../../lib/API';
import CreateOperation from './CreateOperation';
import { operationDTO } from '../../lib/DTO';
import { Row, Col } from '../shared/Grid';

export default class Finances extends React.Component {
  static propTypes = {
    team: PropTypes.object.isRequired,
    operations: PropTypes.array.isRequired,
    balance: PropTypes.string.isRequired,
    user_id: PropTypes.number.isRequired,
    togglePage: PropTypes.func.isRequired,
    currencySymbol: PropTypes.string.isRequired,
  };

  state = {
    operations: this.props.operations,
    balance: this.props.balance,
    createActive: false,
    isOperationCommited: false,
  };

  deleteOperation = (id) => {
    const { team } = this.props;

    destroy({}, `/teams/${team.id}/operations/${id}`, (result) => this.afterDelete(result),
    );
  };

  afterDelete = ({ id, balance }) => {
    const { operations } = this.state;
    this.setState({
      balance,
      operations: operations.filter((op) => op.id !== id),
      isOperationCommited: true,
    });
  };

  addOperation = (data) => {
    const { team, user_id } = this.props;

    create(
      operationDTO({ ...data, owner_id: team.id }),
      `/teams/${team.id}/operations`,
      (result) => this.afterAdd(result),
    );
  };

  afterAdd = ({ operation, balance }) => {
    const { operations } = this.state;

    this.setState({
      createActive: false,
      balance,
      operations: [...operations, operation],
      isOperationCommited: true,
    });
  };

  goBack = () => {
    this.state.isOperationCommited
      ? location.replace(`/teams/${this.props.team.id}`)
      : this.props.togglePage();
  };

  render() {
    const { currencySymbol } = this.props;
    const { balance, operations, createActive } = this.state;
    const { date } = this.props;

    return (
      <div className="card">
        <div className="table__wrapper">
          <div className="table__head_wrapper">
            <Row>
              <Col l="7">
                <div className="d-flex flex-column align-items-flex-start">
                  <div className="u-font-size-13 u-weight-500">
                    Balance:
                  </div>

                  <div className="u-font-size-24 u-weight-800 u-pb-1">
                    {balance}
                  </div>
                  {createActive ? (
                    <CreateOperation
                      date={date}
                      onCancel={() => this.setState({ createActive: false })}
                      onCreate={this.addOperation}
                      currencySymbol={currencySymbol}
                    />
                  ) : (
                    <Button
                      mod="Button Button--link Button--medium u-font-size-13"
                      title="Adjust"
                      id="financial-adjust-btn"
                      onClick={() => this.setState({ createActive: !createActive })}
                    />
                  )}
                </div>
              </Col>

              <Col l="5">
                <div className="d-flex flex-column align-items-flex-end">
                  <Button
                    mod="Button Button--link Button--medium u-font-size-13"
                    title="Back to profile"
                    id="financial-back-to-profile-btn"
                    onClick={this.goBack}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <Row classMod="u-mb-2">
            <Col l="12">
              <table className="table bordered teams-finances u-w-100">
                <thead>
                  <tr>
                    <th className="col--transaction">
                      <span className="table__head_wrapper">
                        Transaction
                      </span>
                    </th>
                    <th className="col--date">
                      <span className="table__head_wrapper">
                        Date
                      </span>
                    </th>
                    <th className="col--description">
                      <span className="table__head_wrapper">
                        Description
                      </span>
                    </th>
                    <th className="col--delete" />
                  </tr>
                </thead>
                <tbody>
                  {operations
                    .sort((o1, o2) => moment(o2.date) - moment(o1.date))
                    .map((op, i) => (
                      <tr key={i} className="table__row_padded">
                        <td>
                          {op.op_type === 'deduction' ? '-' : '+'}{' '}
                          {currencySymbol}
                          {op.amount}
                        </td>
                        <td>
                          {moment(op.date).format('MMMM D, YYYY')}
                        </td>
                        <td>{op.description}</td>
                        <td className="d-flex justify-content-flex-end align-items-center">
                          <span className="table__action">
                            <i
                              id="del-operation"
                              className="far fa-times u-font-size-17 u-color-azure-radiance"
                              onClick={() => window.confirm(
                                  'Are you sure you wish to delete this transaction?',
                                ) && this.deleteOperation(op.id)
                              }
                            />
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
