import React, {useEffect, useState} from 'react';
import {redirect} from '../../lib/utils';
import Button from '../shared/Button';
import Background from './Background';
import TeamsTable from './teams_table/TeamsTable';
import WaitingListTable from './teams_table/WaitingListTable';
import TeamFormRegistration from './option_windows/TeamFormRegistration';
import CloseListing from './option_windows/CloseListing';
import TransferModal from './option_windows/TransferModal';
import {
  addTeamRegistration, 
  editTeamRegistration, 
  transferToListing, 
  transferToLeague, 
  closeListing,
  processedTableOption,
  teamTableStatus, 
  registrationFormStatus
} from './util/_options';
import {
  buildQueryParams,
  buildQueryParamsArray,
  buildQueryParamsEncode
} from './util/_build_request_params';
import { 
  handleCloseListing,
  getRegistrations,
  addTeamToListing,
  updateTeamInformation,
  transferToListingRequest,
  transferToAllTeams,
  proccessWaitingTeamRegistration,
  deleteTeam
} from "./util/_api_requests";

          
export default function ManageListing({registration_listing}) {
  const [checkedRegistrations, setCheckedRegistrations] = useState([]);
  const [popupOption, setPopupOption] = useState(null);
  const [popupTeam, setPopupTeam] = useState(null);
  const [chosenTab, setChosenTab] = useState(0);
  const [newTeamRegistrations, setNewTeamRegistrations] = useState([]);
  const [teamListRegistrations, setTeamListRegistrations] = useState([]); 
  const [waitingListRegistrations, setWaitingListRegistrations] = useState([]); 

  const tabs = [
    {tab: processedTableOption.teamList, className: 'Button--switchFirst', list: teamListRegistrations},
    {tab: processedTableOption.waitingList, className: 'Button--switchLast', list: waitingListRegistrations}
  ];

  useEffect(() => {
    getRegistrations(registration_listing.id, setNewTeamRegistrations, setTeamListRegistrations, setWaitingListRegistrations)
  }, [popupOption]);

  const canCloseListing = () => {
    const canClose = !!newTeamRegistrations.length || !!teamListRegistrations.length || !!waitingListRegistrations.length;
    return canClose
  };

  const isChecked = () => {
    return checkedRegistrations.length ? true : false
  };

  const handleTransferToListing = (checkedRegistrations, selectedOption) => {
    const teamRegistrationIds = checkedRegistrations.map(item => item.team_registration_id);
    const params = buildQueryParams({transfer_to_id: selectedOption}) + '&' + buildQueryParamsArray('team_registration_ids', teamRegistrationIds)
    transferToListingRequest(registration_listing.id, params);
  };

  const handleTransferToLeague = (checkedRegistrations, selectedOptionID) => {
    const teamRegistrationIds = checkedRegistrations.map(item => item.team_registration_id);
    const extraTeamIds = checkedRegistrations.map(item => item.extra_team_id);
    const params = buildQueryParamsArray('team_registration_ids', teamRegistrationIds) + '&' + buildQueryParamsArray('extra_team_ids', extraTeamIds);
    redirect(`/leagues/${selectedOptionID}/edit?${params}`);
  };

  const createNewLeague = checkedRegistrations => {
    const teamRegistrationIds = checkedRegistrations.map(item => item.team_registration_id);
    const extraTeamIds = checkedRegistrations.map(item => item.extra_team_id);
    const leagueParams = {
      sport_id: registration_listing.sport_id,
      venue_id: registration_listing.venue_id,
      division_id: registration_listing.division_id,
      day: registration_listing.day
    };

    const params = buildQueryParams(leagueParams) + '&' + buildQueryParamsArray('team_registration_ids', teamRegistrationIds) + '&' + buildQueryParamsArray('extra_team_ids', extraTeamIds);
    redirect(`/leagues/new?${params}`)
  };

  const getLeaguesWithFilters = () => {
    const params = {
      filters: {
        venue_id: registration_listing.venue_id
      }
    };
    return  buildQueryParamsEncode(params)
  };

  const getListingsWithFilters = () => {
    const params = {
      filters: {
        except_listing_id: registration_listing.id
      }
    };
    return  buildQueryParamsEncode(params)
  };

  const onOptionClick = (option, team) => {
    setPopupOption(option);
    setPopupTeam(team)
  };

  const disablePopup = () => {
    setPopupTeam(null);
    setPopupOption(null);
  };

  const getTabListLength = index => {
    return tabs[index].list.length ? `(${tabs[index].list.length})` : '(0)'
  };

  const getNecessaryPopup = (option, team) => {
    switch (option) {
        case addTeamRegistration:
            return <TeamFormRegistration team={team}
                                         title="Add team"
                                         cancelCallback={disablePopup}
                                         confirmCallback={params => addTeamToListing(registration_listing.id, params, disablePopup)}/>
        case editTeamRegistration:
            return <TeamFormRegistration team={team}
                                         title="Edit team"
                                         cancelCallback={disablePopup}
                                         confirmCallback={(params, teamID) => updateTeamInformation(registration_listing.id, params, teamID, disablePopup)}/>
        case transferToListing:
            return <TransferModal checkedRegistrations={checkedRegistrations}
                                  params={getListingsWithFilters()}
                                  title={'listing'}
                                  cancelCallback={disablePopup}
                                  confirmCallback={(checkedRegistrations, selectedOptionID) => handleTransferToListing(checkedRegistrations, selectedOptionID)}/>
        case transferToLeague:
            return <TransferModal checkedRegistrations={checkedRegistrations}
                                  title={'league'}
                                  params={getLeaguesWithFilters()}
                                  cancelCallback={disablePopup}
                                  confirmCallback={(checkedRegistrations, selectedOptionID) => handleTransferToLeague(checkedRegistrations, selectedOptionID)}/>
        case closeListing:
            return <CloseListing cancelCallback={disablePopup}
                                 confirmCallback={() => handleCloseListing(registration_listing.id)}/>
    }
  };

  const changeTab = index => {
    setChosenTab(index)
  };

  const getNecessaryTable = option => {
    switch(option) {
        case processedTableOption.teamList:
            return  <TeamsTable title=""
                                registrationsList={teamListRegistrations}
                                status="all"
                                setCheckedRegistrations={checked => setCheckedRegistrations(checked)}
                                onOptionClick={(option, team) => onOptionClick(option, team)}
                                onDeleteTeam={id => deleteTeam(registration_listing.id, id, setNewTeamRegistrations, setTeamListRegistrations, setWaitingListRegistrations)}/>
        case processedTableOption.waitingList:
            return <WaitingListTable
                      registrationsList={waitingListRegistrations}
                      setCheckedRegistrations={checked => setCheckedRegistrations(checked)}
                      onOptionClick={(option, team) => onOptionClick(option, team)}
                      onDeleteTeam={id => deleteTeam(registration_listing.id, id, setNewTeamRegistrations, setTeamListRegistrations, setWaitingListRegistrations)}
                      onArrowClick={id => proccessWaitingTeamRegistration(registration_listing.id, id, setNewTeamRegistrations, setTeamListRegistrations, setWaitingListRegistrations)}/>
    }
  };

  return <div style={{ position: 'relative' }}>
      <Background isShow={popupOption && popupTeam}
                  setDisable={disablePopup}
                  backgroundStyle={'Underlayer-dark'}/>

      {(popupOption && popupTeam) && getNecessaryPopup(popupOption, popupTeam)}      

      <div className='d-flex justify-content-end u-mb-30'>
        <Button mod="Button--red Button--medium ManageListing-button--large ManageListing-button--close"
                title="Close listing"
                id="add-payment-item-btn"
                disabled={canCloseListing()}
                onClick={() => onOptionClick(closeListing, 'close')}/>
      </div>

      <div className="row">
          <div className="col-md-7">
            <div className="u-font-size-14 text-bold u-pb-1 u-weight-500">Description</div>
            <div className="col-md-8">
              <div className="row">
                <p className="u-font-size-14 u-mb-2">
                  {registration_listing.description}
                </p>
              </div>
            </div>
          </div>
      </div>

      <div className="d-fullWidth d-flex justify-content-between align-items-end u-mb-3">
        <div className="d-flex u-pt-2">
            <Button mod="Button Button--medium Button--switchFirst ManageListing-button--transfer"
                    title="Transfer to listing"
                    id="transfer-to-listing-btn"
                    disabled={!isChecked()}
                    onClick={() => onOptionClick(transferToListing, checkedRegistrations)}/>
            <Button mod="Button Button--medium Button--switchMiddle ManageListing-button--transfer"
                    title="Transfer to league"
                    id="transfer-to-league-btn"
                    disabled={!isChecked()}
                    onClick={() => onOptionClick(transferToLeague, checkedRegistrations)}/>
            <Button mod="Button  Button--medium Button--switchLast ManageListing-button--transfer"
                    title="Create new league"
                    id="create-new-league-btn"
                    disabled={!isChecked()}
                    onClick={() => createNewLeague(checkedRegistrations)}/>
        </div>

        <div className="d-flex flex-column justify-content-end u-p-0">
          <Button mod="Button Button--medium ManageListing-button--large"
                  title="Add team registration"
                  id="add-team-registration-btn"
                  onClick={() => onOptionClick(addTeamRegistration, registrationFormStatus.new)}/>
        </div>
      </div>

      <div className="card jsLeaguesList u-mb-4">
        <div className="table__wrapper">
          <TeamsTable title="New interest"
                      registrationsList={newTeamRegistrations}
                      status={teamTableStatus.new}
                      setCheckedRegistrations={checked => setCheckedRegistrations(checked)}
                      onOptionClick={(option, team) => onOptionClick(option, team)}
                      onDeleteTeam={id => deleteTeam(registration_listing.id, id, setNewTeamRegistrations, setTeamListRegistrations, setWaitingListRegistrations)}
                      onArrowClick={id => transferToAllTeams(registration_listing.id, id, setNewTeamRegistrations, setTeamListRegistrations, setWaitingListRegistrations)}>
          </TeamsTable>
        </div>
      </div>

      <div className="card jsLeaguesList">
        <div className="row u-m-0 u-pb-1 u-pt-2 u-pl-2" style={{position: 'relative'}}>
          {tabs.map(({tab, className}, index) => {
              const primaryClass = chosenTab === index ? 'Button--primary' : '';
              return <div key={index}
                          className={`Button Button--medium ManageListing-button--tab ${className} ${primaryClass}`}
                          onClick={() => changeTab(index)}>
                      {`${tab} ${getTabListLength(index)}`}
                  </div>})}
        </div>

        <div className="table__wrapper">{getNecessaryTable(tabs[chosenTab].tab)}</div>
      </div>
    </div>
}
