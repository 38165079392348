import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/shared/Modal';
import ToggleCheckbox, {
  LABEL_POSITION,
} from 'components/shared/ToggleCheckbox';

const calculatePlayersCount = (players) => players.reduce((acc, player) => (player.played ? ++acc : acc), 0);

const itemStyle = {
  borderBottom: '1px solid #F1F3F7',
};

const AttendantsModal = ({
  saving,
  teamTitle,
  onCancel,
  onSave,
  players,
}) => {
  const [checkedAttendants, setCheckedAttendants] = useState(players);

  const handleChange = (e) => {
    const { value, checked } = e.target;

    setCheckedAttendants((data) => {
      const newAttendants = [...data];
      const item = newAttendants.find(
        ({ user_id }) => +user_id === +value,
      );
      const newItem = { ...item };
      const index = newAttendants.indexOf(item);

      newItem.played = checked;
      newAttendants.splice(index, 1, newItem);

      return newAttendants;
    });
  };

  const handleSave = () => {
    onSave(checkedAttendants);
  };

  return (
    <Modal
      id="attendance_modal"
      show
      footer={
        <div className="d-flex">
          <button
            disabled={saving}
            className="Button Button--medium Button--primary u-mr-1"
            onClick={handleSave}
          >
            Save
          </button>
          <a
            className="Button Button--medium Button--cancel"
            onClick={onCancel}
          >
            Cancel
          </a>
        </div>
      }
    >
      <div>
        <div
          style={{ ...itemStyle }}
          className="d-flex justify-content-between align-items-center u-pb-1 u-font-size-16"
        >
          <h5 className="u-weight-500">{teamTitle} Lineup</h5>
          <span className="u-color-dodger-blue u-weight-500">
            {calculatePlayersCount(checkedAttendants)} Playing
          </span>
        </div>

        <div className="u-pb-2">
          {checkedAttendants.map(({ user_id, name, played }) => (
            <div
              style={{ ...itemStyle }}
              key={user_id}
              className="u-pb-1 u-pt-1 u-pr-1"
            >
              <ToggleCheckbox
                value={user_id}
                className="justify-content-between u-font-size-14 Checkbox--medium"
                label={name}
                checked={played}
                onChange={handleChange}
                labelPosition={LABEL_POSITION.LEFT}
              />
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

AttendantsModal.propTypes = {
  teamTitle: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  players: PropTypes.arrayOf(
    PropTypes.shape({
      user_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      played: PropTypes.bool.isRequired,
    }),
  ).isRequired,
};

export default AttendantsModal;
