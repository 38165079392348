import React, {useEffect, useState} from 'react';
import {noop} from "lodash";
import {redirect} from '../../lib/utils';
import {getSuggestedMatch, updateTeam} from "./util/_api_requests";

export default function MergeTeam({team_registration, registration_listing_id}) {
  const [suggestedMatch, setSuggestedMatch] = useState(null);
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  console.log('suggestedMatch', suggestedMatch)
  const redirectUrl = `/registration_listings/${registration_listing_id}/team_registrations/`;
  const {email, first_name, id, last_name, phone, title} = team_registration;

  useEffect(() => {
    getSuggestedMatch(registration_listing_id, id, setSuggestedMatch);
  }, [])
  
  const handleTeamAction = () => {
    updateTeam(registration_listing_id, id, selectedTeamId);
  }

  return <React.Fragment>
    <div className="MergeTeam-card card u-mb-4">
      <div className="u-mb-2 u-font-size-17">Registration details</div>
      
      <div className="MergeTeam-details">
        <table className="highlight">
          <thead>
            <tr className="u-text-left u-noBorder u-noPadding u-pb-1">
              <th className="u-color-dodger-blue u-w-60">Team name</th>
              <th className="u-color-dodger-blue u-w-60">Captain</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className="MergeTeam-vertical-align">{title}</td>
              <td>
                <div className="MergeTeam-captain bold u-font-size-14 u-weight-600">{first_name} {last_name}</div>
                <div className="MergeTeam-captain">{email}</div>
                <div>{phone}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="u-mb-2 u-font-size-17">Suggested match</div>

      <table className="highlight u-w-100">
        <thead>
          <tr className="u-text-left u-noBorder u-noPadding">
            <th />
            <th className="u-color-dodger-blue">Team name</th>
            <th className="u-color-dodger-blue">Captain</th>
            <th className="u-color-dodger-blue">League history</th>
            <th className="u-color-dodger-blue">Match category</th>
          </tr>
        </thead>

        {suggestedMatch &&
          <tbody className="MergeTeam-table-body">
            {suggestedMatch?.map(({id, leagues, tags, title, user}, i) =>
              <tr className="MergeTeam-table-row" key={i}>
                <td className="MergeTeam-vertical-align u-pr-2">
                  <input id={id}
                         className="MergeTeam-radio-button browser-default"
                         name="team"
                         type="radio"
                         value={id}
                         checked={id === selectedTeamId}
                         onClick={() => !!selectedTeamId && selectedTeamId === id? setSelectedTeamId(null) : setSelectedTeamId(id)}
                         onChange={noop}/>
                </td>
                <td className="MergeTeam-vertical-align u-w-15 u-pr-2">{title}</td>
                <td className="MergeTeam-vertical-align u-w-20">
                  <div className="MergeTeam-captain bold u-font-size-14 u-weight-600">{user?.title}</div>
                  <div className="MergeTeam-captain">{user?.email}</div>
                  <div>{user?.phone}</div>
                </td>
                <td className="MergeTeam-vertical-align u-color-dodger-blue u-w-45">
                  {leagues &&
                    leagues.map(({league_summary, league_url}, i) =>
                      <a href={league_url} key={i} className="MergeTeam-history">{league_summary}</a>)}
                </td>
                <td className="MergeTeam-vertical-align bold u-font-size-14 u-weight-600 u-w-20">
                  {tags?.slice(0, -1).map((t,i) =>
                    <span key={i}>{`${t}, `}</span>)}
                    <span>{tags[tags.length-1]}</span>
                </td>
              </tr>)}
          </tbody>}
      </table>

      <div className="d-flex u-mt-3">
        <button className="Button Button--primary u-mr-1"
                disabled={selectedTeamId}
                onClick={handleTeamAction}>
            Add as new team
        </button>

        {!!suggestedMatch?.length &&
          <button className="Button Button--primary u-mr-1"
                  disabled={!selectedTeamId}
                  onClick={handleTeamAction}>
              Match existing team
          </button>}

        <button className="Button Button--primary-empty"
                onClick={() => redirect(redirectUrl)}>
            Cancel
        </button>
    </div>
    </div>
  </React.Fragment>
}


