import React from "react";
import Button from "../shared/Button";
import {redirect} from '../../lib/utils';

export default function Success({company}) {
  return <div className="RegisterTeams-success">
    <div className="RegisterTeams-success--image"></div>
    <div className="u-font-size-30 u-mt-3 u-mb-2">Registration was successful</div>
    <div className="u-mb-1">You have successfully registered your team.</div>
    <div className="u-mb-2">{company} will be in contact with you shortly.</div>

    <Button mod="Button--primary"
            title="Return home"
            id="return_home"
            onClick={() => redirect('/')}/>
  </div>
}
