import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Modal from '../shared/ModalClosableOutside';
import MatchItemTeam from './MatchItemTeam';
import FriendlyMatchForm from '../FriendlyMatchForm/Index';
import ScoreStatusDropdown from './ScoreStatusDropdown';
import Spinner from '../shared/Spinner';
import {SCORE_STATUS} from './constants';
import {getErrorTextFromObject} from '../../helpers/utils';

export default class MatchItem extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      id: PropTypes.number.isRequired,
      start_time: PropTypes.string,
      duration: PropTypes.number.isRequired,
      score_a: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      score_b: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      score_status: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      fee_a: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      fee_b: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      paid_a: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      paid_b: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      team_a_attendance: PropTypes.array.isRequired,
      team_b_attendance: PropTypes.array.isRequired,
      team_a_balance: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]).isRequired,
      team_b_balance: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]).isRequired,
      round_title: PropTypes.string,
      league_title: PropTypes.string,
      team_a_title: PropTypes.string,
      team_b_title: PropTypes.string,
      team_a: PropTypes.object.isRequired,
      team_b: PropTypes.object.isRequired,
      errors: PropTypes.object,
      team_a_fee_overdue: PropTypes.bool.isRequired,
      team_b_fee_overdue: PropTypes.bool.isRequired
    }).isRequired,
    isExtendedControlsVisible: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    venueId: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    onMatchUpdate: PropTypes.func.isRequired,
    onMatchDelete: PropTypes.func.isRequired,
    currencySymbol: PropTypes.string.isRequired,
    isAttendantsDisabled: PropTypes.bool,
    onUpdateMatchPayment: PropTypes.func.isRequired
  };

  static defaultProps = {
    isAttendantsDisabled: false,
  };

  state = {
    isEditDialogOpened: false,
    isLoading: false,
  };

  _getMatchTeam = (suffix = 'a') => {
    const { match } = this.props;

    if (!['a', 'b'].includes(suffix) || !match) return {};

    const matchTeam = {
      id: match.id,
      start_date: match.start_date,
      suffix,
      score: match[`score_${suffix}`],
      fee: match[`fee_${suffix}`] || 0,
      paid: match[`paid_${suffix}`] || 0,
      team_balance: match[`team_${suffix}_balance`] || 0,
      team_title: match[`team_${suffix}_title`],
      team: match[`team_${suffix}`] || null,
      league: match.league,
      venue: match.venue,
      attendants: match[`team_${suffix}_attendance`],
      team_overdues: match[`team_${suffix}_overdues`],
      team_fee_overdue: match[`team_${suffix}_fee_overdue`]
    };

    matchTeam.fee = parseFloat(matchTeam.fee).toFixed(1);
    matchTeam.paid = parseFloat(matchTeam.paid).toFixed(1);
    matchTeam.team_balance = parseFloat(
      matchTeam.team_balance,
    ).toFixed(1);

    return matchTeam;
  };

  addOperation = (teamId, opData) => axios
      .post(`/teams/${teamId}/operations.json`, opData)
      .then(() => axios.get(
          `/matches/${this.props.match.id}?date=${this.props.date}.json`,
        ),
      )
      .then((res) => {
        this.props.onMatchUpdate({ match: { ...res.data } });
      })
      .catch((error) => {
        M.toast({
          html: 'Adding new operation failed',
          classes: 'u-bg-red',
        });
      });

  deleteMatchItem = (venueId, matchId) => axios
      .delete(`/venues/${venueId}/matches/${matchId}.json`)
      .then(() => {
        this.props.onMatchDelete(matchId);
      })
      .catch((error) => {
        let message;
        if (error.response.data.message) {
          message = error.response.data.message;
        } else {
          message = 'Failed to delete the match';
        }
        M.toast({
          html: message,
          classes: 'u-bg-red',
        });
      });

  updateMatchItem = (updatedMatchData) => {
    this.setState({ isLoading: true });
    const { date, match, onMatchUpdate } = this.props;
    const {
      match: matchData,
      isNeedCompleteNight,
    } = updatedMatchData;

    // optimistic response
    onMatchUpdate({
      match: { ...match, ...matchData },
      isNeedCompleteNight,
    });

    return axios
      .put(
        `/matches/${match.id}.json`,
        { match: matchData },
        { params: { date } },
      )
      .then((res) => {
        onMatchUpdate({
          match: { ...res.data },
          isNeedCompleteNight,
        });
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        // if we get an error we must revert our optimistic changes
        onMatchUpdate({ match: { ...match }, isNeedCompleteNight });
        M.toast({
          html: `Failed to update a match: <br>${getErrorTextFromObject(
            error,
          )}`,
          classes: 'u-bg-red',
        });
      });
  };

  updateMatchScoreStatus = (score_status) => {
    const updatedMatchData = {
      match: {
        id: this.props.match.id,
        score_status,
      },
      isNeedCompleteNight: true,
    };
    this.updateMatchItem(updatedMatchData);
  };

  updateMatchItemTeam = (suffix, e) => {
    const itemName = `${e.target.name}_${suffix}`;
    const { match } = this.props;

    if (e.target.value === (match[itemName] || '')) {
      return false;
    }

    const updatedMatchData = {
      match: {
        id: this.props.match.id,
        [itemName]: e.target.value,
      },
      isNeedCompleteNight: true,
    };

    if (
      parseFloat(updatedMatchData.match[itemName])
      === parseFloat(this.props.match[itemName])
    )
      return;

    this.updateMatchItem(updatedMatchData);
  };

  updateMatchItemTeamA = (e) => {
    this.updateMatchItemTeam('a', e);
  };

  updateMatchItemTeamB = (e) => {
    this.updateMatchItemTeam('b', e);
  };

  toggleFriendlyMatchModal = () => {
    this.setState({
      isEditDialogOpened: !this.state.isEditDialogOpened,
    });
  };

  updateMatchWithModal = (matchData) => {
    this.props.onMatchUpdate({ match: { ...matchData } });
    this.toggleFriendlyMatchModal();
  };

  updateMatchPayment = (suffix) => (fee, overdues, balance, isPaid) => {
    const updatedParams = {};
    updatedParams[`fee_${suffix}`] = fee;
    updatedParams[`team_${suffix}_overdues`] = overdues;
    updatedParams[`team_${suffix}_balance`] = balance;

    if(isPaid) {
      updatedParams[`paid_${suffix}`] = fee;
    }

    this.props.onUpdateMatchPayment({...this.props.match, ...updatedParams})
  }

  _isTeamToBeConfirmed = (team) => team.team_title === 'TBC' && !team.team;

  _isFriendlyMatch = () => {
    const { match } = this.props;

    return !(match.fixture && !!match.fixture.id);
  };

  render() {
    const {
      match,
      isExtendedControlsVisible,
      isAdmin,
      venueId,
      date,
      currencySymbol,
      isAttendantsDisabled,
    } = this.props;
    const { isEditDialogOpened, isLoading } = this.state;

    const isLeague = match.league && !!match.league.id;

    const matchTeamA = this._getMatchTeam('a');
    const matchTeamB = this._getMatchTeam('b');

    // console.group('match item props');
    // console.log('this', this.props);
    // // console.log('match item fee', this.props.matchItem.fee);
    // // console.log('match item paid', this.props.matchItem.paid);
    // console.groupEnd()

    return (
      <React.Fragment>
        <tr className="schedule__team-spacer">
          <td />
        </tr>
        <tr className="schedule__team-a-row">
          <td rowSpan="3" className="schedule__team-a-col match-start-time">
            {match.start_time}
            <br />
            {match.duration} minutes
          </td>
          <td rowSpan="3" className="schedule__team-a-col space-title">
            {match.space_title}
          </td>
          <td rowSpan="3" className="schedule__team-a-col league-title">
            {isLeague &&
              isExtendedControlsVisible
                ? <a href={`/leagues/${match.league.id}`}>
                    {match.league_title}
                  </a> 
                : match.league_title }

            {this._isFriendlyMatch() &&
              <React.Fragment>
                {isEditDialogOpened &&
                  <Modal onHideModal={this.toggleFriendlyMatchModal}>
                    <FriendlyMatchForm match={match}
                                       venue={match.venue}
                                       onMatchUpdate={this.updateMatchWithModal}
                                       date={date}/>
                  </Modal>}

                <span>Friendly Match</span>

                {isExtendedControlsVisible &&
                  <React.Fragment>
                    <span className="u-ml-1 u-mr-1">|</span>
                    <a href="#"
                       onClick={this.toggleFriendlyMatchModal}
                       className="table__action u-mr-1">
                      <i className="far fa-pen" />
                    </a>

                    <a className="table__action"
                       onClick={(e) =>
                          window.confirm('Are you sure you wish to delete this match?') && this.deleteMatchItem(venueId, match.id)}
                       href="#">
                      <i className="far fa-times u-font-size-17" />
                    </a>
                  </React.Fragment>}
              </React.Fragment>}
          </td>
          <td rowSpan="3"
              className="schedule__team-a-col text-center">
            {match.round_title}
          </td>

          <MatchItemTeam matchItem={matchTeamA}
                        updateMatchItem={this.updateMatchItemTeamA}
                        isExtendedControlsVisible={isExtendedControlsVisible}
                        isAdmin={isAdmin}
                        isScoreDisabled={SCORE_STATUS.isStatusSet(match.score_status) || this._isTeamToBeConfirmed(matchTeamA)}
                        isPaidDisabled={this._isTeamToBeConfirmed(matchTeamA)}
                        isScoreDashed={SCORE_STATUS.isOmited(match.score_status)}
                        addOperation={this.addOperation}
                        currencySymbol={currencySymbol}
                        isAttendantsDisabled={isAttendantsDisabled}
                        updateMatchPayment={this.updateMatchPayment}/>
        </tr>

        <tr>
          <td />
          {!isAttendantsDisabled && <td />}
          <td className="schedule__team-divider">
            {!this._isTeamToBeConfirmed(matchTeamA) && !this._isTeamToBeConfirmed(matchTeamB) &&
              <ScoreStatusDropdown matchScoreStatus={match.score_status}
                                    onScoreStatusUpdate={this.updateMatchScoreStatus}
                                    teamNames={{
                                      teamA: match.team_a_title,
                                      teamB: match.team_b_title,
                                    }} />}
          </td>
          <td colSpan="7" className="text-right">
            <span className="u-relative">
              <Spinner isLoading={isLoading}
                       position={{ top: -10, right: 0 }} />
            </span>
          </td>
        </tr>

        <tr className="schedule__team-b-row">
          <MatchItemTeam matchItem={matchTeamB}
                         updateMatchItem={this.updateMatchItemTeamB}
                         isExtendedControlsVisible={isExtendedControlsVisible}
                         isAdmin={isAdmin}
                         isScoreDisabled={SCORE_STATUS.isStatusSet(match.score_status) || this._isTeamToBeConfirmed(matchTeamB)}
                         isPaidDisabled={this._isTeamToBeConfirmed(matchTeamB)}
                         isScoreDashed={SCORE_STATUS.isOmited(match.score_status)}
                         addOperation={this.addOperation}
                         currencySymbol={currencySymbol}
                         isAttendantsDisabled={isAttendantsDisabled}
                         updateMatchPayment={this.updateMatchPayment} />
        </tr>

        <tr className="schedule__team-spacer schedule__team-spacer--bottom">
          <td />
        </tr>
      </React.Fragment>
    );
  }
}
