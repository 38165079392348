import PropTypes from 'prop-types';
import React, { createRef} from "react";

export default class SingleFileUploader extends React.Component {
  constructor(props) {
    super(props);
    this.fileInputRef = createRef();
  }

  componentDidMount() {
    if (this.props.file) {
      this.loadFile();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filePath !== this.props.filePath) {
      this.loadFile();
    }
  }

  async loadFile() {
    const { file: filePath } = this.props;

    try {
      const response = await fetch(filePath, { mode: 'no-cors' });
      const fileBlob = await response.blob();
      const fileName = filePath.split('/').pop();
      const myFile = new File([fileBlob], fileName, {
        type: fileBlob.type,
      });

      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(myFile);

      if (this.fileInputRef.current) {
        this.fileInputRef.current.files = dataTransfer.files;
        this.setState({ selectedFile: myFile });
      }
    } catch (error) {
      console.error('Error loading file:', error);
    }
  }

  state = {
    selectedFile: null
  }

  static propTypes = {
    file: PropTypes.string,
    url: PropTypes.string.isRequired,
    updateFunc: PropTypes.func,
    id: PropTypes.string,
    label: PropTypes.string,
  }

  handleFileChange = (e) => {
    if (e.target.files) {
      const file = e.target.files[0]

      if (file) {
        this.setState({ selectedFile: file });
        this.props.updateFunc(file)
      }
    }
  }

  render() {
    const {selectedFile, status} = this.state;
    return (
      <div className='file-uploader'>
        <label htmlFor={this.props.id}>{this.props.label} </label>
        <span id="file-chosen">{selectedFile?.name || 'No file chosen'}</span>
        <input
          id={this.props.id}
          type="file"
          accept="image/svg+xml"
          onChange={this.handleFileChange}
          ref={this.fileInputRef}
          hidden
        />
      </div>
    )
  };
};
