import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types"
import Background from "./Background";
import moment from "moment";
import Spinner from "../shared/Spinner";
import TableFilterCell from "./listing_table/TableFilterCell";
import {redirect} from '../../lib/utils';
import {getListingWithFilters} from "./util/_payments_tabs_api";
import {buildQueryParams} from './util/_build_request_params';

export default function Registrations({
  venues,
  sports,
  days,
  divisions,
  new_url
}) {
  const [isLoading, setLoading] = useState(true);
  const [listingsList, setListingsList] = useState([]);
  const [venue, setVenue] = useState(null);
  const [sport, setSport] = useState(null);
  const [day, setDay] = useState('');
  const [division, setDivision] = useState(null);

  useEffect(() => {
    onChangeFilters();
  }, [sport, venue, day, division]);

  const onChangeFilters = () => {
    setLoading(true);
    apiRequestFunc();
  };

  const apiRequestFunc = () => {
    getListingWithFilters(`/registration_listings?${buildParams()}`)
      .then(({data: {data}}) => {
        setListingsList(data);
        setLoading(false)
      })
  };

  const buildParams = () => {
    const params = {
      filters: {
        sport_id: sport,
        venue_id: venue,
        day: day,
        division_id: division
      }
    }

    return buildQueryParams(params);
  };

  const extraClass = (teams, capacity) => {
    if (teams/capacity == 1) {
      return 'Registrations-capacity-full'
    } else if (teams/capacity > 1) {
      return 'Registrations-capacity-fullout'
    } else {
      return ''
    }
  };

  return <div>
    <Background backgroundStyle={'Underlayer-dark'}/>
      <div className="d-flex justify-content-between">
        <h1 className="u-font-size-30 u-m-0 u-pb-3">Registrations</h1>

        <button className="Button Button--primary Button--medium Registrations-button--big"
                onClick={() => redirect(new_url)}>
          Create listings
        </button> 
      </div>

      <div className="card jsLeaguesList">
        <div className="table__wrapper">
          <Spinner isLoading={isLoading}
                    position={{right: '50%', bottom: '25%'}}/>
          {!isLoading &&
            <React.Fragment>
              <Background backgroundStyle={'Underlayer-dark'}/>

              <table className="bordered highlight u-w-100">
                <thead>
                  <tr className="u-text-left u-noBorder">
                    <th className='u-pt-2 u-pb-2'/>
                    <TableFilterCell columnName={'Venue'}
                                     optionsList={venues}
                                     chosenOption={venue}
                                     setChosenOption={setVenue}/>
                    <TableFilterCell columnName={'Sport'}
                                     optionsList={sports}
                                     chosenOption={sport}
                                     setChosenOption={setSport}/>
                    <TableFilterCell columnName={'Day'}
                                     optionsList={days}
                                     chosenOption={day}
                                     setChosenOption={setDay}/>
                    <TableFilterCell columnName={'Division'}
                                     optionsList={divisions}
                                     chosenOption={division}
                                     setChosenOption={setDivision}/>
                    <th className='u-pt-2 u-pb-2'>Start date</th>
                    <th className='u-pt-2 u-pb-2'>Duration</th>
                    <th className='u-pt-2 u-pb-2'>Capacity</th>
                    <th className='u-pt-2 u-pb-2'>View</th>
                    <th className='u-pt-2 u-pb-2'/>
                    <th className='u-pt-2 u-pb-2'/>
                  </tr>
                </thead>
                <tbody>

                  {!listingsList.length &&
                    <tr>
                      <td colSpan={10}>
                        <h4 className="Registrations-no-listings">No active listings</h4>
                      </td>
                    </tr>}

                  {!!listingsList.length &&
                    listingsList.map(listing => {
                      const {
                          id,
                          sport,
                          venue,
                          day,
                          division,
                          start_date,
                          duration,
                          capacity,
                          registered_teams,
                          new_registrations,
                          public_listing,
                          edit_url,
                          manage_url
                      } = listing;
                      return <tr className="collection-item"
                                  key={id}>
                        <td className='Registrations-icon-bell'>
                          {new_registrations > 0 &&
                            <i number-of-notif={new_registrations} className="far fa-bell"/>}
                        </td>
                        <td className='Registrations-minWidth--100'>{venue}</td>
                        <td className='Registrations-minWidth--100'>{sport}</td>
                        <td className='Registrations-minWidth--90'>{day}</td>
                        <td className='Registrations-minWidth--70'>{division}</td>
                        <td>{moment(start_date).format('LL')}</td>
                        <td>{`${duration} weeks`}</td>
                        <td className={extraClass(registered_teams, capacity)}>
                            {`${registered_teams} / ${capacity}`}
                        </td>
                        <td>
                          <div class={"u-pt-1 text-center u-color-" + (public_listing ? 'mine-shaft' : 'red') + " league-public__icon league-public__toggle u-rotate-53 far fa-wifi fa-lg"}></div>
                        </td>
                        <td className="Registrations-textButton">
                            <a href={manage_url}> Manage listing</a>
                        </td>
                        <td>
                            <a href={edit_url} className='table__action'>
                                <i className="far fa-pen"/>
                            </a> 
                        </td>
                      </tr>
                  })}
                </tbody>
              </table>      
            </React.Fragment>}
        </div>
      </div>
    </div>
}

Registrations.propTypes = {
  sports: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired
    })
  ).isRequired,
  venues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired
    })
  ).isRequired,
  divisions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired
    })
  ).isRequired, 
  days: PropTypes.arrayOf(PropTypes.string).isRequired,
  new_url: PropTypes.string.isRequired
}
