const parseMatchFeeFromDescription = (description) => {
    const index = description.indexOf('|');
    return index === -1 ? description : description.substring(0, index).trim();
}

const parseCompetitionFromDescription = (description) => {
    const index = description.lastIndexOf('|');
    return index === -1 ? '' : description.substring(index + 1).trim();
}

const parseOnceOfFeeDescription = (description) => {
    const index = description.indexOf('|');
    return index === -1 ? '' : description.slice(0, index).trim();
}

export {
    parseMatchFeeFromDescription,
    parseCompetitionFromDescription,
    parseOnceOfFeeDescription
}
