import React, {useState} from "react";
import {noop} from "lodash";
import Calendar from "../shared/Calendar";
import moment from "moment-timezone";
import Background from "../shared/Background";

const DateFilteringBlock = ({firstDate, setFirstDate, lastDate, setLastDate, additionalClass}) => {

    const [showFirstCalendar, setShowFirstCalendar] = useState(false);
    const [showSecondCalendar, setShowSecondCalendar] = useState(false);

    const formatDate = (date) => {
        return date.length === 0 ? '' : moment(date).format('DD MMM, YYYY');
    }

    const disableAll = () => {
        setShowFirstCalendar(false);
        setShowSecondCalendar(false);
    }
    

    return (
        <React.Fragment>
            <Background setDisable={() => disableAll()}
                        isShow={showFirstCalendar || showSecondCalendar}/>
            <div
                className="u-relative d-flex u-ml-4"
            >
                <label htmlFor="first_date" className="input-field__label d-flex u-mr-1 FiltersBlock-label">
                    From
                </label>

                <span className="calendar-icon FiltersBlock-input"
                      onClick={() => setShowFirstCalendar(true)}
                >
                    <input className={'browser-default ' + 'search-field ' + additionalClass}
                           id="first_date"
                           type="text"
                           value={formatDate(firstDate)}
                           onChange={noop} // in order to eliminate React warning, onChange should be provided here
                    />
                  </span>

                {!showFirstCalendar && firstDate.length > 0 ?
                    <i className="fal fa-times fa-lg clear-icon"
                       onClick={() => setFirstDate('')}
                    /> : null}

                {showFirstCalendar && (
                    <div className="popup__content">
                        <Calendar
                            onSelect={(value) => onSelect(value, setFirstDate, setShowFirstCalendar)}
                            value={firstDate}
                        />
                    </div>
                )}
            </div>

            <div
                className="u-relative d-flex u-ml-4"
            >
                <label htmlFor="last_date" className="input-field__label d-flex u-mr-1 FiltersBlock-label">
                    To
                </label>

                <span className="calendar-icon FiltersBlock-input"
                      onClick={() => setShowSecondCalendar(true)}
                >
                    <input className={'browser-default ' + 'search-field ' + additionalClass}
                           id="last_date"
                           type="text"
                           value={formatDate(lastDate)}
                           onChange={noop} // in order to eliminate React warning, onChange should be provided here
                    />
                  </span>

                {!showSecondCalendar && lastDate.length > 0 ?
                    <i className="fal fa-times fa-lg clear-icon"
                       onClick={() => setLastDate('')}
                    /> : null}

                {showSecondCalendar && (
                    <div className="popup__content">
                        <Calendar
                            onSelect={(value) => onSelect(value, setLastDate, setShowSecondCalendar)}
                            value={lastDate}
                        />
                    </div>
                )}
            </div>
        </React.Fragment>
    )
}

export const onSelect = (value, setDate, setShowCalendar) => {
    setDate(value.format('YYYY-MM-DD'))
    setShowCalendar(false);
}

export default DateFilteringBlock
