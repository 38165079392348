import PropTypes from 'prop-types';
import React from 'react';
import {noop} from 'lodash';
import moment from 'moment-timezone';
import Select from '../shared/Select';
import TextInput from '../shared/TextInput';
import Button from '../shared/Button';
import Calendar from '../shared/Calendar';
import {Col, Row} from '../shared/Grid';
import {isValid} from '../../lib/validation';
import {destroy, get} from '../../lib/API';
import {onceOffFeesDTO, newOnceOffFeesDTO } from '../../lib/DTO';
import {updateSelects} from '../../lib/materialHelper';

export default class League extends React.Component {
  static propTypes = {
    league: PropTypes.object.isRequired,
    divisions: PropTypes.array.isRequired,
    grades: PropTypes.array.isRequired,
    once_off_fees: PropTypes.array.isRequired,
    seasons: PropTypes.array.isRequired,
    sports: PropTypes.array.isRequired,
    venues: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    currencySymbol: PropTypes.string.isRequired,
    team_ids: PropTypes.array,
  };

  state = {
    ...this.props.league,
    once_off_fees_attributes: null,
    addFeeActive: false,
    amount: '',
    description: '',
    venues: this.props.venues,
    once_off_fees: this.props.once_off_fees,
    enableEditDateMode: false,
    due_date: moment().format('YYYY-MM-DD')
  };

  updateField(field, value) {
    const { onChange } = this.props;
    const { league } = this.state;
    this.setState({ [field]: value }, () => onChange(this.state));
  }

  handleSportChange = (sport_id) => {
    const { onChange } = this.props;

    get(`/settings/venues?sport_id=${sport_id}`, (data) => {
      this.setState(
        {
          sport_id,
          venue_id: null,
          venues: data,
        },
        () => {
          onChange(this.state);
          updateSelects();
        },
      );
    });
  };

  isFeeValid() {
    const { amount, description } = this.state;

    return isValid(amount, ['float', 'positive']) && !!description;
  }

  addFee() {
    const {
      team_ids,
      onChange
    } = this.props;

    const {
      amount,
      description,
      due_date,
      id: leagueId,
      once_off_fees
    } = this.state;

    const data = {
      once_off_fee: newOnceOffFeesDTO({ amount, description, due_date, team_ids }),
    };

    const temporaryFeeId = -(once_off_fees.length + 1);

    const new_once_off_fees = [...once_off_fees, {
      ...data.once_off_fee,
      id: temporaryFeeId,
    }];

    this.setState(
      {
        addFeeActive: false,
        amount: '',
        description: '',
        due_date: moment().format('YYYY-MM-DD'),
        once_off_fees: new_once_off_fees
      },
      () => onChange(this.state),
    );

  }

  deleteFee(id) {
    const { league, onChange } = this.props;
    const {
      amount,
      description,
      due_date,
      once_off_fees
    } = this.state;

    const data = {
      once_off_fee: onceOffFeesDTO({ amount, description, due_date })
    };
    const isFeeOfExistingLeague = id > 0;

    if (isFeeOfExistingLeague) {
      destroy(data, `/api/leagues/${league.id}/once_off_fees/${id}`, () => this.afterDeleteFee(id));
    } else {
      this.setState(
        {
          once_off_fees: once_off_fees.filter((item) => item.id !== id)
        },
        () => onChange(this.state),
      );
    }
  }

  afterDeleteFee(feeId) {
    const { league, once_off_fees } = this.state;
    const { onChange } = this.props;
    const new_once_off_fees = once_off_fees.filter(
      ({ id }) => id !== feeId,
    );

    this.setState(
      {
        once_off_fees: new_once_off_fees,
      },
      () => onChange(this.state)
    );
  }

  onChooseDueDate = date => {
    this.setState((prevState) => ({
      enableEditDateMode: !prevState.enableEditDateMode,
      start_date: date.format('YYYY-MM-DD'),
      due_date: date.format('YYYY-MM-DD')
    }));
  };

  render() {
    const {
      sports,
      divisions,
      grades,
      seasons,
      days,
      currencySymbol,
    } = this.props;

    const {
      addFeeActive,
      venues,
      amount,
      description,
      sport_id,
      venue_id,
      day,
      division_id,
      grade_id,
      season_id,
      fee,
      once_off_fees,
      enableEditDateMode,
      due_date
    } = this.state;

    const daysObj = days.map(day => ({
      id: day,
      title: day,
    }));

    const feesList = once_off_fees;
    const local_date = moment(due_date).format('DD MMM, YYYY');

    return  <div className="new-league">
      <div className="u-weight-500 u-pb-2">League details</div>
      <Select title="Sport"
              required
              items={sports}
              value={sport_id}
              onSelect={this.handleSportChange}
              name="league[sport_id]"
              id="league_sport_id"/>
      <div className='u-mt-3'/>
      <Select title="Venue"
              required
              items={venues}
              value={venue_id}
              onSelect={(id) => this.updateField('venue_id', +id)}
              name="league[venue_id]"
              id="league_venue_id"/>
      <div className='u-mt-3'/>
      <Select title="Days"
              required
              items={daysObj}
              value={day}
              onSelect={(day) => this.updateField('day', day)}
              name="league[day]"
              id="league_day"/>
      <div className='u-mt-3'/>
      <Select title="Division"
              required
              items={divisions}
              value={division_id}
              onSelect={(id) => this.updateField('division_id', +id)}
              name="league[divisions_id]"
              id="league_divisions_id"/>
      <div className='u-mt-3'/>
      <Select title="Grade"
              required
              items={grades}
              value={grade_id}
              onSelect={(id) => this.updateField('grade_id', +id)}
              name="league[grades_id]"
              id="league_grades_id"/>
      <div className='u-mt-3'/>
      <Select title="Season"
              required
              items={seasons}
              value={season_id}
              onSelect={(id) => this.updateField('season_id', +id)}
              name="league[season_id]"
              id="league_season_id"/>
      <Row classMod="u-mb-3">
        <Col l="6">
          <TextInput validate={['required', 'float', 'positive']}
                      type="number"
                      measure={currencySymbol}
                      title="Weekly Fee"
                      id="league_weekly_fee"
                      value={fee}
                      onChange={(value) => this.updateField('fee', value)}/>
        </Col>
      </Row>
      <Col l="6" mod="u-pl-0 u-pr-0">
        {feesList.length > 0 &&
          <div>
            <div className="subtitle">Once-Off Fees</div>
            <ul className="league__fees_list u-mt-1 u-mb-2">
              {feesList.map((fee, i) => (
                <li key={i} className="u-p-1 u-mb-1 u-border-dodger-blue">
                  <div className="teams-list__remove"
                        onClick={() => this.deleteFee(fee.id)}>
                    <i className="far fa-times u-font-size-17" />
                  </div>
                  <div className="league__fees_item u-pb-1">
                    {currencySymbol}
                    {fee.amount}
                  </div>
                  <div className="league__fees_item u-pb-1">
                    {fee.description}
                  </div>
                  <div className="league__fees_item">
                    Due date: {moment(fee.due_date).format('DD MMM, YYYY')}
                  </div>
                </li>
              ))}
            </ul>
          </div>}
      </Col>
      <Row classMod="u-mb-2" hide={!addFeeActive}>
        <Col l="6" mod="u-pb-2 u-mt-0">
          <TextInput type="number"
                      validate={['required', 'float', 'positive']}
                      measure={currencySymbol}
                      title="Once off-fee amount"
                      id="league_weekly_once_fee"
                      value={amount}
                      onChange={(amount) => this.setState({ amount })}/>
        </Col>
        <Col l="6" mod="u-mt-0">
          <TextInput title="Description"
                      id="league_weekly_fee_description"
                      validate={['required']}
                      value={description}
                      onChange={(description) => this.setState({ description })}/>
          <br />
        </Col>
        <Col l="12" mod="d-flex">
          <div className="input-field u-mt-0"
               onClick={() => this.setState({ enableEditDateMode: true })}>
            <span className="calendar-icon">
              <input id="due_date"
                      type="text"
                      value={local_date}
                      // in order to eliminate React warning, onChange should be provided here
                      onChange={noop}/>
            </span>

            <label htmlFor="due_date" className="input-field__label select-label_filled active">
              Due date
            </label>

            {enableEditDateMode &&
              <div className="popup__content">
                <Calendar onSelect={(value) => this.onChooseDueDate(value)}
                          value={due_date}/>
              </div>}
          </div>
        </Col>
        <Col l="12" mod="d-flex">
          <Button mod="Button--small u-font-size-13 u-mr-1"
                  title="Create"
                  id="add_fee_create_button"
                  disabled={!this.isFeeValid()}
                  onClick={() => this.addFee()}/>
          <Button mod="Button--cancel Button--small u-font-size-13 u-mr-1"
                  title="Cancel"
                  id="add_fee_cancel_button"
                  onClick={() => this.setState({ addFeeActive: false })}/>
        </Col>
      </Row>
      <Row classMod="u-mb-2" hide={addFeeActive}>
        <Col l="12">
          <Button mod="Button--small u-font-size-13"
                  title="ADD ONCE-OFF FEE"
                  id="add_fee_button"
                  onClick={() => this.setState({ addFeeActive: true })}/>
        </Col>
      </Row>
    </div>
  }
}
